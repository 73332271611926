import React from 'react'
import connect from "react-redux/es/connect/connect";
import {showEndOfPeriod} from "../actions/errors";
import {withRouter} from "react-router-dom";
import {hasGameEnded, quarterNameFor, remainingHoursInCurrentQuarter} from "../services/quarter";
import {dateFromUnixTimestamp, formatDate} from "../services/utils";
import PropTypes from 'prop-types';
import RemainingTimeProgressBar from "../components/RemainingTimeProgressBar";
import Tooltip from 'react-tooltip-lite';
import { withTranslation } from "react-i18next";

class MenuFooter extends React.Component {

    static propTypes = {
        game: PropTypes.object,
        currentQuarter: PropTypes.object,
    };

    render() {
        if (this.props.currentQuarter === undefined || this.props.game === undefined) {
            return null;
        } else if (hasGameEnded(this.props.game)) {
            return this.renderAfterGameInfo();
        } else {
            return this.renderCurrentQuarterInfo();
        }
    }

    renderCurrentQuarterInfo() {
        const { t } = this.props;
        const remainingHours = remainingHoursInCurrentQuarter(this.props.game);
        const runningOutOfTime = remainingHours < 1 && remainingHours !== null;

        return (
            <div className="basic-info mt-auto">
                <div className="text-secondary">
                   {t("menuFooter.currentQuarter")}
                </div>
                <div className="text-body mb-2">
                    {quarterNameFor(this.props.game, this.props.currentQuarter.quarterNumber)}
                </div>
                <div className="text-secondary d-flex">
                    <div>
                    {t("menuFooter.quarterEnd")}
                    </div>
                    <Tooltip
                        content={t("menuFooter.remainingTimeInfo")}>
                        <i className={"material-icons time-accuracy-icon " + (runningOutOfTime ? 'text-danger' : '')}>info</i>
                    </Tooltip>
                </div>
                <div className="text-body mb-2">
                    {formatDate(dateFromUnixTimestamp(this.props.currentQuarter.end))}
                </div>
                <div className="remaining-days">
                    <RemainingTimeProgressBar
                        game={this.props.game}
                        title={''}
                        expirationCallback={() => this.props.showEndOfPeriod()}
                    />
                </div>
            </div>
        )
    }

    renderAfterGameInfo() {
        return (
            <div className="basic-info mt-auto text-secondary">
                <i className="material-icons">check_circle_outline</i> {this.props.t("menuFooter.gameOver")}
            </div>
        )
    }


}

const mapStateToProps = (state, ownProps) => {
    return {

    }
};

const MenuFooterTranslated = withTranslation('translation')(MenuFooter)

export default withRouter(connect(mapStateToProps, {
    showEndOfPeriod
})(MenuFooterTranslated));

import React from 'react'
import PropTypes from 'prop-types'
import {formatDateTime} from "../services/utils";

const LastChange = ({ object, t }) => {
    if (object !== undefined && object.lastChange !== undefined && object.lastChange !== null) {
        return (
            <div className="last-change" key="last-change">
                {t("lastChange.info", {user: object.lastChange.user, time: formatDateTime(new Date(object.lastChange.datetime * 1000))})}
            </div>
        )
    }
    return null;
}

LastChange.propTypes = {
    object: PropTypes.shape({
        lastChange: PropTypes.shape({
            user: PropTypes.string.isRequired,
            datetime: PropTypes.number.isRequired,
        })
    }),
    t: PropTypes.func.isRequired
};

export default LastChange

export const generateEffectivity = (hardness) => {
    let values = [];

    switch (hardness) {
        case EffectivityLevel.low:
            values = [
                {
                    "hours": 0,
                    "people": 0,
                    "goods": 0
                },
                {
                    "hours": 250,
                    "people": 1,
                    "goods": 125
                },
                {
                    "hours": 500,
                    "people": 2,
                    "goods": 400
                },
                {
                    "hours": 750,
                    "people": 3,
                    "goods": 675
                },
                {
                    "hours": 1000,
                    "people": 4,
                    "goods": 950
                },
                {
                    "hours": 1250,
                    "people": 5,
                    "goods": 1225
                },
                {
                    "hours": 1500,
                    "people": 6,
                    "goods": 1500
                }
            ];
            break;

        case EffectivityLevel.high:
            values = [
                {
                    "hours": 0,
                    "people": 0,
                    "goods": 0
                },
                {
                    "hours": 250,
                    "people": 2,
                    "goods": 25
                },
                {
                    "hours": 500,
                    "people": 4,
                    "goods": 75
                },
                {
                    "hours": 750,
                    "people": 6,
                    "goods": 125
                },
                {
                    "hours": 1000,
                    "people": 8,
                    "goods": 200
                },
                {
                    "hours": 1250,
                    "people": 10,
                    "goods": 275
                },
                {
                    "hours": 1500,
                    "people": 12,
                    "goods": 400
                }
            ];
            break;

        default:
            return "jsi blbec";
    }

    return values;
};

export const EffectivityLevel = {
    low: 1,
    high: 3
};
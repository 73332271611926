/* eslint-disable no-undef */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { loadMaterialSurvey } from '../../../../actions/sync-modules'
import Loader from '../../../../components/Loader'
import MaterialSurveyTable from './MaterialSurveyTable'
import { withTranslation } from "react-i18next";

class MaterialSurvey extends Component {

    constructor(props) {
        super(props);
        this.props.loadMaterialSurvey();
    }

    render() {
        if (!this.props.didDownloadData) {
            return (
                <Loader/>
            );
        } else {
            return <div className={'row'}>
                {this.renderBoxes()}
            </div>
        }
    }

    renderBoxes() {
        return this.props.materials.map(material => {
            return (
                <div key={material.id} className="col-md-6">
                    <div className={'box'}>
                        <h3>{this.props.t("materialSurvey.surveyPrice", {materialName: localStorage.getItem("language") == "cs"? material.name : material.nameEn})}</h3>
                        <div className={'content'}>
                            <MaterialSurveyTable data={this.props.materialSurvey[material.id]}  game={this.props.game} t={this.props.t}/>
                        </div>
                    </div>
                </div>
            )
        });
    }
}

const mapStateToProps = (state, ownProps) => {
    const game = state.entities.game['first'];

    return {
        game: game,
        materials: game.materials,
        materialSurvey: state.entities.materialSurvey,
        materialSurveyRequirement: state.entities.materialSurveyRequirement['first'],
        didDownloadData: state.downloadedEntities.materialSurvey
    }
};

const mapDispatchToProps = {
    loadMaterialSurvey
};

const MaterialSurveyTranslated = withTranslation('translation')(MaterialSurvey)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MaterialSurveyTranslated))

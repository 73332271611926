/* eslint-disable no-undef */

import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {loadProductionAndMachines, updateMachines, updateProduction} from '../../../../actions/sync-modules'
import SliderWithField from '../../../../components/SliderWithField'
import Loader from '../../../../components/Loader'
import {currentQuarter, quarterNameFor} from "../../../../services/quarter";
import {sequenceMap, toArray} from "../../../../services/utils";
import LastChange from "../../../../components/LastChange";
import update from "immutability-helper";
import { withTranslation } from "react-i18next";

class Production extends Component {

    constructor(props) {
        super(props);

        this.handleChangeProduction = this.handleChangeProduction.bind(this);
        this.handleBuyMachines = this.handleBuyMachines.bind(this);
        this.handleSellMachines = this.handleSellMachines.bind(this);

        this.state = {
            isInitialized: false,
            confirmedLethalSell: null
        };

        this.props.loadProductionAndMachines();
    }

    static getDerivedStateFromProps(props, state) {
        if (state.isInitialized === false && props.machines !== undefined) {
            return {
                isInitialized: true,
                confirmedLethalSell: props.machines.sell
            }
        }
        return null
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="box box-important">
                            <h3>{t("production.purchaseAndSells")}</h3>
                            <div className="content">
                                {this.renderMachines()}
                            </div>
                            <LastChange object={this.props.machines}  t={t}/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="box box-important">
                            <h3>{t("production.productionPlan", {quarterName: quarterNameFor(this.props.game, this.props.currentQuarterNumber)})}</h3>
                            <div className="content">
                                {this.renderProduction()}
                            </div>
                            <LastChange object={this.props.production}  t={t}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderProduction() {
        if (this.props.didDownloadData !== true) {
            return <Loader/>
        }

        const currentQuarterProduction = this.getActiveMachinesProduction();
        const futureProduction = this.getNotActiveMachinesProduction();

        return currentQuarterProduction.concat(futureProduction);
    }

    getActiveMachinesProduction() {
        const { t } = this.props;
        const machinesCount = this.props.machineCount;

        if (machinesCount === 0) {
            return [<div key={'no-machines'} className={'my-3'}>{t("production.nothingInProduction")}</div>];
        }

        return sequenceMap(0, machinesCount - 1, machineIndex => {
            return (
                    <div key={machineIndex} className={'border-bottom-2 mb-2 pb-2'}>
                    <h5>{t("production.machine", {number: machineIndex + 1})}</h5>
                    {toArray(this.props.goods).map(good => {
                        const production = this.findGoodProduction(machineIndex, good);
                        return (
                            <div key={good.id} className={'d-flex align-items-center mb-3'}>
                                <div className={'w-50'}>
                                    <button
                                        className="plusminus-button"
                                        disabled={production.machineTime <= 0}
                                        onClick={() => this.handleChangeProduction(machineIndex, good.id, -250)}
                                    >-</button>
                                    <button
                                        className="plusminus-button mr-3"
                                        disabled={this.isMachineCapacityFull(machineIndex)}
                                        onClick={() => this.handleChangeProduction(machineIndex, good.id, 250)}
                                    >+</button>
                                    {production.machineTime} {t("production.hour")}
                                </div>
                                <div className={'w-50 text-secondary'}>
                                    {t("production.quantityOf", 
                                    {goodName: (localStorage.getItem("language") == "cs"? good.name : good.nameEn).toLocaleLowerCase(),
                                    quantity: this.getProducedGoodCount(production, good)})}
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        });
    }

    isMachineCapacityFull(machineIndex) {
        let time = 0;

        toArray(this.props.goods).forEach(good => {
            const production = this.findGoodProduction(machineIndex, good);
            time += production.machineTime;
        });

        return time >= 1500;
    }

    findGoodProduction(machineIndex, good) {
        const machineProduction = this.props.productionOfMachines[machineIndex];

        if (machineProduction !== undefined) {
            return machineProduction.production.find(production => production.goodId === good.id)
        } else {
            return { machineTime: 0 };
        }
    }

    getProducedGoodCount(production, good) {
        let goodCount = '??';

        if (production.machineTime === 0) {
            goodCount = 0;
        } else {
            const productionEffectivity = this.props.productionEffectivity.find(item => item.goodId === good.id);
            if (productionEffectivity !== undefined) {
                let a = productionEffectivity.effectivity.find(item => item.hours === production.machineTime);
                if (a !== undefined) {
                    goodCount = a.goods;
                }
            }
        }

        return goodCount;
    }

    handleChangeProduction(machineIndex, goodId, value) {
        const machineProduction = this.props.productionOfMachines[machineIndex].production;
        const goodProductionIndex = machineProduction.findIndex(production => production.goodId === goodId);
        const goodProduction = machineProduction[goodProductionIndex];

        const newMachineTime = goodProduction.machineTime + value;

        this.props.updateProduction(update(this.props.production, {
            machines: {[machineIndex]: {production: {[goodProductionIndex]: {
                machineTime: {$set: newMachineTime}
            }}}}
        }));
    }

    getNotActiveMachinesProduction() {
        const currentMachineCount = this.props.machineCount;
        const { t } = this.props;
        const futureMachinesCount = Math.max(0, this.props.machines.buy - this.props.machines.sell);

        if (futureMachinesCount === 0) {
            return null;
        }

        return sequenceMap(0, futureMachinesCount - 1, machineIndex => {
            return (
                <div key={currentMachineCount + machineIndex} className={'border-bottom-2 mb-2 pb-2 text-secondary'}>
                    <h5>{t("production.machinePassive", {number: currentMachineCount + machineIndex + 1})}</h5>
                    {toArray(this.props.goods).map(good => {
                        return (
                            <div key={good.id} className={'d-flex align-items-center mb-3'}>
                                <div className={'w-50'}>
                                    <button
                                        className="plusminus-button"
                                        disabled={true}
                                    >+
                                    </button>
                                    <button
                                        className="plusminus-button mr-3"
                                        disabled={true}
                                    >-
                                    </button>
                                    {0} {t("production.hour")}
                                </div>
                                <div className={'w-50 text-secondary'}>
                                    {t("production.quantityOf", 
                                    {goodName: (localStorage.getItem("language") == "cs"? good.name : good.nameEn).toLocaleLowerCase(),
                                    quantity: 0})}
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        });
    }

    renderMachines() {
        const { t } = this.props;
        if (this.props.didDownloadData !== true) {
            return <Loader/>
        }

        const sellPrice = this.props.machinePrices.sell / 1000;
        const buyPrice = this.props.machinePrices.buy / 1000;

        return (
            <div>
                <SliderWithField
                    title={t("production.sellMachines")}
                    defaultValue={this.props.machines.sell}
                    minValue={0}
                    maxValue={this.props.machineCount}
                    step={1}
                    description={t("production.sellInfo", {sellPrice: sellPrice, profif: sellPrice * this.props.machines.sell})}
                    onChange={this.handleSellMachines.bind(this)}
                />
                {this.renderLethalSellWarning()}
                <hr />
                <SliderWithField
                    title={t("production.purchaseMachines")}
                    defaultValue={this.props.machines.buy}
                    minValue={0}
                    maxValue={this.props.maxMachineCount - this.props.machineCount}
                    step={1}
                    description={t("production.purchaseInfo", {buyPrice: buyPrice, totalPrice: (buyPrice * this.props.machines.buy)})}
                    onChange={this.handleBuyMachines.bind(this)}
                />
            </div>
        );
    }

    handleBuyMachines(count) {
        const newValue = parseInt(count, 10);
        this.props.updateMachines(this.props.machines.sell, newValue);
    }

    handleSellMachines(newValue, isFinal) {
        let count = parseInt(newValue, 10);

        if (isFinal && this.confirmLethalValue(count) === false) {
            return false;
        } else {
            this.props.updateMachines(count, this.props.machines.buy);
            return true;
        }
    }

    confirmLethalValue(value) {
        const { t } = this.props;
        if (value !== this.state.confirmedLethalSell) {
            if (value === this.props.machineCount) {
                if (window.confirm(t("production.confirmSellAll"))) {
                    this.setState({confirmedLethalSell: value});
                } else {
                    return false;
                }
            } else if (value >= 3) {
                if (window.confirm(t("production.confirmSell"))) {
                    this.setState({confirmedLethalSell: value});
                } else {
                    return false;
                }
            } else {
                this.setState({confirmedLethalSell: value});
            }
        }

        return true;
    }

    renderLethalSellWarning() {
        const { t } = this.props;
        if (this.props.machines.sell === this.props.machineCount) {
            return <div className="text-danger">{t("production.confirmSellAll")}</div>
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const game = state.entities.game['first'];
    const production = state.entities.production['first'];
    const machines = state.entities.machines['first'];

    return {
        game: game,
        production: production,
        productionOfMachines: production === undefined ? undefined : production.machines,
        machineCount: production === undefined ? 0 : production.machines.length,
        machines: machines,
        goods: game.goods,
        productionEffectivity: game.productionEffectivity,
        machinePrices: game.machinePrices,
        maxMachineCount: game.maxMachineCount,
        currentQuarterNumber: currentQuarter(game).quarterNumber,
        didDownloadData: state.downloadedEntities.machines && state.downloadedEntities.production
    }
};

const ProductionTranslated = withTranslation('translation')(Production)

export default withRouter(connect(mapStateToProps, {
    loadProductionAndMachines,
    updateProduction,
    updateMachines
})(ProductionTranslated))

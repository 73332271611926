/* eslint-disable no-undef */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { loadConcurrencySurvey } from '../../../../actions/sync-modules'
import Loader from '../../../../components/Loader'
import {currentQuarter, quarterNameFor} from "../../../../services/quarter";
import {formatNumber, toArray} from "../../../../services/utils";
import { withTranslation } from "react-i18next";

class ConcurrencySurvey extends Component {

    constructor(props) {
        super(props);
        this.props.loadConcurrencySurvey();
    }

    render() {
        const { t } = this.props;
        if (this.props.concurrencySurvey === undefined) {
            return (
                <Loader/>
            );
        }

        return toArray(this.props.concurrencySurvey).reverse().map(survey => (
            <div className="box" key={survey.quarterNumber}>
                <h3>{t("concurrencySurvey.competitorsResults", {quarterName: quarterNameFor(this.props.game, survey.quarterNumber)})}</h3>
                <div className="content">
                    {this.renderSurveyResults(survey)}
                </div>
            </div>
        ));
    }

    renderSurveyResults(survey) {
        const { t } = this.props;
        return (
            <table className="table table-responsive table-striped-columns table-centered-except-first-column">
                <thead>
                <tr key={"headers1"}>
                    <th rowSpan={2}> </th>
                    {this.props.goods.map(good =>
                        <th key={good.id} colSpan={2}>{localStorage.getItem("language") == "cs"? good.name : good.nameEn}</th>
                    )}
                </tr>
                <tr key={"headers2"}>
                    {this.props.goods.map(good => [
                        <th key={"price-"+good.id}>{t("concurrencySurvey.price")}</th>,
                        <th key={"soldGoods-"+good.id}>{t("concurrencySurvey.sold")}</th>
                    ])}
                </tr>
                </thead>
                <tbody>
                {toArray(survey.results).map((result, i) => {
                    return (
                        <tr key={i}>
                            <th key={'company'}>{t("concurrencySurvey.company", {number: i + 2})}</th>
                            {this.props.goods.map(good => this.renderResultForCompanyAndGood(result, good))}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }

    renderResultForCompanyAndGood(companySurvey, good) {
        const goodSurvey = companySurvey.goods.find(item => item.goodId === good.id);

        let formattedPrice, formattedSoldGoods;

        if (goodSurvey !== undefined && goodSurvey.price !== undefined) {
            formattedPrice = formatNumber(goodSurvey.price);
        } else {
            formattedPrice = "?";
        }

        if (goodSurvey !== undefined && goodSurvey.soldGoods !== undefined) {
            formattedSoldGoods = formatNumber(goodSurvey.soldGoods);
        } else {
            formattedSoldGoods = "?";
        }

        return [
            <td key={'price' + good.id}>{formattedPrice}</td>,
            <td key={'soldGoods' + good.id}>{formattedSoldGoods}</td>
        ];
    }
}

const mapStateToProps = (state, ownProps) => {
    const game = state.entities.game['first'];

    return {
        game: game,
        goods: game.goods,
        concurrencySurvey: state.entities.concurrencySurvey,
        concurrencySurveyRequirement: state.entities.concurrencySurveyRequirement['first'],
        currentQuarter: currentQuarter(game).quarterNumber
    }
};

const mapDispatchToProps = {
    loadConcurrencySurvey
};

const ConcurrencySurveyTranslated = withTranslation("translation")(ConcurrencySurvey) 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConcurrencySurveyTranslated))

import React, {Component, Suspense} from 'react'
import { Provider } from 'react-redux'
import {Route, Switch, withRouter} from 'react-router-dom'
import Sells from '../containers/pages/Student/Sells/Sells'
import Production from '../containers/pages/Student/Production/Production'
import Layout from '../containers/Layout'
import Dashboard from "../containers/pages/Student/Dashboard/Dashboard"
import Login from "../containers/pages/Login/Login"
import PrivateSection from "./PrivateSection"
import Homepage from '../containers/pages/Homepage/Homepage'
import StudentSummaries from '../containers/pages/Student/Summaries/Summaries'
import MaterialPurchase from '../containers/pages/Student/MaterialPurchase/MaterialPurchase'
import Staff from '../containers/pages/Student/Staff/Staff'
import RegistrationToGame from '../containers/pages/Student/RegistrationToGame/RegistrationToGame'
import CompanyRegistration from '../containers/pages/Student/CompanyRegistration/CompanyRegistration'
import { connect } from 'react-redux'
import StudentSection from "./StudentSection";
import TeacherSection from "./TeacherSection";
import Games from "../containers/pages/Teacher/Games/Games";
import Game from "../containers/pages/Teacher/Game/Game";
import MarketsAndCompanies from "../containers/pages/Teacher/MarketsAndCompanies/MarketsAndCompanies";
import LoanRequirements from "../containers/pages/Teacher/LoanRequirements/LoanRequirements";
import StockPrices from "../containers/pages/Teacher/StockPrices/StockPrices";
import Students from "../containers/pages/Teacher/Students/Students";
import TeacherSummaries from "../containers/pages/Teacher/Summaries/Summaries";
import CompanySummary from "../containers/pages/Teacher/Summaries/CompanySummary";
import NotFound from "../containers/pages/Errors/NotFound";
import Surveys from "../containers/pages/Student/Surveys/Surveys";
import StudentScores from "../containers/pages/Student/Scores/Scores";
import TeacherScores from "../containers/pages/Teacher/Scores/Scores";
import CompanyRegistrationSection from "./CompanyRegistrationSection";
import CompanySection from "./CompanySection";
import GameSection from "./GameSection";
import translationCsJson from '../localization/translations/cs.json'
import translationEnJson from '../localization/translations/en.json'
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import intervalPlural from 'i18next-intervalplural-postprocessor';
import config from "../config";
import Experimental from "../containers/pages/Teacher/Experimental/Experimental";

// import DevTools from './DevTools'

class Root extends Component {

    constructor() {
        super();
        this.initI18n();
    }

    render() {
        return (
            <Provider store={this.props.store}>
            <I18nextProvider i18n={i18next}>
                <Suspense>
                <Layout>
                    <Switch onChange={this.onRouteChange}>

                        <Route exact path="/" component={Homepage}/>
                        <Route path="/login" component={Login}/>

                        <PrivateSection path="/auth">
                            <Switch>

                                <StudentSection path="/auth/student">
                                    <Switch>
                                        <Route path="/auth/student/register-to-game" component={RegistrationToGame}/>
                                        <GameSection>
                                            <Switch>
                                                <CompanyRegistrationSection path="/auth/student/company-registration">
                                                    <Route path="/auth/student/company-registration" component={CompanyRegistration}/>
                                                </CompanyRegistrationSection>
                                                <CompanySection path="/auth/student/company">
                                                    <Switch>
                                                        <Route path="/auth/student/company/dashboard" component={Dashboard}/>
                                                        <Route path="/auth/student/company/production" component={Production}/>
                                                        <Route path='/auth/student/company/sells' component={Sells}/>
                                                        <Route path='/auth/student/company/summaries' component={StudentSummaries}/>
                                                        <Route path='/auth/student/company/material-purchase' component={MaterialPurchase}/>
                                                        <Route path='/auth/student/company/surveys' component={Surveys}/>
                                                        <Route path='/auth/student/company/staff' component={Staff}/>
                                                        <Route path='/auth/student/company/scores' component={StudentScores}/>
                                                        <Route path={'*'} component={NotFound}/>
                                                    </Switch>
                                                </CompanySection>
                                                <Route path={'*'} component={NotFound}/>
                                            </Switch>
                                        </GameSection>
                                        <Route path={'*'} component={NotFound}/>
                                    </Switch>
                                </StudentSection>

                                <TeacherSection path="/auth/teacher">
                                    <Switch>
                                        <Route exact path="/auth/teacher/games" component={Games}/>
                                        <Route exact path="/auth/teacher/games/:id" component={Game}/>
                                        <Route exact path="/auth/teacher/administration/:gameId/markets-and-companies" component={MarketsAndCompanies}/>
                                        <Route exact path="/auth/teacher/administration/:gameId/students" component={Students}/>
                                        <Route exact path="/auth/teacher/administration/:gameId/loan-requirements" component={LoanRequirements}/>
                                        <Route exact path="/auth/teacher/administration/:gameId/stock-prices" component={StockPrices}/>
                                        <Route exact path="/auth/teacher/administration/:gameId/experimental" component={Experimental}/>
                                        <Route exact path="/auth/teacher/administration/:gameId/summaries" component={TeacherSummaries}/>
                                        <Route exact path="/auth/teacher/administration/:gameId/summaries/:companyId" component={CompanySummary}/>
                                        <Route exact path='/auth/teacher/administration/:gameId/scores' component={TeacherScores}/>
                                        <Route path={'*'} component={NotFound}/>
                                    </Switch>
                                </TeacherSection>

                                <Route path={'*'} component={NotFound} />
                            </Switch>
                        </PrivateSection>

                        <Route path={'*'} component={NotFound}/>
                    </Switch>
                </Layout>
                </Suspense>
               </I18nextProvider>
            </Provider>
        )
    }

    getLanguage() {
        if (localStorage.getItem("language") === null) {
            const urlParams = new URLSearchParams(window.location.search);
            const urlLanguage = urlParams.get('defaultLang');
            const defaultLanguage = ["cs", "en"].includes(urlLanguage) ? urlLanguage : config.defaultLanguage;
            localStorage.setItem("language", defaultLanguage)
            return defaultLanguage
        } else {
            return localStorage.getItem("language");
        }
    }

    initI18n() {
        i18next
            .use(intervalPlural)
            .init({
                interpolation: { escapeValue: false },  // React already does escaping
                lng: this.getLanguage(),                // language to use
                resources: {
                    en: {
                        translation: translationEnJson.translationEn
                    },
                    cs: {
                        translation: translationCsJson.translationCs
                    },
                },
                transSupportBasicHtmlNodes: true
            });
    }
}

const mapStateToProps = (state, ownProps) => {
    return {

    }
};

export default withRouter(connect(mapStateToProps, {

})(Root))

import React from 'react'
import {Redirect, withRouter} from 'react-router-dom'
import { connect } from "react-redux";
import {isRegistrationPeriod} from "../services/quarter";

const CompanyRegistrationSection = ({ isRegistrationPeriod, children }) => {

    if (isRegistrationPeriod === false) { // registration period ended, redirect to company administration
        console.log(7);
        return <Redirect to={'/auth/student/company/dashboard'}/>
    }

    return children;
};

export default withRouter(connect(state => {
    const game = state.entities.game['first'];

    return {
        isRegistrationPeriod: isRegistrationPeriod(game),
    }
}, {
})(CompanyRegistrationSection));




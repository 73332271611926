import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from "react-router-dom";

const TwoLineDashboardBox = ({ icon, children, danger, link, history }) => {
    let className = danger ? ' bg-danger ' : '';

    if (link !== undefined) {
        className += ' pointer ';
    }

    const onClick = () => {
        if (link !== undefined) {
            history.push(link);
        }
    };

    return (
        <div className={"dashboard-box clearfix" + className} onClick={onClick}>
            <div className={"icon " + icon}>&nbsp;</div>
            <div className="text">
                {children}
            </div>
        </div>
    )
};

TwoLineDashboardBox.propTypes = {
    icon: PropTypes.string.isRequired,
    children: PropTypes.node,
    danger: PropTypes.bool,
    link: PropTypes.string
};

export default withRouter(TwoLineDashboardBox)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {loadInvestmentLoanExtraRepayment, updateInvestmentLoanExtraRepayment} from '../../../../actions/sync-modules'
import Loader from '../../../../components/Loader'
import LastChange from "../../../../components/LastChange";
import SliderWithField from "../../../../components/SliderWithField";
import { withTranslation } from "react-i18next";

class InvestLoanExtraRepayment extends Component {

    static propTypes = {

    };

    constructor(props) {
        super(props);

        this.state = {
            didLoadData: false
        };

        this.props.loadInvestmentLoanExtraRepayment();
    }

    render() {
        const { t } = this.props;
        if (this.props.didDownloadData === false) {
            return <Loader/>;
        }

        const repayment = this.props.extraRepayment;

        if (repayment.max === 0) {
            return null;
        }

        return (
            <div className="box box-important">
                <h3>{t("investLoanExtraRepayment.extraRepayment")}</h3>
                <div className="content">
                    <SliderWithField
                        title={t("investLoanExtraRepayment.amountOfExtraRepayment")}
                        defaultValue={repayment.amount}
                        minValue={0}
                        maxValue={repayment.max}
                        step={1}
                        onChange={value => {
                            this.props.updateInvestmentLoanExtraRepayment({
                                amount: value
                            });
                        }}
                    />
                    <LastChange object={this.props.requirement}  t={this.props.t}/>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    const extraRepayment = state.entities.investmentLoanExtraRepayment['first'];

    return {
        extraRepayment: extraRepayment,
        didDownloadData: state.downloadedEntities.investmentLoanExtraRepayment === true,
    }
};

const mapDispatchToProps = {
    loadInvestmentLoanExtraRepayment,
    updateInvestmentLoanExtraRepayment
};
const InvestLoanExtraRepaymentTranslated = withTranslation('translation')(InvestLoanExtraRepayment)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvestLoanExtraRepaymentTranslated))

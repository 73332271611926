import React from 'react'
import PropTypes from 'prop-types'
import {currentQuarterLengthInHours, remainingHoursInCurrentQuarter} from "../services/quarter";
import ProgressBar from "./ProgressBar";
import { withTranslation } from "react-i18next";

class RemainingTimeProgressBar extends React.Component {

    static propTypes = {
        game: PropTypes.object.isRequired,
        title: PropTypes.string,
        offset: PropTypes.number,
        expirationCallback: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.oneDay = 24;

        this.state = {
            remainingHours: this.calculateRemainingHours()
        };

        this.triggerExpirationCallback();
        setInterval(() => this.tick(), 1000);
    }

    calculateRemainingHours() {
        const offset = (this.props.offset === undefined) ? 0 : this.props.offset;
        return remainingHoursInCurrentQuarter(this.props.game, offset);
    }

    tick() {
        const remainingHours = this.calculateRemainingHours();

        if (this.state.remainingHours !== remainingHours) {
            this.setState({
                remainingHours: remainingHours
            });
            this.triggerExpirationCallback();
        }
    }

    triggerExpirationCallback() {
        if (this.state.remainingHours === -1 && this.props.expirationCallback !== undefined) {
            this.props.expirationCallback();
        }
    }

    render() {
        const remainingHours = this.state.remainingHours;

        if (remainingHours === null) {
            return <div>{this.props.t("remainingTimeProgressBar.quarterFinished")}</div>;
        }

        const hoursInQuarter = currentQuarterLengthInHours(this.props.game);
        const barTitle = (remainingHours < this.oneDay) ?
            this.getRemainingHoursTitle(remainingHours) :
            this.getRemainingDaysTitle(remainingHours);

        return (
            <ProgressBar
                barTitle={barTitle}
                percents={remainingHours / hoursInQuarter}
                title={this.props.title}/>
        )
    }

    getRemainingHoursTitle(remainingHours) {
        const { t } = this.props;
        let measure = '';

        if (remainingHours === -1) {
            return t("remainingTimeProgressBar.quarterFinishedReversed");
        } else if (remainingHours === 0) {
            return t("remainingTimeProgressBar.lessThanHour");
        } else {
            measure = t("remainingTimeProgressBar.hour_interval", { postProcess: 'interval', count: remainingHours });
        } 

        return remainingHours + ' ' + measure;
    }

    getRemainingDaysTitle(remainingHours) {
        const remainingDays = Math.floor(remainingHours / this.oneDay);
        let measure = '';
        const { t } = this.props;

        
        measure = t("remainingTimeProgressBar.day_interval", { postProcess: 'interval', count: remainingDays});
        
        return remainingDays + ' ' + measure;
    }
}

export default withTranslation('translation')(RemainingTimeProgressBar)

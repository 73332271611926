export const ADD_SYNC_REQUEST = 'ADD_SYNC_REQUEST';
export const REMOVE_FIRST_SYNC_REQUEST = 'REMOVE_FIRST_SYNC_REQUEST';
export const WAIT_FOR_SYNC = 'WAIT_FOR_SYNC';
export const START_SYNC = 'START_SYNC';
export const STOP_SYNC = 'STOP_SYNC';
export const NEW_CHANGES_WITHOUT_SAVING = 'NEW_CHANGES_WITHOUT_SAVING';
export const SAVED_ALL_CHANGES = 'SAVED_ALL_CHANGES';

export const addSyncRequest = (id, request) => (dispatch, getState) => {
    return dispatch({
        type: ADD_SYNC_REQUEST,
        id: id,
        request: request
    });
};

let syncTimeout = null;
let syncCompletionHandlers = [];

export const startSync = (complete = () => {}, withDelay = false) => (dispatch, getState) => {
    // if the queue is empty and the sync is not already running, then trigger the completion handler immediately and do not start the sync at all
    // else add the handler to the array to be called after finishing the sync
    if (
        getState().isWaitingForSync === false &&
        getState().isSyncing === false &&
        getState().syncQueue.length === 0
    ) {
        complete();
        return;
    } else {
        syncCompletionHandlers.push(complete);
    }

    // start the sync if not running already
    if (getState().isSyncing === false) {

        // restart the delay timeout
        clearTimeout(syncTimeout);
        dispatch({type: WAIT_FOR_SYNC});

        syncTimeout = setTimeout(() => {

            // process all items in sync queue
            dispatch({type: START_SYNC});
            syncFirstItem(dispatch, getState, () => {
                // finish syncing after all items are processed
                dispatch({type: STOP_SYNC});
                triggerCompletionHandlers();
            });

        }, (withDelay ? 1000 : 0));
    }
};

export const startSyncWithDelay = (complete) => {
    return startSync(complete, true);
};

const syncFirstItem = (dispatch, getState, allItemsCompleted) => {
    const firstRequest = getState().syncQueue[0];
    dispatch({type: REMOVE_FIRST_SYNC_REQUEST});

    if (firstRequest === undefined) {
        // no more requests in the queue
        allItemsCompleted();
    } else {
        // process a request in the queue
        dispatch(firstRequest.request).then(() => {
            // then continue with the next request in the queue
            syncFirstItem(dispatch, getState, allItemsCompleted);
        });
    }
};

const triggerCompletionHandlers = () => {
    // we have to copy the array to prevent recursive triggering when the sync is started inside the completion handler
    const copy = syncCompletionHandlers.slice();
    syncCompletionHandlers = [];
    copy.forEach(handler => handler());
};

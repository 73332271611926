import React from 'react'
import {Link} from "react-router-dom";
import { withTranslation } from "react-i18next";

class NotFound extends React.Component {
    render() {
        return (
            <div className={'d-flex flex-column justify-items-center align-items-center text-center'}>
                <img alt='avatar' src={process.env.PUBLIC_URL + '/img/avatar-bigger.png'} style={{height: '128px'}}/>
                <h1 className={'mt-3'}>{this.props.t("notFound.header")}</h1>
                <h3>{this.props.t("notFound.info")}</h3>
                <Link to={'/'} className={'btn btn-primary mt-3'}>{this.props.t("notFound.goToHomepage")}</Link>
            </div>
        )
    }
}

export default withTranslation('translation')(NotFound)

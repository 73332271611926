import React from 'react'
import Loader from "../../../../components/Loader";
import PropTypes from 'prop-types'
import {formatNumber} from "../../../../services/utils";
import {regenerateRandomness} from "../../../../actions/sync-modules";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";

class MarketDemand extends React.Component {

    static propTypes = {
        game: PropTypes.object.isRequired,
        market: PropTypes.object.isRequired,
        companyCount: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isGenerating: false
        };
    }

    render() {
        if (this.state.isGenerating || this.props.didDownloadData === false) {
            return this.renderLoader();
        } else {
            return this.renderDemand();
        }
    }

    renderDemand() {
        const estimation = this.props.teacherRandomness[this.props.market.id];
        const machineCount = estimation !== undefined ? estimation.estimatedMachineCountInLastQuarter : 0;
        const machineCountForOneCompany = machineCount / this.props.companyCount;
        return <td>
            <div>
                <small>
                    Potřeba strojů*: <b>{formatNumber(machineCountForOneCompany)}</b>
                </small>
            </div>
            <button className={'btn btn-light btn-sm'} onClick={() => this.generateNewRandomNumbers()}>
                Přegenerovat
            </button>
        </td>
    }

    renderLoader() {
        return <td>
            <Loader isLarge={false} />
        </td>
    }

    generateNewRandomNumbers() {
        this.setState({isGenerating: true}, () => {
            this.props.regenerateRandomness(this.props.game.id, this.props.market.id, () => {
                this.setState({isGenerating: false});
            });
        });
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        teacherRandomness: state.entities.teacherRandomness,
        didDownloadData: state.downloadedEntities.teacherRandomness === true,
    }
};

const mapDispatchToProps = {
    regenerateRandomness
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MarketDemand))

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateStaffRequirement } from '../../../../actions/sync-modules'
import LastChange from '../../../../components/LastChange'
import SliderWithField from "../../../../components/SliderWithField";
import {currentQuarter, quarterNameFor} from "../../../../services/quarter";
import PropTypes from 'prop-types'
import {formatNumber} from "../../../../services/utils";
import { withTranslation } from "react-i18next";

class StaffRequirement extends Component {

    static propTypes = {
        salaryIndex: PropTypes.number
    };

    constructor(props) {
        super(props);

        this.state = {
            isInitialized: false,
            fire: 0,
            hire: 0,
            confirmedLethalFiring: null
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state.isInitialized === false && props.staffRequirement !== undefined) {
            return {
                isInitialized: true,
                fire: props.staffRequirement.fire,
                hire: props.staffRequirement.hire,
                confirmedLethalFiring: props.staffRequirement.fire
            }
        }
        return null
    }

    render() {
        const { t } = this.props;
        return [
            <div className="content" key="content">
                <SliderWithField
                    defaultValue={this.state.fire}
                    minValue={0}
                    maxValue={this.props.currentEmployeeCount}
                    step={1}
                    description={this.getFiringDescription()}
                    title={t("staffRequirement.fireEmployee")}
                    onChange={this.handleFireEmployees.bind(this)}
                />
                {this.renderLethalSellWarning()}
                <SliderWithField
                    defaultValue={this.state.hire}
                    minValue={0}
                    maxValue={this.props.maxEmployeeCount - this.props.currentEmployeeCount}
                    step={1}
                    description={this.getHiringDescription()}
                    title={t("staffRequirement.hireEmployee")}
                    onChange={this.handleHireEmployees.bind(this)}
                />

            </div>,
            <LastChange key="last-change" object={this.props.staffRequirement} t={t} />
        ]
    }

    handleFireEmployees(value, isFinal = false) {
        if (isFinal && this.confirmLethalValue(value) === false) {
            return false;
        } else {
            this.setState({fire: value});
            this.props.updateStaffRequirement({fire: value, hire: this.state.hire});
            return true;
        }
    }

    confirmLethalValue(value) {
        const { t } = this.props;
        if (value !== this.state.confirmedLethalFiring) {
            if (value === this.props.currentEmployeeCount) {
                if (window.confirm(t("staffRequirement.confirmFireAll"))) {
                    this.setState({confirmedLethalFiring: value});
                } else {
                    return false;
                }
            } else if (value >= 6) {
                if (window.confirm(t("staffRequirement.confirmFireMultiple", {value: value}))) {
                    this.setState({confirmedLethalFiring: value});
                } else {
                    return false;
                }
            } else {
                this.setState({confirmedLethalFiring: value});
            }
        }

        return true;
    }

    handleHireEmployees(value) {
        this.setState({hire: value});
        this.props.updateStaffRequirement({fire: this.state.fire, hire: value});
    }

    getFiringDescription() {
        const { t } = this.props;
        const salary = (this.props.salaryIndex/100) * this.props.salaryBase;
        return t("staffRequirement.firingDescription", {salary: formatNumber(Math.round(salary)),
            quarterName: quarterNameFor(this.props.game, this.props.currentQuarter + 2),
             value: formatNumber(Math.round(salary * this.state.fire))})
    }

    getHiringDescription() {
        const { t } = this.props;
        const salary = (this.props.salaryIndex/100) * this.props.salaryBase;
        return t("staffRequirement.hiringDescription", {salary: formatNumber(Math.round(salary)),
            quarterName: quarterNameFor(this.props.game, this.props.currentQuarter),
             value: formatNumber(Math.round(salary * this.state.hire))})
    }

    renderLethalSellWarning() {
        const { t } = this.props;
        if (this.props.currentEmployeeCount - this.state.fire === 0) {
            return <div className="text-danger mb-4">{t("staffRequirement.warningFireAll")}</div>
        } else if (this.props.currentEmployeeCount - this.state.fire <= 5) {
            return <div className="text-danger mb-4">{t("staffRequirement.warningFireMultiple")}</div>
        } else {
            return null;
        }
    }

}

const mapStateToProps = (state, ownProps) => {
    const game = state.entities.game['first'];
    const company = state.entities.company['first'];

    return {
        game: game,
        goods: game.goods,
        currentQuarter: currentQuarter(game).quarterNumber,
        currentEmployeeCount: company.currentState.employeeCount,
        maxEmployeeCount: game.maxEmployees,
        staffRequirement: state.entities.staffRequirement['first'],
        salaryBase: game.defaultSalary,
    }
};

const mapDispatchToProps = {
    updateStaffRequirement
};

const StaffRequirementTranslated = withTranslation('translation')(StaffRequirement)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StaffRequirementTranslated))

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const MenuItem = ({ currentPath, targetPath, icon, title, bold }) => {

    let className = ((currentPath === targetPath) ? 'selected' : '');

    if (bold) {
        className += ' font-weight-bold ';
    }

    return (
        <Link to={targetPath} className={className}>
            <i className={'icon ' + icon}> </i>
            {title}
        </Link>
    )
};

MenuItem.propTypes = {
    className: PropTypes.string,
    targetPath: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    bold: PropTypes.bool
};

export default MenuItem

import React from 'react'
import PropTypes from 'prop-types'

class ProgressBar extends React.Component {

    static propTypes = {
        title: PropTypes.string,
        barTitle: PropTypes.string.isRequired,
        percents: PropTypes.number.isRequired
    };

    render() {
        const { title, percents } = this.props;

        let content = '';

        if (percents >= 0.3) {
            content = this.renderMoreThan30();
        } else if (percents >= 0.1) {
            content = this.renderLessThan30();
        } else {
            content = this.renderLessThan10();
        }

        return (
            <div>
                <div className={'mb-2 text-secondary'}>{title}</div>
                <div className="progress">{content}</div>
            </div>
        );
    }

    renderMoreThan30() {
        return <div className="progress-bar" style={{width: (this.props.percents * 100) + '%'}}>{this.props.barTitle}</div>
    }

    renderLessThan30() {
        const percentsSupplement = 1 - this.props.percents;
        return [
            <div key="color" className="progress-bar bg-danger" style={{width: (this.props.percents * 100) + '%'}}> </div>,
            <div key="text" className="progress-bar bg-transparent text-danger text-right pr-3" style={{width: (percentsSupplement * 100) + '%'}}>{this.props.barTitle}</div>
        ];
    }

    renderLessThan10() {
        return <div className="progress-bar progress-bar-striped progress-bar-animated bg-danger" style={{width: '100%'}}>{this.props.barTitle}</div>
    }

}

export default ProgressBar
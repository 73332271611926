/* eslint-disable no-undef */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { loadStockPrices } from '../../../../actions/sync-modules'
import Loader from '../../../../components/Loader'
import {currentQuarter, quarterNameFor} from "../../../../services/quarter";
import {toArray} from "../../../../services/utils";
import {Redirect} from "react-router-dom";

class StockPrices extends Component {

    constructor(props) {
        super(props);

        const gameId = this.props.match.params.gameId;

        this.state = {
            game: props.games[gameId]
        };

        this.props.loadStockPrices(gameId);
    }

    render() {
        if (this.state.game === undefined) {
            return <Redirect to={'/auth/teacher/games'} />
        }

        const arr = toArray(this.props.stockPrices);

        if (!this.props.didDownloadData) {
            return <Loader/>

        } else if (arr.length === 0) {
            return <div className={'box'}>
                <h3> </h3>
                <div className={'content'}>Žádná data.</div>
            </div>;

        } else {
            return (
                <div>
                    {arr.map(item => this.renderMarket(item))}
                    {this.renderLegend()}
                </div>
            )
        }
    }

    renderMarket(item) {
        const currentQuarterNumber = Math.max(0, currentQuarter(this.state.game).quarterNumber);
        const arrayLength = Math.max(0, currentQuarterNumber - 1);
        const quarters = Array.from(new Array(arrayLength), (x,i) => i + 1);

        return (
            <div key={item.id} className={'box'}>
                <h3>Ceny akcií pro {item.name}</h3>
                <div className={'content'}>
                    <table className={'table table-responsive'}>
                        <thead>
                        <tr>
                            <th> </th>
                            {quarters.map(quarterNumber =>
                                <th key={quarterNumber}>{quarterNameFor(this.state.game, quarterNumber)}</th>
                            )}
                        </tr>
                        </thead>
                        <tbody>
                        {this.renderCompaniesOnMarket(item, quarters)}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    renderCompaniesOnMarket(market, quarters) {
        if (market.companies.length === 0) {
            return <tr><td colSpan={4}>Žádné firmy na tomto trhu.</td></tr>
        } else {
            return market.companies.map(company => (
                <tr key={company.id}>
                    <th>{company.name}</th>
                    {quarters.map(quarterNumber => {
                        const stockPrice = this.getStockPriceInQuarter(company, quarterNumber);
                        const className = this.getStockPriceHighlight(stockPrice, market, company, quarterNumber);
                        return this.renderCell(quarterNumber, stockPrice, className);
                    })}
                </tr>
            ))
        }
    }

    renderCell(quarterNumber, stockPrice, className) {
        if (stockPrice === null) {
            return <td key={quarterNumber}> - </td>
        } else {
            return <td key={quarterNumber}>
                <div className={className}>
                    {Math.round(stockPrice)}
                </div>
            </td>
        }
    }

    getStockPriceHighlight(stockPrice, market, company, quarterNumber) {
        const extremaInQuarter = this.getStockPriceExtremaInQuarter(market, quarterNumber);
        const previousQuarterPrice = this.getStockPriceInQuarter(company, quarterNumber - 1);
        let className = '';

        if (stockPrice === extremaInQuarter.max) {
            className += ' stock-circle stock-circle-green ';
        } else if (stockPrice === extremaInQuarter.min) {
            className += ' stock-circle stock-circle-red ';
        }

        if (previousQuarterPrice > stockPrice) {
            className += ' text-danger ';
        }

        return className;
    }

    getStockPriceExtremaInQuarter(market, quarterNumber) {
        let min = null;
        let max = null;

        market.companies.forEach(company => {
            const stockPrice = this.getStockPriceInQuarter(company, quarterNumber);

            if (max === null || stockPrice > max) {
                max = stockPrice;
            }

            if (min === null || stockPrice < min) {
                min = stockPrice;
            }
        });

        return {min: min, max: max}
    }

    getStockPriceInQuarter(company, quarterNumber) {
        const data = company.quarters.find(q => q.quarterNumber === quarterNumber);

        if (data === undefined) {
            return null;
        } else {
            return data.stockPrice;
        }
    }

    renderLegend() {
        return <div className={'box bg-light'}>
            <h5>Legenda</h5>
            <div className={'content'}>

                <div className={'d-flex align-items-center mt-3'}>
                    <div className={'stock-circle stock-circle-green mr-2'}>
                        90
                    </div>
                    <div>
                        Zelený kroužek značí největší cenu akcie v daném kvartálu pro daný trh.
                    </div>
                </div>

                <div className={'d-flex align-items-center mt-3'}>
                    <div className={'stock-circle stock-circle-red mr-2'}>
                        70
                    </div>
                    <div>
                        Červený kroužek značí nejmenší cenu akcie v daném kvartálu pro daný trh.
                    </div>
                </div>

                <div className={'d-flex align-items-center mt-3'}>
                    <div className={'stock-circle text-danger mr-2'}>
                        60
                    </div>
                    <div>
                        Červeným textem je znázorněn pokles ceny akcie dané firmy oproti předchozímu kvartálu.
                    </div>
                </div>

            </div>
        </div>
    }
}

const mapStateToProps = (state, ownProps) => {
    const stockPrices = state.entities.stockPrices;

    return {
        games: state.entities.teacherGames,
        stockPrices: stockPrices,
        didDownloadData: state.downloadedEntities.stockPrices
    }
};

const mapDispatchToProps = {
    loadStockPrices
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StockPrices))

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateMaterialSurveyRequirement } from '../../../../actions/sync-modules'
import {currentQuarter, hasGameEnded, quarterNameFor} from "../../../../services/quarter";
import LastChange from '../../../../components/LastChange'
import Checkboxes from "../../../../components/Checkboxes";
import {formatNumber} from "../../../../services/utils";
import Loader from "../../../../components/Loader";
import { withTranslation } from "react-i18next";

class MaterialSurveyRequirement extends Component {

    static propTypes = {

    };

    constructor(props) {
        super(props);

        this.state = {}
    }

    static getDerivedStateFromProps(props, state) {
        if (state.checkedQuarterNumbers === undefined && props.materialSurveyRequirement !== undefined) {
            return {
                checkedQuarterNumbers: props.materialSurveyRequirement.quarterNumbers
            }
        }
        return null
    }

    render() {
        if (this.props.didDownloadData !== true) {
            return <Loader/>
        }

        return (
            <div className={"box box-important " + (this.props.hasGameEnded ? "" : "box-always-enabled")}>
                <h3>{this.props.t("materialSurveyRequirement.order")}</h3>
                <div className="content">
                    <div className="form-item">
                        {this.renderCheckboxes()}
                        {this.renderSurveyPrice()}
                    </div>
                </div>
                <LastChange object={this.props.materialSurveyRequirement} t={this.props.t} />
            </div>
        );
    }

    renderCheckboxes() {
        const materialSurveyRequirement = this.props.materialSurveyRequirement;

        let quarters = [];
        for (let i = 1; i <= 4; i++) {
            const quarter = this.props.currentQuarter + i;
            quarters.push({
                key: quarter,
                title: quarterNameFor(this.props.game, quarter),
                checked: materialSurveyRequirement !== undefined && materialSurveyRequirement.quarterNumbers.includes(quarter)
            });
        }

        return <Checkboxes
            items={quarters}
            onChange={items => this.handleChange(items)}
        />;
    }

    handleChange(items) {
        const checkedItems = items.filter(item => item.checked).map(item => item.key);

        this.setState({
            checkedQuarterNumbers: checkedItems
        });

        this.props.updateMaterialSurveyRequirement({
            quarterNumbers: checkedItems
        });
    }

    renderSurveyPrice() {
        const quarterNumbers = this.state.checkedQuarterNumbers || [];
        const itemCount = quarterNumbers.length;
        const itemPrice = this.props.materialSurveyPrice;
        const discountRate = this.props.discountRate;

        let coefficient = 0;
        for (let i = 0; i < itemCount; i++) {
            const discount = i * discountRate; // every next item has 10% more discount: 1st 0%, 2nd 10%, 3rd 20% etc.
            coefficient += 1 - discount;
        }

        const finalPrice = itemPrice * coefficient;

        return (
            <div key="desc" className="description">
                 {this.props.t("materialSurveyRequirement.totalPrice", {amount: formatNumber(finalPrice)})}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const game = state.entities.game['first'];

    return {
        game: game,
        currentQuarter: currentQuarter(game).quarterNumber,
        materialSurveyRequirement: state.entities.materialSurveyRequirement['first'],
        materialSurveyPrice: game.surveyPrices.materialPrices,
        discountRate: game.surveyPrices.discountRate,
        didDownloadData: state.downloadedEntities.materialSurveyRequirement,
        hasGameEnded: hasGameEnded(game)
    }
};

const mapDispatchToProps = {
    updateMaterialSurveyRequirement
};

const MaterialSurveyRequirementTranslated = withTranslation('translation')(MaterialSurveyRequirement)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MaterialSurveyRequirementTranslated))

export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';
export const REMOVE_ERROR_MESSAGE = 'REMOVE_ERROR_MESSAGE';

// Displays an error message.
export const showErrorMessage = (message) => ({
    type: SHOW_ERROR_MESSAGE,
    error: {error: message}
});

// Displays a fatal error message.
export const showFatalErrorMessage = (message) => ({
    type: SHOW_ERROR_MESSAGE,
    error: {error: message, code: 500}
});

// Resets the currently isVisible error message.
export const resetErrorMessage = () => ({
    type: RESET_ERROR_MESSAGE
});

// Deletes an error message by indexes
export const removeErrorMessage = (indexes) => ({
    type: REMOVE_ERROR_MESSAGE,
    indexes: indexes
});

// Deletes an error message by id
export const removeErrorMessageById = (id) => ({
    type: REMOVE_ERROR_MESSAGE,
    id: id
});


// SPECIFIC ERRORS

export const showEndOfPeriod = () => ({
    type: SHOW_ERROR_MESSAGE,
    error: {
        error: 'End of period',
        code: 403
    }
});

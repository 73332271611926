/* eslint-disable no-undef */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Link, Redirect, withRouter} from 'react-router-dom'
import Loader from '../../../../components/Loader'
import {loadTeacherCompanies, loadTeacherGameUsers, updateTeacherUser} from "../../../../actions/sync-modules";
import {sanitize, toArray} from "../../../../services/utils";

class Students extends Component {

    constructor(props) {
        super(props);

        const gameId = this.props.match.params.gameId;
        const game = props.games[gameId];

        this.state = {
            game: game
        };

        this.props.loadTeacherGameUsers(gameId);
        this.props.loadTeacherCompanies(gameId);
    }

    render() {
        if (this.state.game === undefined) {
            return <Redirect to={'/auth/teacher/games'} />
        } else if (this.props.didDownloadData !== true) {
            return <Loader />
        } else {
            return this.renderContent();
        }
    }

    renderContent() {
        return <div className={'col-xl-9'}>
            <div className={'box'}>
                <h3>Studenti registrovaní do hry {this.state.game.name}</h3>
                <div className={'content'}>
                    <div className={'alert alert-info'}>
                        Zde je možné uživatele pouze odebírat. Pokud chcete přidat uživatele do hry, <Link to={'/auth/teacher/administration/' + this.state.game.id + '/markets-and-companies'}>přidejte ho rovnou do firmy</Link>.
                    </div>
                    <table className={'table table-responsive'}>
                        <thead>
                        <tr>
                            <th>Jméno</th>
                            <th>Login</th>
                            <th>Firma</th>
                            <th> </th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.renderTableRows()}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>;
    }

    renderTableRows() {
        const users = toArray(this.props.teacherUsers)
            .filter(i => i.gameId === this.state.game.id)
            .sort((a, b) => sanitize(a.name) < sanitize(b.name) ? -1 : 1);

        return users.map(user => (
            <tr key={user.id}>
                <th>{user.name}</th>
                <td>{user.username}</td>
                <td>{this.findCompanyName(user.companyId)}</td>
                <td><div
                    className={'btn btn-light text-danger btn-sm'}
                    onClick={() => this.removeUserFromGame(user)}
                >Odebrat ze hry</div></td>
            </tr>
        ));
    }

    findCompanyName(companyId) {
        const companies = this.props.teacherCompanies;
        const company = companies[companyId];

        if (company !== undefined) {
            return company.name;
        } else {
            return " - ";
        }
    }

    removeUserFromGame(user) {
        const confirmed = window.confirm("Opravdu chcete odebrat uživatele " + user.name + " ze hry " + this.state.game.name + '?');
        if (confirmed) {
            this.props.updateTeacherUser(user, {companyId: null, gameId: null});
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        games: state.entities.teacherGames,
        teacherUsers: state.entities.teacherUsers,
        teacherCompanies: state.entities.teacherCompanies,
        didDownloadData: state.downloadedEntities.teacherUsers && state.downloadedEntities.teacherCompanies
    }
};

const mapDispatchToProps = {
    loadTeacherCompanies,
    loadTeacherGameUsers,
    updateTeacherUser
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Students))

/* eslint-disable no-undef */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { loadMaterialPurchase } from '../../../../actions/sync-modules'
import Loader from '../../../../components/Loader'
import MaterialPurchaseRequirement from './MaterialPurchaseRequirement'
import MaterialPurchaseTable from './MaterialPurchaseTable'
import { withTranslation } from "react-i18next";

class MaterialPurchase extends Component {

    constructor(props) {
        super(props);
        this.props.loadMaterialPurchase();
    }

    render() {
        return (
            <div>
                <div className={'row'}>
                    {this.renderMaterialRequirements()}
                </div>
                <div className={'row'}>
                    {this.renderMaterialPurchase()}
                </div>
            </div>
        )
    }

    renderMaterialPurchase() {
        const { t } = this.props;
        if (Object.keys(this.props.materialPurchase).length === 0) {
            return (
                <Loader/>
            );
        }

        return this.props.materials.map(material => {
            return (
                <div className={'col-12'} key={material.id}>
                    <div className={'box'}>
                        <h3>{t("materialPurchase.purchasesAndSupplies", {materialName: localStorage.getItem("language") == "cs"? material.name : material.nameEn})}</h3>
                        <div className={'content'}>
                            <MaterialPurchaseTable
                                data={this.props.materialPurchase[material.id].quarters}
                                game={this.props.game}
                                t={t}/>
                        </div>
                    </div>
                </div>
            )
        });
    }

    renderMaterialRequirements() {
        if (Object.keys(this.props.materialPurchaseRequirement).length === 0) {
            return <Loader/>
        } else {
            return this.props.materials.map(material => {
                return (
                    <div className={'col-md-6'} key={material.id}>
                        <MaterialPurchaseRequirement
                            data={this.props.materialPurchaseRequirement[material.id]}
                            prices={this.props.materialPurchasePrices[material.id]}
                        />
                    </div>
                )
            });
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const game = state.entities.game['first'];

    return {
        game: game,
        materials: game.materials,
        materialPurchase: state.entities.materialPurchase,
        materialPurchasePrices: state.entities.materialPurchasePrices,
        materialPurchaseRequirement: state.entities.materialPurchaseRequirement
    }
};

const mapDispatchToProps = {
    loadMaterialPurchase
};

const MaterialPurchaseTraslated = withTranslation('translation')(MaterialPurchase)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MaterialPurchaseTraslated))

/* eslint-disable no-undef */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'
import {Redirect} from "react-router-dom";
import TimeTravel from "./TimeTravel";

class Experimental extends Component {

    constructor(props) {
        super(props);

        const gameId = this.props.match.params.gameId;

        this.state = {
            game: props.games[gameId]
        };

    }

    render() {
        if (this.state.game === undefined) {
            return <Redirect to={'/auth/teacher/games'}/>
        }

        return <TimeTravel game={this.state.game} />;
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        games: state.entities.teacherGames,
    }
};

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Experimental))

import React from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'

class Checkboxes extends React.Component {

    static propTypes = {
        items: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            items: props.items,
            random: Math.random()
        };
    }

    render() {
        return this.props.items.map((item, index) => {
            const id = 'checkbox-' + this.state.random + '-' + item.key;
            return (
                <div className="form-check form-check-inline" key={item.key}>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id={id}
                        defaultChecked={item.checked}
                        onChange={() => this.toggleCheckbox(index)}
                    />
                    <label className="form-check-label" htmlFor={id}>
                        {item.title}
                    </label>
                </div>
            )
        });
    }

    toggleCheckbox(index) {
        this.setState(update(this.state, {items: {[index]: {$toggle: ['checked']}}}), () => {
            this.props.onChange(this.state.items);
        });
    }

}

export default Checkboxes
import {entities, Schemas} from "../middleware/sync/schema";
import {deleteAllEntitiesData} from "./entities";
import {CALL_API} from "../middleware/sync";
import {
    downloadData,
    createData,
    updateData,
    deleteData,
    uploadFile,
    postRequest, putRequest, getRequest,
} from './sync-requests'
import {clearAuthToken, getAuthToken, persistAuthToken} from "../middleware/sync/authorization";
import config from "../config";


// --- STUDENT ---

export const START_LOADING_BASIC_DATA = 'START_LOADING_BASIC_DATA';

// Register to game

export const registerToGame = (registrationPhrase, complete) => (dispatch, getState) => {
    dispatch(postRequest('/register-to-game', Schemas.USER_GAME, {registrationPhrase: registrationPhrase})).then(complete);
};

// Game

export const loadGame = () => (dispatch, getState) => {
    dispatch({type: START_LOADING_BASIC_DATA});
    downloadData({endpoint: 'game', schema: Schemas.GAME})(dispatch, getState);
};

// Company

export const loadCompany = () => (dispatch, getState) => {
    dispatch({type: START_LOADING_BASIC_DATA});
    downloadData({endpoint: 'company', schema: Schemas.COMPANY})(dispatch, getState);
};

// Sells

export const loadSells = () => (dispatch, getState) => {
    downloadData({entity: entities.sells, endpoint: 'sells', schema: Schemas.SELL_ARRAY})(dispatch, getState);
};

export const updateSells = (goodId, data) => (dispatch, getState) => {
    updateData(`sells/${goodId}`, Schemas.SELL, goodId, data)(dispatch, getState);
};

export const loadMarketSurveys = () => (dispatch, getState) => {
    downloadData({entity: entities.marketSurveys, endpoint: 'market-survey', schema: Schemas.MARKET_SURVEY_ARRAY})(dispatch, getState);
};

export const loadMarketSurveyRequirements = () => (dispatch, getState) => {
    downloadData({entity: entities.marketSurveyRequirements, endpoint: 'market-survey/requirements', schema: Schemas.MARKET_SURVEY_REQUIREMENT_ARRAY})(dispatch, getState);
};

export const updateMarketSurveysRequirement = (goodId, data) => (dispatch, getState) => {
    updateData(`market-survey/requirements/${goodId}`, Schemas.MARKET_SURVEY_REQUIREMENT, goodId, data)(dispatch, getState);
};


// Production

export const loadProductionAndMachines = () => (dispatch, getState) => {
    downloadData([
        {entity: entities.production, endpoint: 'production', schema: Schemas.PRODUCTION},
        {entity: entities.machines, endpoint: 'machines', schema: Schemas.MACHINES}
    ])(dispatch, getState);
};

export const updateProduction = (production) => (dispatch, getState) => {
    updateData('production', Schemas.PRODUCTION, 'first', production)(dispatch, getState);
};

export const updateMachines = (sell, buy) => (dispatch, getState) => {
    updateData('machines', Schemas.MACHINES, 'first', {sell: sell, buy: buy})(dispatch, getState);
};


// Investment Loan

export const loadInvestmentLoan = () => (dispatch, getState) => {
    downloadData({entity: entities.investmentLoan, endpoint: 'investment-loan', schema: Schemas.INVESTMENT_LOAN})(dispatch, getState);
};

export const updateInvestmentLoan = (data) => (dispatch, getState) => {
    updateData('investment-loan', Schemas.INVESTMENT_LOAN, 'first', data)(dispatch, getState);
};

export const uploadInvestmentLoanDocument = (file, complete) => (dispatch, getState) => {
    uploadFile('investment-loan/document', Schemas.INVESTMENT_LOAN, {"document": file}, complete)(dispatch, getState);
};

export const loadInvestmentLoanExtraRepayment = () => (dispatch, getState) => {
    downloadData({entity: entities.investmentLoanExtraRepayment, endpoint: 'investment-loan/extra-repayment', schema: Schemas.INVESTMENT_LOAN_EXTRA_REPAYMENT})(dispatch, getState);
};

export const updateInvestmentLoanExtraRepayment = (data) => (dispatch, getState) => {
    updateData('investment-loan/extra-repayment', Schemas.INVESTMENT_LOAN_EXTRA_REPAYMENT, 'first', data)(dispatch, getState);
};


// Concurrency Surveys

export const loadConcurrencySurvey = () => (dispatch, getState) => {
    downloadData([{entity: entities.concurrencySurvey, endpoint: 'concurrency-survey', schema: Schemas.CONCURRENCY_SURVEY_ARRAY}])(dispatch, getState);
};

export const loadConcurrencySurveyRequirement = () => (dispatch, getState) => {
    downloadData([{entity: entities.concurrencySurveyRequirement, endpoint: 'concurrency-survey/requirement', schema: Schemas.CONCURRENCY_SURVEY_REQUIREMENT}])(dispatch, getState);
};

export const updateConcurrencySurveyRequirement = (data) => (dispatch, getState) => {
    updateData('concurrency-survey/requirement', Schemas.CONCURRENCY_SURVEY_REQUIREMENT, 'first', data)(dispatch, getState);
};


// StudentSummaries

export const loadSummaries = () => (dispatch, getState) => {
    downloadData({entity: '', endpoint: 'summaries', schema: Schemas.SUMMARY_ARRAY})(dispatch, getState);
};


// Material purchase

export const loadMaterialPurchase = () => (dispatch, getState) => {
    downloadData([
        {endpoint: 'material-purchase', schema: Schemas.MATERIAL_PURCHASE_ARRAY},
        {endpoint: 'material-purchase/prices', schema: Schemas.MATERIAL_PURCHASE_PRICES_ARRAY},
        {endpoint: 'material-purchase/requirement', schema: Schemas.MATERIAL_PURCHASE_REQUIREMENT_ARRAY},
    ])(dispatch, getState);
};

export const updateMaterialPurchaseRequirement = (materialId, data) => (dispatch, getState) => {
    updateData('material-purchase/requirement/' + materialId, Schemas.MATERIAL_PURCHASE_REQUIREMENT, materialId, data)(dispatch, getState);
};


// Material survey

export const loadMaterialSurvey = () => (dispatch, getState) => {
    downloadData([
        {endpoint: 'material-survey', schema: Schemas.MATERIAL_SURVEY_ARRAY},
        {endpoint: 'material-survey/requirement', schema: Schemas.MATERIAL_SURVEY_REQUIREMENT},
    ])(dispatch, getState);
};

export const updateMaterialSurveyRequirement = (data) => (dispatch, getState) => {
    updateData('material-survey/requirement', Schemas.MATERIAL_SURVEY_REQUIREMENT, 'first', data)(dispatch, getState);
};


// Staff

export const loadStaff = () => (dispatch, getState) => {
    downloadData([
        {endpoint: 'staff', schema: Schemas.STAFF},
        {endpoint: 'staff/requirement', schema: Schemas.STAFF_REQUIREMENT},
        {endpoint: 'staff/personal-policy', schema: Schemas.STAFF_PERSONAL_POLICY},
    ])(dispatch, getState);
};

export const updateStaffRequirement = (data) => (dispatch, getState) => {
    updateData('staff/requirement', Schemas.STAFF_REQUIREMENT, 'first', data)(dispatch, getState);
};

export const updateStaffPersonalPolicy = (data) => (dispatch, getState) => {
    updateData('staff/personal-policy', Schemas.STAFF_PERSONAL_POLICY, 'first', data)(dispatch, getState);
};

// Student-companies

export const loadUserCompanies = () => (dispatch, getState) => {
    downloadData({endpoint: 'student-companies', schema: Schemas.USER_COMPANY_ARRAY})(dispatch, getState);
};

export const addNewCompany = (companyName, seminarId, complete) => (dispatch, getState) => {
    createData('student-companies', Schemas.USER_COMPANY, {name: companyName, seminarId: seminarId}, complete)(dispatch, getState);
};

export const updateCompany = (companyId, name, seminarId) => (dispatch, getState) => {
    updateData('student-companies/' + companyId, Schemas.USER_COMPANY, companyId, {name: name, seminarId: seminarId})(dispatch, getState);
};

export const deleteCompany = (companyId) => (dispatch, getState) => {
    deleteData('student-companies/' + companyId, Schemas.USER_COMPANY, companyId)(dispatch, getState);
};

export const updateUserCompany = (user, companyId, complete) => (dispatch, getState) => {
    updateData('user', Schemas.USER, "first", {companyId: companyId}, complete)(dispatch, getState);
};

// Scores

export const loadScores = () => (dispatch, getState) => {
    downloadData({endpoint: 'score', schema: Schemas.SCORE_ARRAY})(dispatch, getState);
};



// --- TEACHER ---

// Teacher :: games

export const loadTeacherGames = () => (dispatch, getState) => {
    downloadData({endpoint: 'teacher/games', schema: Schemas.TEACHER_GAME_ARRAY})(dispatch, getState);
};

export const deleteTeacherGame = (gameId) => (dispatch, getState) => {
    deleteData('teacher/games/' + gameId, Schemas.TEACHER_GAME, gameId)(dispatch, getState);
};

export const createTeacherGame = (data, complete) => (dispatch, getState) => {
    createData('teacher/games', Schemas.TEACHER_GAME, data, complete)(dispatch, getState);
};

export const updateTeacherGame = (gameId, data, complete) => (dispatch, getState) => {
    updateData('teacher/games/' + gameId, Schemas.TEACHER_GAME, gameId, data, () => {
        // download game again to fetch updated things like currentQuarterNumber
        downloadData({endpoint: 'teacher/games/' + gameId, schema: Schemas.TEACHER_GAME}, complete)(dispatch, getState);
    })(dispatch, getState);
};

export const changeCurrentGameQuarter = (gameId, newQuarterNumber, complete) => (dispatch) => {
    dispatch(putRequest('teacher/games/' + gameId + '/switch-quarter/' + newQuarterNumber, null, null)).then(complete);
};

export const loadSwitchQuarterLog = (gameId, complete) => (dispatch) => {
    dispatch(getRequest('teacher/games/' + gameId + '/switch-quarter-log', null)).then(complete);
};

// Teacher :: companies and markets

export const loadTeacherCompanies = (gameId, complete) => (dispatch, getState) => {
    downloadData({endpoint: 'teacher/games/' + gameId + '/companies', schema: Schemas.TEACHER_COMPANY_ARRAY}, complete)(dispatch, getState);
};

export const loadTeacherMarkets = (gameId, complete) => (dispatch, getState) => {
    downloadData({endpoint: 'teacher/games/' + gameId + '/markets', schema: Schemas.TEACHER_MARKET_ARRAY}, complete)(dispatch, getState);
};

export const addNewTeacherCompany = (gameId, companyName, seminarId, complete) => (dispatch, getState) => {
    createData('teacher/games/' + gameId + '/companies', Schemas.TEACHER_COMPANY, {name: companyName, seminarId: seminarId}, complete)(dispatch, getState);
};

export const updateTeacherCompany = (gameId, companyId, data) => (dispatch, getState) => {
    updateData('teacher/games/' + gameId + '/companies/' + companyId, Schemas.TEACHER_COMPANY, companyId, data)(dispatch, getState);
};

export const deleteTeacherCompany = (gameId, companyId) => (dispatch, getState) => {
    deleteData('teacher/games/' + gameId + '/companies/' + companyId, Schemas.TEACHER_COMPANY, companyId)(dispatch, getState);
};

export const addNewMarket = (gameId, marketName, complete) => (dispatch, getState) => {
    createData('teacher/games/' + gameId + '/markets', Schemas.TEACHER_MARKET, {name: marketName}, complete)(dispatch, getState);
};

export const updateTeacherMarket = (gameId, marketId, data) => (dispatch, getState) => {
    updateData('teacher/games/' + gameId + '/markets/' + marketId, Schemas.TEACHER_MARKET, marketId, data)(dispatch, getState);
};

export const deleteTeacherMarket = (gameId, marketId) => (dispatch, getState) => {
    deleteData('teacher/games/' + gameId + '/markets/' + marketId, Schemas.TEACHER_MARKET, marketId)(dispatch, getState);
};

export const loadMarketsRandomness = (gameId) => (dispatch, getState) => {
    downloadData({endpoint: 'teacher/games/' + gameId + '/randomness', schema: Schemas.TEACHER_RANDOMNESS_ARRAY})(dispatch, getState);
};

export const regenerateRandomness = (gameId, marketId, complete) => (dispatch, getState) => {
    createData('teacher/games/' + gameId + '/randomness/' + marketId, Schemas.TEACHER_RANDOMNESS, {}, complete)(dispatch, getState);
};

export const searchUsers = (query, complete) => (dispatch, getState) => {
    downloadData([{endpoint: 'teacher/users/?search=' + query, schema: Schemas.TEACHER_SEARCH_USER_ARRAY}], complete)(dispatch, getState);
};

export const updateTeacherUser = (user, data) => (dispatch, getState) => {
    updateData('teacher/users/' + user.id, Schemas.TEACHER_USER, user.id, data)(dispatch, getState);
};

// Teacher :: students

export const loadTeacherGameUsers = (gameId) => (dispatch, getState) => {
    downloadData({endpoint: 'teacher/games/' + gameId + '/users', schema: Schemas.TEACHER_USER_ARRAY})(dispatch, getState);
};

// Teacher :: stock prices

export const loadStockPrices = (gameId) => (dispatch, getState) => {
    downloadData({endpoint: 'teacher/games/' + gameId + '/stock-prices', schema: Schemas.TEACHER_STOCK_PRICES_ARRAY})(dispatch, getState);
};

// Teacher :: loan requirements

export const UPDATE_OPENED_LOAN_REQUIREMENTS = 'UPDATE_OPENED_LOAN_REQUIREMENTS';

export const loadLoanRequirements = (gameId) => (dispatch, getState) => {
    downloadData({endpoint: 'teacher/games/' + gameId + '/loan-requirements', schema: Schemas.TEACHER_LOAN_REQUIREMENTS_ARRAY}, () => {
        dispatch({type: UPDATE_OPENED_LOAN_REQUIREMENTS, gameId: gameId});
    })(dispatch, getState);
};

export const updateLoanRequirement = (gameId, requirementId, data) => (dispatch, getState) => {
    updateData('teacher/games/' + gameId + '/loan-requirements/' + requirementId, Schemas.TEACHER_LOAN_REQUIREMENTS, requirementId, data)(dispatch, getState);
    dispatch({type: UPDATE_OPENED_LOAN_REQUIREMENTS, gameId: gameId});
};

// Teacher :: summaries

export const loadTeacherSummaries = (companyId) => (dispatch, getState) => {
    downloadData({endpoint: 'teacher/companies/' + companyId + '/summaries', schema: Schemas.SUMMARY_ARRAY})(dispatch, getState);
};

// Teacher :: scores

export const loadTeacherScores = (gameId) => (dispatch, getState) => {
    downloadData({endpoint: 'teacher/games/' + gameId + '/score', schema: Schemas.SCORE_ARRAY})(dispatch, getState);
};

// Teacher :: statistics

export const generateTeacherStatistics = (gameId, complete) => (dispatch, getState) => {
    createData('teacher/games/' + gameId + '/statistics/generate', null, null, complete)(dispatch, getState);
};

// Teacher :: game defaults

export const loadTeacherGameDefaults = (complete) => (dispatch, getState) => {
    downloadData({endpoint: 'teacher/game-defaults', schema: Schemas.TEACHER_GAME_DEFAULTS}, complete)(dispatch, getState);
};



// --- USER ---

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGIN_RESTORED = 'LOGIN_RESTORED';

export const login = (username, password, complete) => (dispatch, getState) => {
    dispatch({
        [CALL_API]: {
            types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
            method: 'POST',
            endpoint: 'login',
            schema: Schemas.USER,
            data: {
                "username": username,
                "password": password
            }
        }
    }).then(result => {
        persistAuthToken(result.response);
        loadProfilePicture()(dispatch, getState);
        complete();
    });
};

export const loadUser = () => (dispatch, getState) => {
    dispatch({type: START_LOADING_BASIC_DATA});
    downloadData({entity: entities.user, endpoint: 'user', schema: Schemas.USER})(dispatch, getState);
    loadProfilePicture()(dispatch, getState);
};

export const SET_PROFILE_PICTURE = 'SET_PROFILE_PICTURE';
export const DELETE_PROFILE_PICTURE = 'DELETE_PROFILE_PICTURE';

export const loadProfilePicture = () => (dispatch, getState) => {
    const parameters = {
        headers: { authorization: 'Bearer ' + getAuthToken() }
    };
    fetch(config.serverUrl + 'user/picture', parameters)
        .then(response => {
            if (response.status === 200) {
                return response.blob();
            }
        })
        .then(blob => {
            if (blob !== undefined) {
                const reader = new FileReader();
                reader.onload = function () {
                    dispatch({
                        type: SET_PROFILE_PICTURE,
                        image: this.result
                    });
                };
                reader.readAsDataURL(blob);
            }
        });
};

export const LOGOUT = 'LOGOUT';

export const logout = () => (dispatch, getState) => {
    deleteAllEntitiesData()(dispatch, getState);
    clearAuthToken();
    dispatch({type: LOGOUT});
};

import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import Loader from '../../../../components/Loader'
import {quarterNameFor} from "../../../../services/quarter";
import {formatNumber} from "../../../../services/utils";
import {loadMarketSurveys} from "../../../../actions/sync-modules";
import { withTranslation } from "react-i18next";

class MarketSurvey extends Component {

    static propTypes = {

    };

    constructor(props) {
        super(props);

        this.props.loadMarketSurveys();
    }

    render() {
        return <div className="row">
            {this.renderBoxes()}
        </div>
    }

    renderBoxes() {
        const { t } = this.props;
        return Object.keys(this.props.goods).map((key, index) => {
            const good = this.props.goods[key];

            const tableRows = () => {
                const marketSurvey = this.props.marketSurveys[good.id];

                if (marketSurvey === undefined || marketSurvey.quarters.length === 0)
                    return <tr><td colSpan="3">{t("marketSurvey.nothingYet")}</td></tr>;

                marketSurvey.quarters.sort((a, b) => a.quarterNumber - b.quarterNumber);

                return marketSurvey.quarters.map((surveyForQuarter, i) => {
                    return (
                        <tr key={i + "-" + good.id + "-" + surveyForQuarter.quarterNumber}>
                            <td>{quarterNameFor(this.props.game, surveyForQuarter.quarterNumber)}</td>
                            <td>{formatNumber(surveyForQuarter.price)}</td>
                            <td>{formatNumber(surveyForQuarter.marketPotential)}</td>
                        </tr>
                    )
                });
            };

            return (
                <div key={good.id} className="col-lg-6">
                    <div className="box">
                        <h3>{t("marketSurvey.marketSurvey", 
                        {productName: localStorage.getItem("language") == "cs"? good.name : good.nameEn})}</h3>
                        <div className="content">
                            <table className="table table-responsive">
                                <thead>
                                <tr>
                                    <th>{t("marketSurvey.quarter")}</th>
                                    <th>{t("marketSurvey.price")}</th>
                                    <th>{t("marketSurvey.marketPotential")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (Object.keys(this.props.marketSurveys).length === 0) ?
                                        <tr><td colSpan="3"><Loader/></td></tr>
                                        :
                                        tableRows()
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
        })
    }

}

const mapStateToProps = (state, ownProps) => {
    const game = state.entities.game['first'];

    return {
        game: game,
        goods: game.goods,
        marketSurveys: state.entities.marketSurveys,
    }
};

const mapDispatchToProps = {
    loadMarketSurveys
};

const MarketSurveyTranslated = withTranslation('translation')(MarketSurvey)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MarketSurveyTranslated))

/* eslint-disable no-undef */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { loadSells, loadMarketSurveys, loadMarketSurveyRequirements } from '../../../../actions/sync-modules'
import Loader from '../../../../components/Loader'
import SellsOfGood from './SellsOfGood'
import {currentQuarter, quarterNameFor} from "../../../../services/quarter";

class Sells extends Component {

    constructor(props) {
        super(props);
        this.props.loadSells();
    }

    render() {
        return (
            <div>
                <div className="row">
                    {this.renderGoodSells()}
                </div>
            </div>
        )
    }

    renderGoodSells() {
        if (Object.keys(this.props.sells).length === 0) {
            return (
                <Loader/>
            );
        }

        return Object.keys(this.props.sells).map((key, index) => {
            const sell = this.props.sells[key];
            const good = this.props.goods.find(good => good.id === sell.goodId);
            const storageCount = this.props.goodStorage.find(item => item.goodId === good.id).count;

            return (
                <SellsOfGood
                    key={good.id}
                    good={good}
                    quarterName={quarterNameFor(this.props.game, this.props.currentQuarter)}
                    sell={sell}
                    count={sell.count}
                    price={sell.price}
                    storageCount={storageCount}
                />
            );
        })
    }
}

const mapStateToProps = (state, ownProps) => {
    const game = state.entities.game['first'];
    const company = state.entities.company['first'];;

    return {
        game: game,
        goods: game.goods,
        sells: state.entities.sells,
        currentQuarter: currentQuarter(game).quarterNumber,
        goodStorage: company.previousState.goodStorage,
        isSyncing: state.isSyncing
    }
};

const mapDispatchToProps = {
    loadSells,
    loadMarketSurveys,
    loadMarketSurveyRequirements
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sells))

import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {loadMarketSurveyRequirements, updateMarketSurveysRequirement} from '../../../../actions/sync-modules'
import Loader from '../../../../components/Loader'
import {currentQuarter, hasGameEnded, quarterNameFor} from "../../../../services/quarter";
import LastChange from '../../../../components/LastChange'
import Checkboxes from "../../../../components/Checkboxes";
import NumberField from "../../../../components/NumberField";
import {formatNumber, toArray} from "../../../../services/utils";
import Radioboxes from "../../../../components/Radioboxes";
import { withTranslation } from "react-i18next";

class MarketSurveyRequirements extends Component {

    static propTypes = {

    };

    constructor(props) {
        super(props);

        this.props.loadMarketSurveyRequirements();
    }

    render() {
        return <div className={'row'}>
            {this.renderBoxes()}
        </div>
    }

    renderBoxes() {
        const { t } = this.props;
        const goods = toArray(this.props.goods);

        return goods.map((good, index) => {
            const marketSurveyRequirement = this.props.marketSurveyRequirements[good.id];

            return (
                <div key={good.id} className="col-lg-6">
                    <div className={"box box-important " + (this.props.hasGameEnded ? "" : "box-always-enabled")}>
                        <h3>{t("marketSurveyRequirements.order", {productName: localStorage.getItem("language") == "cs"? good.name : good.nameEn})}</h3>
                        <div className="content">
                            <div className="form-item">
                                {
                                    (Object.keys(this.props.marketSurveyRequirements).length === 0) ?
                                        <Loader/>
                                        :
                                        <div>
                                            <h6 className={'mt-3'}>{t("marketSurveyRequirements.targetTime")}</h6>
                                            {this.renderCheckboxes(good, marketSurveyRequirement)}

                                            <h6 className={'mt-4'}>{t("marketSurveyRequirements.targetPrice")}</h6>
                                            {this.renderTargetPrice(good, marketSurveyRequirement)}

                                            {this.renderSurveyPrice(goods, index)}
                                        </div>
                                }
                            </div>
                        </div>
                        <LastChange object={this.props.marketSurveyRequirements[good.id]} t={t}/>
                    </div>
                </div>
            );
        });
    }

    renderCheckboxes(good, marketSurveyRequirement) {
        let quarters = [];

        for (let i = 1; i <= 4; i++) {
            const quarter = this.props.currentQuarter + i;
            quarters.push({
                title: quarterNameFor(this.props.game, quarter),
                key: quarter,
                checked: marketSurveyRequirement !== undefined && marketSurveyRequirement.quarterNumbers.includes(quarter)
            });
        }

        if (this.props.currentQuarter % 4 === 0) {
            return <Checkboxes
                items={quarters}
                onChange={items => {
                    const checkedQuarters = items.filter(item => item.checked).map(item => item.key);
                    this.handleSelectedQuartersChange(good, marketSurveyRequirement, checkedQuarters);
                }}
                title={""}
            />
        } else {
            quarters.unshift({
                title: this.props.t("marketSurveyRequirements.dontOrder"),
                key: null,
                checked: marketSurveyRequirement === undefined || marketSurveyRequirement.quarterNumbers.length === 0
            });
            return <Radioboxes
                items={quarters}
                onChange={item => {
                     const checkedQuarters = item === null ? [] : [item];
                     this.handleSelectedQuartersChange(good, marketSurveyRequirement, checkedQuarters);
                }}
            />
        }
    }

    renderTargetPrice(good, marketSurveyRequirement) {
        const { maxPrice, minPrice } = good;

        return <NumberField
            required={true}
            minValue={minPrice}
            maxValue={maxPrice}
            measure={this.props.t("marketSurveyRequirements.czk")}
            defaultValue={marketSurveyRequirement.price}
            onChange={price => this.handleTargetPriceChange(good, marketSurveyRequirement, price)}
        />
    }

    renderSurveyPrice(goods, goodIndex) {
        const itemPrice = this.props.marketPotentialSurveyPrice;
        const discountRate = this.props.discountRate;

        let previousGoodsSurveyCount = 0;
        for (let i = 0; i < goodIndex; i++) {
            previousGoodsSurveyCount += this.props.marketSurveyRequirements[goods[i].id].quarterNumbers.length;
        }

        const currentGoodSurveyCount = this.props.marketSurveyRequirements[goods[goodIndex].id].quarterNumbers.length;

        let coefficient = 0;
        for(let i = previousGoodsSurveyCount; i < (currentGoodSurveyCount + previousGoodsSurveyCount); i++) {
            const discount = i * discountRate; // every next item has 10% more discount: 1st 0%, 2nd 10%, 3rd 20% etc.
            coefficient += 1 - discount;
        }

        const finalPrice = itemPrice * coefficient;

        return (
            <div key="desc" className="description">
                {this.props.t("marketSurveyRequirements.surveyPrice", {finalPrice: formatNumber(finalPrice)})}
            </div>
        )
    }

    handleSelectedQuartersChange(good, marketSurveyRequirement, items) {
        this.props.updateMarketSurveysRequirement(good.id, {
            quarterNumbers: items,
            price: marketSurveyRequirement.price
        });
    }

    handleTargetPriceChange(good, marketSurveyRequirement, price) {
        this.props.updateMarketSurveysRequirement(good.id, {
            quarterNumbers: marketSurveyRequirement.quarterNumbers,
            price: price
        });
    }

}

const mapStateToProps = (state, ownProps) => {
    const game = state.entities.game['first'];

    return {
        game: game,
        goods: game.goods,
        currentQuarter: currentQuarter(game).quarterNumber,
        marketSurveyRequirements: state.entities.marketSurveyRequirements,
        marketPotentialSurveyPrice: game.surveyPrices.marketPotential,
        discountRate: game.surveyPrices.discountRate,
        hasGameEnded: hasGameEnded(game)
    }
};

const mapDispatchToProps = {
    loadMarketSurveyRequirements,
    updateMarketSurveysRequirement
};

const MarketSurveyRequirementsTranslated = withTranslation('translation')(MarketSurveyRequirements)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MarketSurveyRequirementsTranslated))

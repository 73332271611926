import React from 'react'
import PropTypes from 'prop-types'

class Radioboxes extends React.Component {

    static propTypes = {
        items: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            items: props.items,
            random: Math.random()
        };
    }

    render() {
        return this.props.items.map((item, index) => {
            const id = 'radiobox-' + this.state.random + '-' + item.key;
            return (
                <div className="form-check form-check-inline" key={item.key}>
                    <input
                        className="form-check-input"
                        type="radio"
                        id={id}
                        name={'radiobox-' + this.state.random}
                        defaultChecked={item.checked}
                        onChange={() => this.selectItem(item)}
                    />
                    <label className="form-check-label" htmlFor={id}>
                        {item.title}
                    </label>
                </div>
            )
        });
    }

    selectItem(item) {
        this.setState({selectedKey: item.key}, () => {
            this.props.onChange(this.state.selectedKey);
        });
    }

}

export default Radioboxes
import React from 'react'
import {isFatal, localizedError, uniqueErrors} from "../services/errors";
import connect from "react-redux/es/connect/connect";
import {removeErrorMessage} from "../actions/errors";
import {withRouter} from "react-router-dom";
import { withTranslation } from 'react-i18next';

class Errors extends React.Component {

    render() {
        const errors = uniqueErrors(this.props.errors);
        if (errors.length > 0) {
            return (
                <div className="error-message">
                    {errors.map(e => this.renderError(e))}
                </div>
            );
        } else {
            return null;
        }
    }

    renderError(error) {
        return (
            <div key={error.indexes[0]} className="p-3 mb-3 bg-danger text-white clearfix">
                {this.renderButton(error)}
                {localizedError(error, this.props.t)}
            </div>
        );
    }

    renderButton(error) {
        if (isFatal(error)) {
            return <button className={'btn btn-light btn-sm ml-3 float-right'} onClick={() => window.location.reload()}>{this.props.t("errorsContainer.reload")}</button>
        } else {
            return <i className="material-icons pointer ml-3 float-right"
                      onClick={() => this.props.removeErrorMessage(error.indexes)}>cancel</i>;
        }
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        errors: state.errors
    }
};

const ErrorsTranslated = withTranslation('translation')(Errors)
export default withRouter(connect(mapStateToProps, {
    removeErrorMessage
})(ErrorsTranslated));

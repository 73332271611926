import React from 'react'
import {Redirect, withRouter} from 'react-router-dom'
import { connect } from "react-redux";
import {loadUser, logout} from "../actions/sync-modules";

const PrivateSection = ({ path, isAuthenticated, user, location, children, loadUser, loginProblem, logout, didDownloadUser }) => {

    if (loginProblem === true) { // logout from the server side
        console.log(1);
        logout();
        return <Redirect to={{
            pathname: '/login',
            state: {from: location}
        }}/>
    }

    if (isAuthenticated === false) { // logout from the frontend
        console.log(2);
        return <Redirect to={{
            pathname: '/login',
            state: {from: location}
        }}/>
    }

    if (user === undefined) { // missing user data
        console.log(3);
        if (didDownloadUser !== true) {
            loadUser();
        }
        return null;
    }

    return children

};

export default withRouter(connect(state => {
    const loginProblem = state.errors.find(error => error.code === 401) !== undefined;

    return {
        isAuthenticated: state.isAuthenticated,
        user: state.entities.user['first'],
        didDownloadUser: state.downloadedEntities.user,
        loginProblem: loginProblem
    }
}, {
    loadUser,
    logout
})(PrivateSection));

import React from 'react'
import PropTypes from 'prop-types'
import {sequenceMap, toArray} from "../services/utils";
import config from "../config";
import { withTranslation } from "react-i18next";

class ScoresTable extends React.Component {

    static propTypes = {
        scores: PropTypes.object.isRequired
    };

    render() {
        const maxCompanies = this.getMaximumNumberOfCompaniesOnMarket();

        if (toArray(this.props.scores).length === 0 || maxCompanies === 0) {
            return <div>{this.props.t("scoresTable.noData")}</div>
        }

        return (
            <table className={'table table-responsive table-striped-odd-columns'}>
                <thead>
                <tr>
                    <th> </th>
                    {sequenceMap(1, maxCompanies, i =>
                        <th key={i}>{this.props.t("scoresTable.placement", {postProcess: 'interval', count: i})}</th>
                    )}
                </tr>
                </thead>
                <tbody>
                {toArray(this.props.scores).map(market => {
                    return (
                        <tr key={market.id}>
                            <th className={'text-nowrap'}>{market.name}</th>
                            {sequenceMap(1, maxCompanies, i =>
                                <td key={i} className={'result-column'}>
                                    {this.renderScoreForMarketAndOrder(market.scores, i)}
                                </td>
                            )}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        );
    }

    renderScoreForMarketAndOrder(marketScores, order) {
        const companies = marketScores.filter(i => i.order === order);
        return companies.map((company) =>
            <div className="mb-1" key={company.id}>
                <b>{company.name} ({company.score})</b>
                <br/>
                {this.renderCompanySummaries(company)}
            </div>
        );
    }

    renderCompanySummaries(company) {
        const summaryCount = company.summaries.length;
        const currentYear = Math.ceil(summaryCount / 4);

        if (summaryCount === 0) {
            return null;
        }

        return sequenceMap(1, currentYear, year => {
            const lastQuarterOfYear = year * 4;
            const lastAvailableQuarter = lastQuarterOfYear > summaryCount ? summaryCount : lastQuarterOfYear;
            const summary = company.summaries.find(i => i.quarterNumber === lastAvailableQuarter);

            if (summary === undefined) {
                return null;
            } else {
                return <a
                    key={year}
                    href={config.serverUrl + 'summaries/' + localStorage.getItem('language') + '/' + summary.yearSummary}
                    target={"_blank"}
                    className={'d-inline-block mr-3'}
                    rel="noopener noreferrer"
                >{this.props.t("scoresTable.year", {year: year})}</a>
            }
        });
    }

    getMaximumNumberOfCompaniesOnMarket() {
        let max = 0;
        toArray(this.props.scores).forEach(market => {
            const companyCount = market.scores.length;
            if (companyCount > max) {
                max = companyCount;
            }
        });
        return max;
    }
}

export default withTranslation('translation')(ScoresTable)

/* eslint-disable no-undef */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'
import {currentQuarter, quarterNameFor} from "../../../../services/quarter";
import PropTypes from "prop-types";
import {changeCurrentGameQuarter, loadSwitchQuarterLog} from "../../../../actions/sync-modules";
import {nl2br} from "../../../../services/utils";
import Loader from "../../../../components/Loader";

class TimeTravel extends Component {

    static propTypes = {
        game: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            hasChangedQuarter: false,
            log: '...',
        };
    }

    componentDidMount() {
        this.startRefreshingLog();
    }

    render() {
        return (
            <div className={'box'}>
                <h3>Cestování v čase</h3>
                {this.renderContent()}
            </div>
        )
    }

    renderContent() {
        const current = currentQuarter(this.props.game);
        const lastInitialYearQuarter = this.props.game.initialYear * 4;
        const lastGameQuarter = this.props.game.gameYearCount * 4;
        const canGoBack = current.quarterNumber > lastInitialYearQuarter;
        const canGoForward = current.quarterNumber <= lastGameQuarter;

        if (this.state.isLoading) {
            return <div className={'content'}><Loader/></div>
        } else if (this.state.hasChangedQuarter) {
            return this.renderResult();
        } else if (current.quarterNumber < 1 || !this.props.game.marketsEnabled) {
            return this.renderGameNotStarted();
        }

        return <div className={'content'}>
            <div className={'alert alert-warning'}>
                <i className="material-icons">warning</i>
                Tato funkce je experimentální. Nejsou známy všechny vedlejší efekty cestování v čase a v krajním případu může dojít i k pádu celé aplikace, který si vyžádá zásah programátora.<br/>
                Tuto funkci využívejte pouze pro testovací a analytické účely mimo období ostrého běhu hry se studenty.<br/>
                Nikdy nepoužívejte pro opravu nechtěného nebo zapomenutého rozhodnutí studenta. Taková oprava rozhodnutí způsobí chaos, protože se nečekaně změní i výsledky ostatních firem.<br/>
            </div>

            <div className={'alert alert-info'}>
                <i className="material-icons">info</i>
                Změna kvartálu může trvat až několik minut a hra by se neměla během této doby používat.
            </div>

            <h4>Aktuální kvartál</h4>
            {quarterNameFor(this.props.game, current.quarterNumber)}

            <h4 className="mt-4">Posunout kvartál</h4>
            <button className="btn btn-primary" onClick={() => this.changeQuarter(current.quarterNumber - 1)} disabled={!canGoBack}>
                <i className="material-icons">navigate_before</i>
            </button>
            <button className="btn btn-primary ml-3" onClick={() => this.changeQuarter(current.quarterNumber + 1)} disabled={!canGoForward}>
                <i className="material-icons">navigate_next</i>
            </button>
        </div>
    }

    renderGameNotStarted() {
        return <div className={'content'}>
            <div className={'alert alert-info'}>Hra ještě nezačala.</div>
        </div>
    }

    changeQuarter(newQuarterNumber) {
        if (window.confirm("Opravdu chcete změnit kvartál? Jste si vědomi možných vedlejších efektů?")) {
            this.setState({isLoading: true});
            this.props.changeCurrentGameQuarter(this.props.game.id, newQuarterNumber, () =>
                this.setState({
                    log: '...',
                    isLoading: false,
                    hasChangedQuarter: true
                })
            );
        }
    }

    renderResult() {
        const hasFinished = this.state.log.trim().endsWith("DONE");

        return <div className={'content'}>
            <div className={'alert alert-success'}>
                <i className="material-icons">check</i>
                Změna kvartálu byla spuštěna.<br/>
                Vyčkejte, než se změna kvartálu dokončí. Může to trvat až několik minut.<br/>
                Jakmile se změna kvartálu dokončí, uvidíte "DONE" na konci logu níže.<br/>
                Poté obnovte stránku a měli byste být v novém kvartálu.
            </div>

            <button className="btn btn-primary mt-1 mb-4" disabled={!hasFinished} onClick={() => window.location.reload()}>Obnovit stránku</button>

            <h4>Log změny kvartálu</h4>
            <div className={'log-box mb-4'}>
                { nl2br(this.state.log) }
            </div>
        </div>
    }

    startRefreshingLog() {
        this.logRefreshInterval = setInterval(() => this.refreshLog(), 1000);
    }

    refreshLog() {
        this.props.loadSwitchQuarterLog(this.props.game.id, result => {
            this.setState({log: result.response.data});
        });
    }

    componentWillUnmount() {
        clearInterval(this.logRefreshInterval);
    }

}

const mapStateToProps = (state, ownProps) => {
    return {};
};

const mapDispatchToProps = {
    changeCurrentGameQuarter,
    loadSwitchQuarterLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TimeTravel))

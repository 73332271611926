/* eslint-disable no-undef */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Redirect, withRouter} from 'react-router-dom'
import {
    loadTeacherCompanies,
    loadTeacherGameUsers,
    loadTeacherMarkets
} from '../../../../actions/sync-modules'
import Loader from '../../../../components/Loader'
import Companies from "./Companies";
import Markets from "./Markets";
import {quarterNameFor} from "../../../../services/quarter";

class MarketsAndCompanies extends Component {

    constructor(props) {
        super(props);

        const gameId = this.props.match.params.gameId;

        this.state = {
            game: props.games[gameId]
        };

        this.props.loadTeacherCompanies(gameId);
        this.props.loadTeacherMarkets(gameId);
        this.props.loadTeacherGameUsers(gameId);
    }

    static getDerivedStateFromProps(props, state) {
        const gameId = props.match.params.gameId;
        return {
            game: props.games[gameId]
        };
    }

    render() {
        if (this.state.game === undefined) {
            return <Redirect to={'/auth/teacher/games'} />
        } else if (!this.props.didDownloadData) {
            return <Loader />
        } else {
            return <div>
                <Markets game={this.state.game} />
                <Companies game={this.state.game} />
                {this.renderMachineEstimationInfo()}
            </div>
        }
    }

    renderMachineEstimationInfo() {
        const lastQuarter = this.state.game.gameYearCount * 4;
        const quarterName = quarterNameFor(this.state.game, lastQuarter);

        return <div className={'alert alert-info'}>
            <b>*</b> Počet strojů, které potřebuje jedna firma, aby uspokojila poptávku při maximální ceně za situace, kdy všechny firmy na trhu prodávají za maximální cenu.
            Jedná se o údaj vztažený k poslednímu čtvrtletí hry ({quarterName}) pro aktuální počet firem na trhu.
            Údaj slouží pro ověření vygenerované poptávky a posouzení jejího vlivu na náročnost hry.
        </div>
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        games: state.entities.teacherGames,
        didDownloadData: state.downloadedEntities.teacherMarkets && state.downloadedEntities.teacherUsers && state.downloadedEntities.teacherCompanies
    }
};

const mapDispatchToProps = {
    loadTeacherCompanies,
    loadTeacherMarkets,
    loadTeacherGameUsers
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MarketsAndCompanies))

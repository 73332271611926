/* eslint-disable no-undef */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import TwoLineDashboardBox from '../../../../components/TwoLineDashboardBox'
import LargeNumberDashboardBox from '../../../../components/LargeNumberDashboardBox'
import StockChart from '../../../../components/StockChart'
import {currentQuarter, hasGameEnded, quarterNameFor} from '../../../../services/quarter'
import InvestLoanRequirement from './InvestLoanRequirement'
import {formatNumber} from "../../../../services/utils";
import CashFlow from "./CashFlow";
import InvestLoanExtraRepayment from "./InvestLoanExtraRepayment";
import {withTranslation} from "react-i18next";

class Dashboard extends Component {

    render() {
        const { t } = this.props;

        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <h1 className={'mb-4 title-out-of-box'}>
                            {this.props.hasGameEnded
                                ? <span> {t("dashBoard.endGameState", {companyName: this.props.companyName})}</span>
                                : <span> {t("dashBoard.begginingGameState", {companyName: this.props.companyName, quarterNameFor: quarterNameFor(this.props.game, this.props.currentQuarterNumber)})}</span>
                            }
                        </h1>
                    </div>
                    <div className="col-md-6 col-xl-3">
                        <TwoLineDashboardBox
                            icon="storage"
                            danger={this.props.isBankrupted}
                            link="/auth/student/company/production"
                        >
                            {this.renderGoodStorage()}
                        </TwoLineDashboardBox>
                    </div>
                    <div className="col-md-6 col-xl-3">
                        <TwoLineDashboardBox
                            icon="storage"
                            danger={this.props.isBankrupted}
                            link="/auth/student/company/material-purchase"
                        >
                            {this.renderMaterialStorage()}
                        </TwoLineDashboardBox>
                    </div>
                    <div className="col-md-6 col-xl-3">
                        <LargeNumberDashboardBox
                            danger={this.props.isBankrupted}
                            icon="staff"
                            number={this.props.employeeCount}
                            title={this.props.t("dashBoard.employee_interval", {postProcess: 'interval', count: this.props.employeeCount})}
                            link="/auth/student/company/staff"
                        />
                    </div>
                    <div className="col-md-6 col-xl-3">
                        <LargeNumberDashboardBox
                            danger={this.props.isBankrupted}
                            icon="machine"
                            number={this.props.machineCount}
                            title={this.props.t("dashBoard.machine_interval", {postProcess: 'interval', count: this.props.machineCount})}
                            link="/auth/student/company/production"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <CashFlow cashFlow={this.props.cashFlow} isBankrupted={this.props.isBankrupted} />
                    </div>
                    <div className="col-md-6">
                        <InvestLoanRequirement/>
                    </div>
                </div>

                <InvestLoanExtraRepayment />

                <div className="box">
                    <h3>{t("dashBoard.stock", {companyName: this.props.companyName})}</h3>
                    <div className="content">
                        <StockChart stockPrices={this.props.stockPrices} game={this.props.game} t={t}/>
                    </div>
                </div>
            </div>
        )
    }


    renderGoodStorage() {
        const { t } = this.props;
        return this.props.goodStorage.map(storageItem => {
            const good = this.props.goods.find(good => good.id === storageItem.goodId);
            return (
                <span key={good.id}>
                    {localStorage.getItem("language") == "cs"? good.name : good.nameEn}: <b>{formatNumber(storageItem.count)} {t("dashBoard.quantity")}</b><br/>
                </span>
            )
        });
    }

    renderMaterialStorage() {
        const { t } = this.props;
        return this.props.materialStorage.map(storageItem => {
            const material = this.props.materials.find(material => material.id === storageItem.materialId);
            return (
                <span key={material.id}>
                    {localStorage.getItem("language") == "cs"? material.name : material.nameEn }: <b>{formatNumber(storageItem.count)} {t("dashBoard.weight")}</b><br/>
                </span>
            )
        });
    }

}

const DashboardTranslated = withTranslation('translation')(Dashboard)

const mapStateToProps = (state, ownProps) => {
    let company = state.entities.company['first'];
    let game = state.entities.game['first'];
    let companyPreviousState = company.previousState;
    let companyCurrentState = company.currentState;

    return {
        game: game,
        companyName: company.name,
        currentQuarterNumber: currentQuarter(game).quarterNumber,
        employeeCount: companyCurrentState.employeeCount,
        machineCount: companyCurrentState.machineCount,
        cashFlow: companyPreviousState.cashFlow,
        stockPrices: companyPreviousState.stockPrices,
        goodStorage: companyPreviousState.goodStorage,
        materialStorage: companyPreviousState.materialStorage,
        goods: game.goods,
        materials: game.materials,
        isBankrupted: company.isBankrupted === true,
        hasGameEnded: hasGameEnded(game)
    }
};

export default withRouter(connect(mapStateToProps, {

})(DashboardTranslated))

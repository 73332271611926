/* eslint-disable no-undef */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Link, Redirect, withRouter} from 'react-router-dom'
import Loader from '../../../../components/Loader'
import {
    loadTeacherCompanies,
    loadTeacherSummaries
} from "../../../../actions/sync-modules";
import SummariesTable from "../../../../components/SummariesTable";

class CompanySummary extends Component {

    constructor(props) {
        super(props);

        const gameId = this.props.match.params.gameId;
        const companyId = this.props.match.params.companyId;

        this.state = {
            game: props.games[gameId],
            companyId: companyId
        };

        this.props.loadTeacherCompanies(gameId);
        this.props.loadTeacherSummaries(companyId);
    }

    render() {
        if (this.state.game === undefined) {
            return <Redirect to={'/auth/teacher/games'} />
        } else if (this.props.didDownloadData !== true) {
            return <Loader />
        } else {
            return this.renderContent();
        }
    }

    renderContent() {
        return <div className={'col-xl-9'}>
            <div className={'box'}>
                <div className={'headline-row'}>
                    <Link
                        to={'/auth/teacher/administration/' + this.state.game.id + '/summaries'}
                        className={'btn btn-primary ml-3 mt-3 mr-3'}
                    >&laquo; Zpět na firmy</Link>
                    <h3>
                        {this.props.companies[this.state.companyId].name}
                    </h3>
                </div>
                <div className={'content'}>
                    <SummariesTable game={this.state.game} summaries={this.props.summaries} />
                </div>
            </div>
        </div>;
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        games: state.entities.teacherGames,
        companies: state.entities.teacherCompanies,
        summaries: state.entities.summaries,
        didDownloadData: state.downloadedEntities['summaries'] && state.downloadedEntities['teacherCompanies']
    }
};

const mapDispatchToProps = {
    loadTeacherCompanies,
    loadTeacherSummaries,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanySummary))

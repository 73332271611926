import React from 'react'
import Loader from "../../../../components/Loader";
import PropTypes from 'prop-types'
import {sortSanitized, toArray} from "../../../../services/utils";
import Modal from 'react-modal';
import {Schemas} from "../../../../middleware/sync/schema";
import SelectSeminar from "../../../../components/SelectSeminar";
import update from "immutability-helper";
import {
    addNewTeacherCompany, deleteTeacherCompany,
     searchUsers,
    updateTeacherCompany, updateTeacherUser
} from "../../../../actions/sync-modules";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {removeErrorMessageById} from "../../../../actions/errors";
import {deleteEntityData} from "../../../../actions/entities";
import {existsServerError} from "../../../../services/errors";
import Tooltip from 'react-tooltip-lite';
import config from "../../../../config";

class Companies extends React.Component {

    static propTypes = {
        game: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            addNewCompany: false,
            selectUser: false,
            selectUserToCompany: null,
            searchQuery: '',
            isSearching: false,
            hasSearched: false,
        };
    }

    render() {
        const companyCount = toArray(this.props.teacherCompanies).length;
        const studentCount = toArray(this.props.teacherUsers).length;
        const studentWithoutCompanyCount = toArray(this.props.teacherUsers).filter(u => u.companyId === null).length;

        return (
            <div>
                <div className="box">
                    <div className="row headline-row">
                        <div className="col mr-auto">
                            <h3>Přehled firem</h3>
                        </div>
                        <div className="col mr-auto">
                            { this.props.game.marketsEnabled === false && (
                                <button
                                    className="float-right btn btn-primary m-3"
                                    onClick={() => this.setState({addNewCompany: true})}
                                    disabled={this.state.isAddingNewCompany === true || this.state.addNewCompany === true}
                                >Nová firma</button>
                            )}
                        </div>
                    </div>
                    <div className="content">
                        <table className="table table-responsive-md">
                            <thead>
                            <tr>
                                <th>Název firmy</th>
                                <th>Cvičení</th>
                                <th>Studenti ve firmě</th>
                                <th>Režim firmy</th>
                                <th> </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderNewCompany()}
                            {this.renderTableRows()}
                            </tbody>
                        </table>
                        <div className={"text-secondary mt-4"}>
                            Ve hře je registrováno {companyCount} firem a {studentCount} {studentCount < 5 ? "studenti" : "studentů"}.
                        </div>
                        {studentWithoutCompanyCount > 0 &&
                            <div className={"alert alert-warning"}>
                                {studentWithoutCompanyCount} studentů není v žádné firmě!
                            </div>
                        }
                    </div>
                </div>
                {this.renderModalWindow()}
            </div>
        )
    }

    renderTableRows() {
        const sortedCompanies = sortSanitized(toArray(this.props.teacherCompanies), 'name');

        return sortedCompanies.map(company => {
            const students = toArray(this.props.teacherUsers).filter(user => user.companyId === company.id);
            return (
                <tr key={company.id}>
                    <td>
                        <input
                            defaultValue={company.name}
                            onChange={e => this.updateTeacherCompany(this.props.game.id, company.id, {name: e.target.value, seminarId: company.seminarId})}
                            maxLength={config.maxCompanyNameLength}
                        /><br/>
                        <small className={'text-secondary'}>Zakladatel: {company.ownerName}</small>
                    </td>
                    <td>
                        <SelectSeminar
                            defaultValue={company.seminarId}
                            game={this.props.game}
                            onChange={value => this.updateTeacherCompany(this.props.game.id, company.id, {name: company.name, seminarId: value})}
                        />
                    </td>
                    <td>
                        {this.renderUserChips(students, company)}
                    </td>
                    <td>
                        {this.renderCompanyMode(students, company)}
                    </td>
                    <td className={'d-flex'}>
                        {this.renderDeleteCompanyButton(company)}
                    </td>
                </tr>
            );
        });
    }

    renderDeleteCompanyButton(company) {
        if (this.props.game.marketsEnabled) {
            return null;
        } else {
            return <div className={'ml-auto'}>
                <Tooltip content="Smazat firmu">
                    <span className="p-2 pl-3 pointer" onClick={() => {
                        if(window.confirm("Opravdu SMAZAT firmu " + company.name + "? Tato akce nelze vrátit!")) {
                            this.props.deleteTeacherCompany(this.props.game.id, company.id);
                        }
                    }}><i className="material-icons">delete_outline</i></span>
                </Tooltip>
            </div>
        }
    }

    updateTeacherCompany(gameId, companyId, data) {
        this.props.removeErrorMessageById('PUT-' + Schemas.TEACHER_COMPANY);
        this.props.updateTeacherCompany(gameId, companyId, data);
    }

    renderUserChips(students, company) {
        let results = students.map(student => {
            return (
                <span
                    key={student.name}
                    className="badge badge-pill badge-primary badge-user mr-3 mb-2"
                >
                    <span className="badge-text">{student.name}</span>
                    <span className="pointer close-in-badge" onClick={
                        () => this.removeUserCompany(student)
                    }><i className="material-icons">close</i></span>
                </span>
            )
        });

        if (company.mode === "manual") {
            results.push(<span key={'add-to-company'} className="plus-button" onClick={() => {
                this.props.deleteEntityData(Schemas.TEACHER_SEARCH_USER.key);
                this.setState({selectUser: true, selectUserToCompany: company});
            }}> </span>);
        }

        return results;
    }

    renderCompanyMode(students, company) {
        const mode = company.mode || 'manual';
        const isDisabled = students.length > 0;
        const disabledClassName = isDisabled ? 'disabled' : '';
        return (
            <span
                className={'d-inline-block company-mode-btn ' + mode + ' ' + disabledClassName}
                title={mode === "automatic" ? "automatický" : "manuální"}
                onClick={() => {
                    if (isDisabled === false) {
                        this.toggleCompanyMode(company);
                    }
                }}
            > </span>
        );
    }

    toggleCompanyMode(company) {
        const newMode = company.mode === "automatic" ? "manual" : "automatic";
        this.updateTeacherCompany(this.props.game.id, company.id, {
            "mode": newMode
        });
    }

    renderNewCompany() {
        if (this.state.addNewCompany) {
            return <tr key={'add-new-company'} className={'bg-light'} id={'new-company-row'}>
                <td>
                    <input
                        placeholder={'Zadejte název'}
                        defaultValue={this.state.newCompanyName}
                        onKeyPress={e => { if (e.key === 'Enter') this.addNewCompany(); }}
                        onChange={(e) => this.setState({ newCompanyName: e.target.value})}
                        autoFocus={true}
                        maxLength={config.maxCompanyNameLength}
                    />
                </td>
                <td>
                    <SelectSeminar
                        game={this.props.game}
                        defaultValue={this.state.newCompanySeminarId}
                        onChange={value => this.setState({ newCompanySeminarId: value})}
                    />
                </td>
                <td colSpan={3}>
                    <button
                        className="btn btn-primary btn-sm ml-3"
                        onClick={() => this.addNewCompany()}
                        disabled={!this.state.newCompanyName || !this.state.newCompanySeminarId}
                    >Vytvořit firmu</button>
                    <button
                        className="btn btn-primary btn-sm ml-3"
                        onClick={() => this.setState({addNewCompany: false})}
                    >Zrušit</button>
                </td>
            </tr>

        } else if (this.state.isAddingNewCompany) {
            return <tr><td colSpan={2}><Loader/></td></tr>
        }
    }

    addNewCompany() {
        if (!this.state.newCompanyName || !this.state.newCompanySeminarId) { return; }

        this.props.removeErrorMessageById('POST-' + Schemas.TEACHER_COMPANY.key);

        this.setState({addNewCompany: false, isAddingNewCompany: true}, () => {
            this.props.addNewTeacherCompany(this.props.game.id, this.state.newCompanyName, this.state.newCompanySeminarId, () => {
                if (this.props.existsCompanyCreateError === false) {
                    this.setState({isAddingNewCompany: false,newCompanyName: null, newCompanySeminarId: null});
                } else {
                    this.setState({isAddingNewCompany: false, addNewCompany: true});
                }
            });
        });
    }

    renderModalWindow() {
        const companyId = this.state.selectUserToCompany !== null ? this.state.selectUserToCompany.id : '';
        const companyName = this.state.selectUserToCompany !== null ? this.state.selectUserToCompany.name : '';

        Modal.setAppElement('#root');

        return (
            <Modal
                id="test"
                contentLabel="modalA"
                closeTimeoutMS={150}
                isOpen={this.state.selectUser}
                style={{content: {padding: 0}}}
            >
                <div className={'modal-headline-block'}>
                    <button className="btn btn-primary float-right ml-4" onClick={() => this.setState({selectUser: false})}>Zrušit</button>
                    <h3>Zvolte uživatele pro přidání do firmy {companyName}</h3>
                    <hr/>
                </div>
                <div className={'modal-content-block'}>
                    <div className={'alert alert-info'}>Po přidání do firmy bude uživatel automaticky registrován do této hry. Pokud již je uživatel v jiné hře nebo firmě, je neprve automaticky odebrán.</div>
                    {this.renderUserSearch(companyId)}
                    {this.renderUsersInCurrentGame(companyId)}
                </div>
            </Modal>
        )
    }

    renderUsersInCurrentGame(companyId) {
        const usersInGame = toArray(this.props.teacherUsers).filter(user => user.companyId !== companyId);

        return <div className={'mt-4'}>
            <h5>Uživatelé registrovaní do této hry</h5>
            <ul>{(usersInGame.length === 0) ? <div>Žádní uživatelé.</div> :
                sortSanitized(usersInGame, 'name').map(user => {
                    return <li
                        className={'text-primary mb-3 pointer'}
                        key={user.id}
                        onClick={() => this.changeUserCompany(user)}
                    >
                        <u>{user.name}</u>
                    </li>
                })
            }</ul>
        </div>
    }

    renderUserSearch(companyId) {
        const searchedUsers = toArray(this.props.teacherSearchUsers).filter(user => user.gameId !== this.props.game.id || user.companyId !== companyId);

        return <div className={'mt-5'}>
            <form
                onSubmit={e => {
                    this.searchUsers();
                    e.preventDefault();
                }}
                className={'form-inline mb-3'}
            >
                <input
                    onChange={e => this.setState({searchQuery: e.target.value})}
                    placeholder={'Jméno nebo login...'}
                    className={'mr-3'}
                    maxLength={config.maxString}
                />
                <button type="submit" className="btn btn-light">Hledat</button>
            </form>
            {this.renderSearchResults(searchedUsers)}
        </div>
    }

    searchUsers() {
        const encodedQuery = encodeURIComponent(this.state.searchQuery.trim());
        if (encodedQuery !== "") {
            this.setState({isSearching: true, hasSearched: true}, () => {
                this.props.searchUsers(encodedQuery, () => {
                    this.setState({isSearching: false});
                });
            });
        }
    }

    renderSearchResults(searchedUsers) {
        if (this.state.isSearching) {
            return <div className={'float-left clearfix'}><Loader/></div>
        } else {
            return <ul>
                {searchedUsers.length === 0 && this.state.hasSearched ? <div>Žádní uživatelé.</div> :
                    sortSanitized(searchedUsers, 'name').map(user => {
                        const game = this.props.games[user.gameId];
                        const gameName = game !== undefined ? game.name : 'není v žádné hře';
                        return <li
                            className={'text-primary mb-4 pointer'}
                            key={user.id}
                            onClick={() => this.changeUserCompany(user)}
                        >
                            <u>{user.name} ({gameName})</u>
                        </li>
                    })
                }
            </ul>;
        }
    }

    changeUserCompany(user) {
        const company = this.state.selectUserToCompany;
        this.setState({selectUser: false});
        this.props.updateTeacherUser(user, update(user, {
            gameId: {$set: this.props.game.id},
            companyId: {$set: company.id}
        }));
    }

    removeUserCompany(user) {
        this.props.updateTeacherUser(user, {companyId: null});
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        games: state.entities.teacherGames,
        teacherCompanies: state.entities.teacherCompanies,
        teacherMarkets: state.entities.teacherMarkets,
        teacherUsers: state.entities.teacherUsers,
        teacherSearchUsers: state.entities.teacherSearchUsers,
        didDownloadSearchResults: state.downloadedEntities.teacherSearchUsers,
        existsCompanyCreateError: existsServerError(state.errors, 'post', Schemas.TEACHER_COMPANY),
    }
};

const mapDispatchToProps = {
    addNewTeacherCompany,
    updateTeacherCompany,
    updateTeacherUser,
    deleteTeacherCompany,
    removeErrorMessageById,
    searchUsers,
    deleteEntityData
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Companies))

import {combineReducers} from 'redux'
import {isAuthenticated, isBeingAuthenticated, profilePicture} from "./user";
import {errors} from "./errors";
import {entities} from "./entities";
import {
    downloadedEntities,
    hasUnsavedChanges,
    isLoadingBasicData,
    isSyncing,
    isWaitingForSync,
    lastSave, syncQueue
} from "./sync";

const rootReducer = combineReducers({
    entities,
    errors,
    isSyncing,
    lastSave,
    syncQueue,
    isWaitingForSync,
    isAuthenticated,
    isBeingAuthenticated,
    isLoadingBasicData,
    downloadedEntities,
    hasUnsavedChanges,
    profilePicture
});

export default rootReducer

import React from 'react'
import PropTypes from 'prop-types'
import config from "../config";

class TextField extends React.Component {

    static propTypes = {
        title: PropTypes.string,
        defaultValue: PropTypes.string,
        description: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,
        name: PropTypes.string,
        maxLength: PropTypes.number,
        className: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            value: props.defaultValue || ""
        };
    }

    wrapDescription(description) {
        if (description === undefined) {
            return "";
        } else {
            return description.split('\n').map((item, key) => {
                return <span key={key}>{item}<br/></span>
            })
        }
    }

    render() {
        const { title, description, className } = this.props;
        const maxLength = this.props.maxLength === undefined ? config.maxString : this.props.maxLength;

        return (
            <div className={className == undefined ? "form-item mb-4": className}>
                <div className="form-label mb-1">{title}</div>
                <input
                    type="text"
                    className="w-100"
                    placeholder={this.props.placeholder}
                    value={this.state.value}
                    onChange={event => this.handleChange(event.target.value)}
                    disabled={this.props.disabled}
                    name={this.props.name}
                    maxLength={maxLength}
                />
                <div className="description">{this.wrapDescription(description)}</div>
            </div>
        );
    }

    handleChange(newValue) {
        this.setState({value: newValue});
        this.props.onChange(newValue);
    }
}

export default TextField

import mergeWith from "lodash/mergeWith";
import {DELETE_ALL_ENTITIES_DATA, DELETE_ENTITY_DATA, DELETE_ENTITY_DATA_BY_ID} from "../actions/entities";
import {entitiesStructure, Schemas} from "../middleware/sync/schema";
import update from "immutability-helper";
import * as ActionTypes from "../actions/sync-modules";
import {toArray} from "../services/utils";
import {currentQuarter} from "../services/quarter";

// Updates an entity cache in response to any action with response.entities.
export const entities = (state = entitiesStructure, action) => {
    let newState = state;

    let deletedData = deleteEntityData(newState, action);
    if (deletedData !== null) {
        return deletedData;
    }

    newState = updateEntityData(newState, action);
    newState = updateOpenedLoanRequirements(newState, action);

    return newState;
};

const deleteEntityData = (state, action) => {
    if (action.type === DELETE_ENTITY_DATA) {
        const copy = Object.assign({}, state);
        copy[action.entity] = {};
        return copy;
    } else if (action.type === DELETE_ALL_ENTITIES_DATA) {
        return Object.assign({}, entitiesStructure);
    } else if (action.type === DELETE_ENTITY_DATA_BY_ID) {
        return update(state, {[action.entity]: {$unset: [action.id]}});
    }

    return null;
};

const updateEntityData = (state, action) => {
    if (action.response && action.response.entities) {
        return mergeWith({}, state, action.response.entities, (a, b) => {
            if (Array.isArray(a) && Array.isArray(b)) {
                return b; // we do not want to merge arrays, they must be always replaced by new value
            }
        });
    }

    return state;
};

const updateOpenedLoanRequirements = (state, action) => {
    const key = Schemas.TEACHER_LOAN_REQUIREMENTS.key;
    const gameId = action.gameId;

    if (action.type === ActionTypes.UPDATE_OPENED_LOAN_REQUIREMENTS && state.teacherGames[gameId]) {
        const game = state.teacherGames[gameId];
        const requirements = toArray(state[key]);
        const openedRequirements = requirements.filter(i => i.accepted === false && i.acceptedByTeacher === null && i.quarterId === currentQuarter(game).id);
        const count = openedRequirements.length;

        return update(state, {teacherGames: {[gameId]: {openedLoanRequirements: {$set: count}}}});
    }

    return state;
};

import {REMOVE_ERROR_MESSAGE, RESET_ERROR_MESSAGE} from "../actions/errors";
import * as ActionTypes from "../actions/sync-modules";
import update from "immutability-helper";

// Updates error message to notify about the failed fetches.
export const errors = (state = [], action) => {
    const {type, error, indexes, id} = action;

    if (type === RESET_ERROR_MESSAGE || type === ActionTypes.LOGIN_SUCCESS) { // remove all errors
        return [];

    } else if (type === REMOVE_ERROR_MESSAGE && indexes !== undefined) { // remove a concrete error by array indexes
        const sortedIndexes = indexes.sort((a, b) => b - a);
        const splices = sortedIndexes.map(i => [i, 1]);
        return update(state, {$splice: splices});

    } else if (type === REMOVE_ERROR_MESSAGE && id !== undefined) { // remove a concrete error by error id
        const index = state.findIndex(i => i.id === id);
        if (index !== -1) {
            return update(state, {$splice: [[index, 1]]});
        }

    } else if (error) { // add new error
        let i = error;
        if (error instanceof Error) i = {error: error.message}; // convert Error to json
        return update(state, {$push: [i]});

    }

    return state
};

/* eslint-disable no-undef */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {loadInvestmentLoan, updateInvestmentLoan, uploadInvestmentLoanDocument} from '../../../../actions/sync-modules'
import Loader from '../../../../components/Loader'
import {
    currentQuarter,
    quarterNameFor,
    remainingHoursInCurrentQuarter,
    remainingTimeForLoanRequirement
} from "../../../../services/quarter";
import NumberField from '../../../../components/NumberField'
import FileUpload from '../../../../components/FileUpload'
import RemainingTimeProgressBar from "../../../../components/RemainingTimeProgressBar";
import LastChange from "../../../../components/LastChange";
import {removeErrorMessageById} from "../../../../actions/errors";
import {Schemas} from "../../../../middleware/sync/schema";
import config from "../../../../config";
import Tooltip from 'react-tooltip-lite';
import { withTranslation } from "react-i18next";

class InvestLoanRequirement extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            didLoadData: false,
            amount: 0,
            document: null,
            isUploading: false,
            isRequestPeriod: true
        };

        setInterval(() => this.checkPeriod(), 1000);

        this.props.loadInvestmentLoan();
    }

    checkPeriod() {
        const time = remainingTimeForLoanRequirement(this.props.game);
        this.setState({isRequestPeriod: time > 0});
    }

    render() {
        const { t } = this.props;
        if (this.props.didDownloadData === false) {
            return <Loader/>;
        }

        const disabledClassName = this.props.isBankrupted ? ' box-disabled ' : '';

        return (
            <div className={"box box-important" + disabledClassName}>
                <h3>{t("investLoanRequirement.application")}</h3>
                {this.renderContent()}
                <LastChange object={this.props.requirement} t={t} />
            </div>
        );
    }

    renderContent() {
        const { t } = this.props;
        if (this.props.isBankrupted) {
            return <div className="content text-secondary">
                {t("investLoanRequirement.applicationDenied")}
            </div>
        } else {
            return <div className="content">
                {this.renderAmount()}
                {this.renderExistingDocument()}
                {this.renderFileUpload()}
            </div>
        }
    }

    renderAmount() {
        const { t } = this.props;
        return <NumberField
            minValue={0}
            defaultValue={this.props.requirement.amount}
            title={t("investLoanRequirement.desiredAmount")}
            description={t("investLoanRequirement.interestRateInfo", {interestRate: this.props.interestRate, repaymentQuarter: this.props.repaymentQuarter})}
            onChange={(value) => this.setState({amount: value}, () => this.handleChange())}
            disabled={this.props.disabledChange || this.state.isUploading}
        />
    }

    renderExistingDocument() {
        const { t } = this.props;
        if (this.props.requirement.document === null || (this.props.requirement.acceptedByTeacher === null && this.props.disabledChange === false)) {
            return null;
        }

        const {id, document} = this.props.requirement;

        return (
            <div className="mb-4 mt-4">
                <a href={config.serverUrl + 'investment-loan-documents/' + id + '/' + document} target="_blank" rel="noopener noreferrer">
                    <i className="material-icons">cloud_download</i> {t("investLoanRequirement.download")}
                </a>
            </div>
        );
    }

    renderFileUpload(){
        const { t } = this.props;
        if (this.props.autoAccept) {
            return null;
        }

        if (this.props.requirement.acceptedByTeacher === true) {
            return <div className="text-success mb-4">{t("investLoanRequirement.applicationAccepted")}</div>
        }

        if (this.props.requirement.acceptedByTeacher === false) {
            return <div className="text-danger mb-4">{t("investLoanRequirement.applicationRejected")}</div>
        }

        if (this.props.disabledChange) {
            return <div className="text-success mb-4">{t("investLoanRequirement.applicationSentInfo")}</div>
        }

        const remainingHours = remainingHoursInCurrentQuarter(this.props.game, this.props.game.hoursForLoanRequirement);

        return <div>
            <FileUpload
                title={''}
                buttonTitle={this.state.document === null ? t("investLoanRequirement.selectAttachment") : t("investLoanRequirement.selectOther") }
                description={t("investLoanRequirement.fileUploadDescription")}
                accept={'.doc,.docx,.pdf,.odt'}
                disabled={this.state.isUploading}
                onChange={(documentUrl) => {
                    this.setState({document: documentUrl}, () => this.handleChange());
                }}
            />
            {this.state.isUploading ? <Loader/> :
                <button
                    className={'btn btn-primary'}
                    onClick={() => this.save()}
                    disabled={this.state.amount <= 0 || this.state.document === null || (this.props.autoAccept === false && this.state.isRequestPeriod === false)}
                >{t("investLoanRequirement.sendApplication")}</button>
            }
            <hr/>
            <div className="row mb-2">
                <div className="col-lg-6">
                    <div className={'mb-2 text-secondary d-flex'}>
                        <div>{t("investLoanRequirement.timeLimit")}</div>
                        <Tooltip
                            content={t("investLoanRequirement.remainingTimeInfo", {hoursForLoanRequirement: this.props.game.hoursForLoanRequirement})}>
                            <i className={"material-icons time-accuracy-icon " + (remainingHours < 1 ? 'text-danger' : '')}>info</i>
                        </Tooltip>
                    </div>
                    <RemainingTimeProgressBar
                        game={this.props.game}
                        offset={this.props.game.hoursForLoanRequirement}
                        title={''}
                    />
                </div>
            </div>
        </div>
    }

    handleChange() {
        if (this.props.autoAccept) {
            this.save();
        }
    }

    save() {
        this.props.removeErrorMessageById('POST-' + Schemas.INVESTMENT_LOAN.key);

        const saveAmount = () => this.props.updateInvestmentLoan({
            amount: this.state.amount
        });

        if (this.state.document !== null) {
            this.setState({isUploading: true});
            this.props.uploadInvestmentLoanDocument(this.state.document, () => {
                this.setState({isUploading: false});
                if (this.props.existsUploadError === false) {
                    saveAmount();
                }
            });
        } else {
            saveAmount();
        }
    }


}

const mapStateToProps = (state, ownProps) => {
    const game = state.entities.game['first'];
    const company = state.entities.company['first'];
    const requirement = state.entities.investmentLoan['first'];
    const autoAccept = company.previousState.hasBridgeLoan !== true && game.automaticLoan === true;

    return {
        company: company,
        game: game,
        requirement: requirement,
        didDownloadData: state.downloadedEntities.investmentLoan === true,
        interestRate: game.investmentLoanInterestRate * 100,
        repaymentQuarter: quarterNameFor(game, currentQuarter(game).quarterNumber + game.investmentLoanRepaymentDelay),
        autoAccept: autoAccept,
        disabledChange: autoAccept === false && requirement !== undefined && requirement.amount > 0, // the loan requirement has been already sent
        existsUploadError: state.errors.find(e => e.id === 'POST-' + Schemas.INVESTMENT_LOAN.key) !== undefined,
        isBankrupted: company.isBankrupted === true,
    }
};

const mapDispatchToProps = {
    loadInvestmentLoan,
    updateInvestmentLoan,
    uploadInvestmentLoanDocument,
    removeErrorMessageById,
};

const InvestLoanRequirementTranslated = withTranslation('translation')(InvestLoanRequirement)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvestLoanRequirementTranslated))

import { normalize } from 'normalizr'
import { camelizeKeys } from 'humps'
import config from '../../config'
import {toArray} from "../../services/utils";

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.
// Read more about Normalizr: https://github.com/paularmstrong/normalizr


// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
const sendServerRequest = (endpoint, schema, method = 'GET', data = {}, authorizationToken = null) => {
    // headers
    let parameters = {
        method: method.toUpperCase(),
        headers: {
            'Accept': 'application/json'
        },
        cache: 'no-cache',
    };

    // request body data
    if (method === 'POST' || method === 'PUT') {
        if (data instanceof FormData) {
            parameters.body = data;
        } else {
            parameters.body = JSON.stringify(data);
            parameters.headers['content-type'] ='application/json';
        }
    }

    // authorization using a bearer token
    if (authorizationToken !== null) {
        parameters.headers.authorization = 'Bearer ' + authorizationToken;
    }

    // request
    return fetch(config.serverUrl + endpoint, parameters)
        .then(response => {
            if (response.ok) {
                return parseSuccessfulResponse(method, response, schema);
            } else {
                if (response.status === 404 && schema !== null) {
                    return parseNotFoundResponse(schema);
                } else {
                    return parseFailedResponse(response, schema, method);
                }
            }
        });
};

const normalizeJson = (json, schema) => {
    const camelizedJson = camelizeKeys(json);
    const normalizedJson = normalize(camelizedJson, schema);

    Object.keys(normalizedJson["entities"]).forEach(key => {
        const entity = normalizedJson["entities"][key];
        if (entity[undefined] !== undefined) { // force delete of items in local state according to deleted items on the server
            delete entity[undefined];
        }
    });

    return normalizedJson;
};

const parseSuccessfulResponse = (method, response, schema) => {
    return response.json()
        .catch(err => {
            console.error(`'${err}' happened, but no big deal!`);
            return {};
        })
        .then(json => {

            // Automatically stores the received data in the local db.
            //
            // It does not store data from PUT and DELETE responses. These data
            // are updated immediately after the user changes something - before
            // sending the request. Storing data from PUTs and DELETE responses
            // would cause UI problems because PUT and DELETE requests are
            // all asynchronous (no spinner). The user does not have to wait for
            // the result and can continue in editing even during saving the data
            // on server.

            if (['GET', 'POST'].includes(method) && schema !== null) {
                return Object.assign({}, normalizeJson(json, schema));
            } else {
                return Object.assign({}, json);
            }
        })
};

const parseFailedResponse = (response, schema, method) => {
    const key = (schema === null) ? '' : schema.key;
    const id = method + '-' + key;
    const code = response.status;

    return response.json()
        .catch(err => {
            return Promise.reject({
                id: id,
                code: code,
                error: 'Fatal error'
            });
        })
        .then(json => {
            if (json.errors !== undefined) {
                json["error"] = toArray(json.errors).join(", ");
            }
            json["id"] = id;
            json["code"] = code;
            return Promise.reject(json);
        });
};

const parseNotFoundResponse = (schema) => {
    let entitites = {};
    entitites[schema.key] = {first: null};
    return {entities: entitites};
};


export default sendServerRequest;

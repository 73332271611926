import React from 'react'
import PropTypes from 'prop-types'
import SelectField from "./SelectField";

class SelectTimeInWeek extends React.Component {

    static propTypes = {
        defaultValue: PropTypes.string,
        onChange: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        const state = {
            dayValue: "",
            hourValue: ""
        };

        let defaultValue = props.defaultValue || '';
        const match = defaultValue.match(/([^0-9]+)([0-9:]+)/i); // e.g. 'Út (sudý týden) 9:00' -> ['Út (sudý týden) ', '9:00']
        if (match !== null) {
            state.dayValue = match[1].trim(); // remove leading space, e.g. 'Út (sudý týden) ' -> 'Út (sudý týden)'
            state.hourValue = match[2];
        }

        this.state = state;
    }

    render() {
        let options1 = [{title: 'Vyberte den', value: ''}];
        ['Po', 'Út', 'St', 'Čt', 'Pá'].forEach(day => {
            options1.push({title: day + ' (sudý týden)', value: day + ' (sudý týden)'});
            options1.push({title: day + ' (lichý týden)', value: day + ' (lichý týden)'});
        });

        let options2 = [{title: 'Vyberte čas', value: ''}];
        [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].forEach(hour => {
            options2.push({title: hour + ':00', value: hour + ':00'});
        });

        return (
            <div className={'row'}>
                <div className={'col'}>
                    <SelectField
                        options={options1}
                        onChange={day => {
                            this.setState({dayValue: day}, this.handleChange);
                        }}
                        defaultValue={this.state.dayValue}
                        name={'select-day'}
                    />
                </div>
                <div className={'col'}>
                    <SelectField
                        options={options2}
                        onChange={hour => {
                            this.setState({hourValue: hour}, this.handleChange);
                        }}
                        defaultValue={this.state.hourValue}
                        name={'select-hour'}
                    />
                </div>
            </div>
        );
    }

    handleChange() {
        if (this.state.dayValue !== '' && this.state.hourValue !== '') {
            const value = this.state.dayValue + ' ' + this.state.hourValue;
            this.props.onChange(value);
        } else {
            this.props.onChange(false);
        }
    }
}

export default SelectTimeInWeek

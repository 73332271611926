/* eslint-disable no-undef */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { loadSummaries } from '../../../../actions/sync-modules'
import Loader from '../../../../components/Loader'
import SummariesTable from "../../../../components/SummariesTable";
import { withTranslation } from "react-i18next";

class Summaries extends Component {

    constructor(props) {
        super(props);
        this.props.loadSummaries();
    }

    render() {
        return (
            <div className="box">
                <h3>{this.props.t("summaries.summaries")}</h3>
                <div className="content">
                    {this.renderSummaries()}
                </div>
            </div>
        )
    }

    renderSummaries() {
        if (!this.props.didDownloadData) {
            return <Loader/>;
        } else {
            return <SummariesTable game={this.props.game} summaries={this.props.summaries} />
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        game: state.entities.game['first'],
        summaries: state.entities.summaries,
        didDownloadData: state.downloadedEntities.summaries
    }
};

const mapDispatchToProps = {
    loadSummaries
};

const SummariesTranslated = withTranslation('translation')(Summaries)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SummariesTranslated))

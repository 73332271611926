export const UPDATE_ENTITY_DATA_BY_ID = 'UPDATE_ENTITY_DATA_BY_ID';
export const DELETE_ENTITY_DATA_BY_ID = 'DELETE_ENTITY_DATA_BY_ID';
export const DELETE_ENTITY_DATA = 'DELETE_ENTITY_DATA';
export const DELETE_ALL_ENTITIES_DATA = 'DELETE_ALL_ENTITIES_DATA';

export const updateEntityDataById = (schema, id, data) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_ENTITY_DATA_BY_ID,
        response: {
            entities: {
                [schema.key]: {
                    [id]: data
                }
            }
        }
    });
};

export const deleteEntityDataById = (schema, id) => (dispatch, getState) => {
    dispatch({
        type: DELETE_ENTITY_DATA_BY_ID,
        entity: schema.key,
        id: id
    });
};

export const deleteEntityData = (entity) => (dispatch, getState) => {
    dispatch({
        type: DELETE_ENTITY_DATA,
        entity: entity
    });
};

export const deleteAllEntitiesData = (entity) => (dispatch, getState) => {
    dispatch({
        type: DELETE_ALL_ENTITIES_DATA
    });
};
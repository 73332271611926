/* eslint-disable no-undef */

import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {Redirect} from "react-router-dom";
import ScoresTable from "../../../../components/ScoresTable";
import {generateTeacherStatistics, loadTeacherScores} from "../../../../actions/sync-modules";
import Loader from "../../../../components/Loader";
import config from "../../../../config";

class Scores extends Component {

    constructor(props) {
        super(props);

        const gameId = this.props.match.params.gameId;

        this.state = {
            game: props.games[gameId],
            hasStartedGeneration: false
        };

        this.props.loadTeacherScores(gameId);
    }

    render() {
        if (this.state.game === undefined) {
            return <Redirect to={'/auth/teacher/games'}/>
        }

        if (!this.props.didDownloadData) {
            return <Loader/>;
        }

        return (
            <div>

                <div className={'box'}>
                    <h3>Bodování a výsledky hospodaření</h3>
                    <div className={'content'}>
                        <ScoresTable scores={this.props.scores}/>
                    </div>
                </div>

                <div className={'box'}>
                    <h3>Statistiky</h3>
                    <div className={'content pb-3'}>
                        <div className={'mb-3'}>
                            <i>Slouží pro vygenerování obsáhlých statistik, které využije učitel pro prezentaci průběhu hry na konci semestru.</i>
                        </div>
                        {this.state.hasStartedGeneration &&
                        <div className='alert alert-success mt-3'>
                            <i className="material-icons">info</i>
                            Generování spuštěno. Zabere to nějakou chvilku. Může to trvat minuty až desítky minut.
                        </div>}
                        <span
                            onClick={() => this.generateStatistics()}
                            className={'btn btn-primary pointer'}
                        >Generovat</span>
                        <span
                            onClick={() => this.openStatistics()}
                            rel="noopener noreferrer"
                            className={'btn btn-primary ml-2 pointer'}
                        >Zobrazit</span>
                    </div>
                </div>

            </div>
        );
    }

    generateStatistics() {
        this.props.generateTeacherStatistics(this.state.game.id, () => {
            this.setState({hasStartedGeneration: true});
        });
    }

    openStatistics() {
        window.open(config.serverUrl + 'games/' + this.state.game.id + '/statistics?hash=' + this.state.game.documentHash + '&rand=' + Math.random());
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        games: state.entities.teacherGames,
        scores: state.entities.scores,
        didDownloadData: state.downloadedEntities.scores
    }
};

const mapDispatchToProps = {
    loadTeacherScores,
    generateTeacherStatistics
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Scores))

import React from 'react'
import {Redirect, withRouter} from 'react-router-dom'
import { connect } from "react-redux";
import {loadGame} from "../actions/sync-modules";

const GameSection = ({ didLoadGame, userHasGame, isLoadingBasicData, loadGame, children }) => {

    if (userHasGame === false) { // user is not registered to any game
        console.log(5);
        return <Redirect to={'/auth/student/register-to-game'}/>
    }

    if (didLoadGame === false) { // missing basic game data
        console.log(6);
        if (isLoadingBasicData === false) {
            loadGame();
        }
        return null;
    }

    return children;
};

export default withRouter(connect(state => {
    const user = state.entities.user['first'];
    const game = state.entities.game['first'];
    const userGame = state.entities.userGame['first'];
    const downloaded = state.downloadedEntities;

    return {
        userHasGame: user.gameId !== null || (userGame !== undefined && userGame !== null) || game !== undefined,
        didLoadGame: downloaded.game === true,
        isLoadingBasicData: state.isLoadingBasicData.isLoading,
    }
}, {
    loadGame
})(GameSection));




import React from 'react'
import PropTypes from 'prop-types'
import 'moment/locale/cs'; // this is required to prevent a bug when czech locale is not loaded
import 'react-datepicker/dist/react-datepicker.css';
import {Link} from "react-router-dom";
import onClickOutside from "react-onclickoutside";

class TableRowDropdown extends React.Component {

    static propTypes = {
        items: PropTypes.array.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        }
    }

    render() {
        return (
            <div className="dropdown text-right">

                <span className={'pointer mr-2'} onClick={() => {
                    this.setState({isVisible: !this.state.isVisible});
                }}><i className="material-icons">more_vert</i></span>

                <div className={"dropdown-menu dropdown-menu-right " + (this.state.isVisible ? 'd-block' : 'd-none')} aria-labelledby="dropdownMenuButton">
                    {this.props.items.map(item => this.renderDropdownItem(item))}
                </div>

            </div>
        );
    }

    renderDropdownItem(item) {
        if (item.linkTo !== undefined) {
            return <Link key={item.title} to={item.linkTo} className="dropdown-item pointer">{item.title}</Link>
        } else {
            return <span key={item.title} onClick={() => item.onClick()} className="dropdown-item pointer">{item.title}</span>
        }
    }

    handleClickOutside(evt) {
        this.setState({isVisible: false});
    }

}

export default onClickOutside(TableRowDropdown);
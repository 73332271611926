import React from 'react'

const Error = ({children}) => {
    return (
        <div className="bg-danger text-white p-3 mb-3 w-100">
            {children}
        </div>
    )
};

Error.propTypes = {

};

export default Error

import React, {Component} from 'react'
import {Prompt, withRouter} from 'react-router-dom';
import { connect } from 'react-redux'
import { logout } from '../actions/sync-modules'
import Loader from '../components/Loader'
import Menu from './Menu'
import Navbar from './Navbar'
import Main from "./Main";
import {savedAllChanges} from "../actions/sync-changes-tracking";
import {hasGameEnded} from "../services/quarter";
import { withTranslation } from "react-i18next";

class Layout extends Component {

    static propTypes = {

    };

    constructor(props) {
        super(props);

        this.handleLeavePage = this.handleLeavePage.bind(this);

        this.state = {
            mobileMenuVisible: false
        };
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.handleLeavePage);
    }


    handleLeavePage(e) {
        // this is called while reloading or giong to another webpage, not while changing pages via react routing on the same website
        if (this.props.isSyncing || this.props.isWaitingForSync || this.props.hasUnsavedChanges) {
            const confirmationMessage = this.props.t("layout.confirmationMessage");
            e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
            return confirmationMessage; // Gecko, WebKit, Chrome <34
        } else {
            // do nothing to avoid the dialogue
            // do not return false
        }
    }

    componentDidUpdate(prevProps) {
        // when the user is to leave the page and confirmed not to save the changes
        if (this.props.location !== prevProps.location) {
            this.props.savedAllChanges();
        }
    }

    render() {
        const {pathname} = this.props.location;

        return (
            <div>
                {this.renderPageLeaveAlert()}
                <Navbar toggleMobileMenu={() => this.setState({mobileMenuVisible: !this.state.mobileMenuVisible})} />
                <div
                    className={this.getCorrectBackground(pathname)}
                    onClick={() => this.setState({userMenuDisplayed: false})}
                >
                    {this.renderContent()}
                </div>
            </div>
        )
    }

    getCorrectBackground(pathname){
        if (pathname === '/login' && localStorage.getItem("language") === "cs"){
            return 'container-fluid login-bg-cs'
        } else if (pathname === '/login' && localStorage.getItem("language") === "en") {
            return 'container-fluid login-bg-en'
        } else {
            return 'container-fluid' 
        }


    }

    renderPageLeaveAlert() {
        // this is called when the react page is changing, it is not called while reloading or going to another web
        return <Prompt
            when={this.props.hasUnsavedChanges}
            message={location => this.props.t("layout.leaveMessage")}
        />
    }

    renderContent() {
        if (this.props.isLoadingBasicData) {
            return (
                <Main>
                    <Loader/>
                </Main>
            );
        } else
        if (this.props.location.pathname.startsWith('/auth/student/company/') || this.props.location.pathname.startsWith('/auth/teacher/administration/')) {
            return (
                <div className="row">
                    <Menu
                        mobileMenuVisible={this.state.mobileMenuVisible}
                        hideMenu={() => this.setState({mobileMenuVisible: false})}
                    />
                    <div className="col-xs-12 col-md-8 col-lg-9 col-xl-10">
                        <Main>
                            {this.props.hasGameEnded &&
                                <div className="alert alert-success" role="alert">
                                    <i className="material-icons">check_circle_outline</i>
                                    {this.props.t("layout.gameHasEnded")}
                                </div>
                            }
                            {this.props.children}
                        </Main>
                    </div>
                </div>
            );
        } else {
            return (
                <Main>
                    {this.props.children}
                </Main>
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const user = state.entities.user['first'];
    const game = state.entities.game['first'];
    const company = state.entities.company['first'];

    return {
        user: user,
        userName: user === undefined ? '' : user.name,
        game: game,
        company: company,
        isAuthenticated: state.isAuthenticated,
        isLoadingBasicData: state.isAuthenticated && state.isLoadingBasicData.isLoading,
        isSyncing: state.isSyncing,
        isWaitingForSync: state.isWaitingForSync,
        hasUnsavedChanges: state.hasUnsavedChanges,
        hasGameEnded: hasGameEnded(game)
    }
};

const LayoutTranslated = withTranslation('translation')(Layout)

export default withRouter(connect(mapStateToProps, {
    logout,
    savedAllChanges
})(LayoutTranslated))

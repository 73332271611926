import React from 'react'
import PropTypes from 'prop-types'
import { quarterNameFor } from "../../../../services/quarter";
import {formatNumber} from "../../../../services/utils";

const MaterialSurveyTable = ({ game, data, t }) => {

    if (data === undefined) {
        return <div>{t("materialSurveyTable.wasNotOrdered")}</div>
    }

    data.quarters.sort((a, b) => a.quarterNumber - b.quarterNumber);

    const tableRows = data.quarters.map(quarterSurvey =>
        <tr key={quarterSurvey.quarterNumber}>
            <td>{quarterNameFor(game, quarterSurvey.quarterNumber)}</td>
            <td>{formatNumber(quarterSurvey.price)}</td>
        </tr>
    );

    return (
        <table className="table table-responsive">
            <thead>
            <tr><th>{t("materialSurveyTable.quarter")}</th><th>{t("materialSurveyTable.price")}</th></tr>
            </thead>
            <tbody>
            {tableRows}
            </tbody>
        </table>
    )
};

MaterialSurveyTable.propTypes = {
    game: PropTypes.object.isRequired,
    data: PropTypes.object,
    t: PropTypes.func.isRequired
};

export default MaterialSurveyTable

/* eslint-disable no-undef */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'
import ScoresTable from "../../../../components/ScoresTable";
import {loadScores} from "../../../../actions/sync-modules";
import Loader from "../../../../components/Loader";
import { withTranslation } from "react-i18next";

class Scores extends Component {

    constructor(props) {
        super(props);
        this.props.loadScores();
    }

    render() {
        const { t } = this.props;
        if (!this.props.didDownloadData) {
            return <Loader/>;
        }

        return (
            <div>
                <div className={'box'}>
                    <h3>{t("scores.results")}</h3>
                    <div className={'content'}>
                        <ScoresTable scores={this.props.scores}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        scores: state.entities.scores,
        didDownloadData: state.downloadedEntities.scores === true
    }
};

const mapDispatchToProps = {
    loadScores
};

const ScoresTranslated = withTranslation('translation')(Scores)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScoresTranslated))

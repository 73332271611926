import React from 'react'
import PropTypes from 'prop-types'
import { quarterNameFor } from "../../../../services/quarter";
import {toArray} from "../../../../services/utils";


const StaffTable = ({ game, data, t }) => {
    data = toArray(data);
    data.sort((a, b) => a.quarterNumber - b.quarterNumber);
    
    return (
        <table className="table table-responsive table-centered-except-first-column">
            <thead>
            <tr>
                <th> </th>
                {data.map(item => <th key={item.quarterNumber}>{quarterNameFor(game, item.quarterNumber)}</th>)}
            </tr>
            </thead>
            <tbody>
            <tr>
                <th>{t("staffTable.number")}</th>
                {data.map(item => <td key={item.quarterNumber}>{item.employees}</td>)}
            </tr>
            <tr>
                <th>{t("staffTable.personalIndex")}</th>
                {data.map(item => <td key={item.quarterNumber}>{item.personalIndex}</td>)}
            </tr>
            <tr>
                <th>{t("staffTable.salaryIndex")}</th>
                {data.map(item => <td key={item.quarterNumber}>{item.salaryIndex}</td>)}
            </tr>
            <tr>
                <th>{t("staffTable.education")}</th>
                {data.map(item => <td key={item.quarterNumber}>{item.education ? t("staffTable.yes") : t("staffTable.no")}</td>)}
            </tr>
            </tbody>
        </table>
    )
};

StaffTable.propTypes = {
    game: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

export default StaffTable

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SliderWithField from '../../../../components/SliderWithField'
import { updateSells } from '../../../../actions/sync-modules'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import LastChange from '../../../../components/LastChange'
import { withTranslation } from "react-i18next";

class SellsOfGood extends Component {

    static propTypes = {
        good: PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            minPrice: PropTypes.number.isRequired,
            maxPrice: PropTypes.number.isRequired,
        }).isRequired,
        quarterName: PropTypes.string.isRequired,
        count: PropTypes.number,
        price: PropTypes.number,
        sell: PropTypes.object.isRequired,
        storageCount: PropTypes.number,
    };

    constructor(props) {
        super(props);

        this.state = {
            count: props.count,
            price: props.price
        }
    }


    render() {
        const { good, quarterName, count, price, storageCount } = this.props;
        const { maxPrice, minPrice } = this.props.good;
        const { t } = this.props;


        return (
            <div className="col-lg-6">
                <div className="box box-important">
                    <h3>{t("sellsOfGood.sellsHeader", {name: localStorage.getItem("language") == "cs"? good.name : good.nameEn, quarterName: quarterName})}</h3>
                    <div className="content">
                        <SliderWithField
                            title={t("sellsOfGood.quantity")} defaultValue={count || 0} minValue={0} maxValue={storageCount || 0} step={1}
                            onChange={newCount => {
                                this.setState({
                                    count: parseInt(newCount, 10)
                                }, this.handleChange);
                            }}
                        />
                        <hr />
                        <SliderWithField
                            title={t("sellsOfGood.price")}  defaultValue={price || 0} minValue={minPrice} maxValue={maxPrice} step={1}
                            onChange={newPrice => {
                                this.setState({
                                    price: parseInt(newPrice, 10)
                                }, this.handleChange);
                            }}
                        />
                    </div>
                    <LastChange object={this.props.sell} t={t} />
                </div>
            </div>
        )
    }

    handleChange() {
        this.props.updateSells(this.props.good.id, this.state);
    }

}

const mapStateToProps = (state, ownProps) => {
    
    return {
    }
};

const mapDispatchToProps = {
    updateSells
};

const SellsOfGoodTranslated = withTranslation('translation')(SellsOfGood)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SellsOfGoodTranslated))

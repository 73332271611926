import {dateFromUnixTimestamp} from "./utils";

export function quarterNameFor(game, quarterNumber) {
    if (quarterNumber < 1){
        return "-";
    }

    const initialYear = game.fictiveYearOfCompanyEstablishement;
    const year = Math.floor((quarterNumber - 1) / 4);
    const quarter = quarterNumber - (year * 4);

    return 'Q' + (quarter) + ' ' + (initialYear + year);
}

export function currentQuarter(game) {
    if (game === undefined || game === null) {
        return {
            quarterNumber: -1,
            end: null
        };
    } else {
        const result = game.quarters.find(a => a.quarterNumber === game.currentQuarterNumber);
        if (result === undefined) {
            return {
                quarterNumber: -1,
                end: null
            };
        } else {
            return result;
        }
    }
}

export function currentQuarterPeriod(game) {
    const quarter = currentQuarter(game);
    const previousQuarter = game.quarters.find(i => i.quarterNumber === quarter.quarterNumber - 1);

    let startOfQuarter = game.endOfRegistrations;
    if (previousQuarter !== undefined && previousQuarter.end !== null) {
        startOfQuarter = previousQuarter.end;
    }

    const endOfQuarter = quarter.end;

    return {
        start: dateFromUnixTimestamp(startOfQuarter),
        end: dateFromUnixTimestamp(endOfQuarter)
    };
}

export function currentQuarterLengthInHours(game) {
    const oneHour = 60*60*1000; // minutes*seconds*milliseconds
    const period = currentQuarterPeriod(game);

    if (period.end === null) {
        return 0;
    }

    return Math.round((period.end.getTime() - period.start.getTime()) / oneHour);
}

export function remainingTimeInCurrentQuarter(game, offset = 0) {
    const periodEnd = currentQuarterPeriod(game).end;
    const now = (new Date()).getTime();

    if (periodEnd === null){
        return null;
    }

    const difference = periodEnd.getTime() - now - offset;

    return difference <= 0 ? -1 : difference;
}

export function remainingHoursInCurrentQuarter(game, hourOffset = 0) {
    const oneHour = 60*60*1000; // minutes*seconds*milliseconds
    const remainingSeconds = remainingTimeInCurrentQuarter(game, hourOffset * oneHour);

    return Math.floor(remainingSeconds / oneHour);
}

export function remainingTimeForLoanRequirement(game) {
    const oneHour = 60*60*1000; // minutes*seconds*milliseconds
    return remainingTimeInCurrentQuarter(game, game.hoursForLoanRequirement * oneHour);
}

export function hasGameEnded(game) {
    if (game === undefined) {
        return false;
    } else {
        return game.hasEnded;
    }
}


export function isRegistrationPeriod(game) {
    if (game === undefined) {
        return false;
    } else {
        return game.isRegistrationPeriod;
    }
}

export function isGameRunning(game) {
    if (game === undefined) {
        return false;
    }

    const lastInitialYearQuarter = game.initialYear * 4;
    const lastGameQuarter = game.gameYearCount * 4;
    const currentQuarterNumber = currentQuarter(game).quarterNumber;

    return currentQuarterNumber > lastInitialYearQuarter && currentQuarterNumber <= lastGameQuarter;
}


import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from "react-router-dom";

const LargeNumberDashboardBox = ({ icon, number, title, danger, link, history }) => {
    let className = danger ? ' bg-danger ' : '';
    const value = number !== undefined && number !== null ? number : "??";

    if (link !== undefined) {
        className += ' pointer ';
    }

    const onClick = () => {
        if (link !== undefined) {
            history.push(link);
        }
    };

    return (
        <div className={"dashboard-box" + className} onClick={onClick}>
            <div className={"icon " + icon}>&nbsp;</div>
            <div className="large-number">{value}</div>
            {title}
        </div>
    )
};

LargeNumberDashboardBox.propTypes = {
    icon: PropTypes.string.isRequired,
    number: PropTypes.number,
    title: PropTypes.string.isRequired,
    danger: PropTypes.bool,
    link: PropTypes.string
};

export default withRouter(LargeNumberDashboardBox)

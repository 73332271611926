import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from "react-datepicker";
import moment from "moment";
import 'moment/locale/cs'; // this is required to prevent a bug when czech locale is not loaded
import 'react-datepicker/dist/react-datepicker.css';

class DateField extends React.Component {

    static propTypes = {
        title: PropTypes.string,
        defaultValue: PropTypes.number,
        description: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        returnEndOfDay: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            value: (props.defaultValue === null || props.defaultValue === undefined) ? null : moment.unix(props.defaultValue)
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            value: (props.defaultValue === null || props.defaultValue === undefined) ? null : moment.unix(props.defaultValue)
        }
    }

    wrapDescription(description) {
        if (description === undefined) {
            return "";
        } else {
            return description.split('\n').map((item, key) => {
                return <span key={key}>{item}<br/></span>
            })
        }
    }

    render() {
        const { title, description } = this.props;

        return (
            <div className="form-item mb-4">
                <div className="form-label mb-1">{title}</div>
                <DatePicker
                    selected={this.state.value}
                    onChange={this.handleChange}
                    className="w-100"
                    locale={'cs'}
                    dateFormat={'l'}
                    placeholderText={this.props.placeholder}
                    disabled={this.props.disabled}
                />
                <div className="description">{this.wrapDescription(description)}</div>
            </div>
        );
    }

    handleChange(newValue) {
        this.setState({value: newValue});

        if (newValue !== null && this.props.returnEndOfDay === true) {
            newValue = newValue.endOf('day')
        }

        this.props.onChange(newValue === null ? null : newValue.unix());
    }
}

export default DateField

import React from 'react'
import PropTypes from 'prop-types'

const SavingIndicator = ({ isLoading, lastSave, t }) => {
    if (isLoading) {
        return (
            <div className="saving-now">
                <div className="loader"></div>
                <div>{t("savingIndicator.saving")}</div>
            </div>
        );
    } else if (lastSave !== null) {
        return (
            <div className="last-saved">
                <i className="material-icons">
                    check_circle
                </i>
                <div className="d-md-none">{t("savingIndicator.saved")}</div>
                <div className="d-none d-md-inline">{t("savingIndicator.autosaved", {lastSave: lastSave})}</div>
            </div>
        );
    } else {
        return null;
    }
};

SavingIndicator.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    lastSave: PropTypes.string,
    t: PropTypes.func.isRequired
};

export default SavingIndicator

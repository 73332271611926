import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import {connect} from "react-redux";
import {deleteTeacherGame, loadTeacherGames} from "../../../../actions/sync-modules";
import Loader from "../../../../components/Loader";
import {toArray} from "../../../../services/utils";
import {currentQuarter, hasGameEnded, isRegistrationPeriod} from "../../../../services/quarter";
import TableRowDropdown from "../../../../components/TableRowDropdown";

class Games extends React.Component {

    static propTypes = {
    };

    constructor(props) {
        super(props);

        this.state = {
            menus: [],
        };

        this.props.loadTeacherGames();
    }

    render() {
        if (!this.props.didDownloadData) {
            return <Loader/>
        }

        return (
            <div className="container">
                <div className="box">
                    <div className="row headline-row">
                        <div className="col mr-auto">
                            <h3>Strategické hry</h3>
                        </div>
                        <div className="col mr-auto">
                            <Link to={'/auth/teacher/games/new'} className="float-right btn btn-primary m-3">Nová hra</Link>
                        </div>
                    </div>
                    <div className="content">
                        <table className="table table-responsive-md table-hover">
                            <thead>
                            <tr>
                                <th>Název</th>
                                <th>Kolo</th>
                                <th>Stav</th>
                                <th>Žádosti</th>
                                <th> </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderTableRows()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    renderTableRows() {
        const arr = toArray(this.props.games);

        if(arr.length === 0) {
            return <tr><td colSpan={5}>Žádné hry.</td></tr>
        }

        return arr.map(item => {
            return (
                <tr key={item.id}>
                    <td>
                        {
                            item.isRunningCalculations
                                ? item.name
                                : <Link to={'/auth/teacher/administration/' + item.id + '/markets-and-companies'}>{item.name}</Link>
                        }
                    </td>
                    <td>{currentQuarter(item).quarterNumber}</td>
                    <td>{this.getGameState(item)}</td>
                    <td>{
                        item.openedLoanRequirements === 0 ? '0' :
                            <Link
                                className="badge badge-pill badge-danger pointer"
                                to={'/auth/teacher/administration/' + item.id + '/loan-requirements'}
                            >
                                {item.openedLoanRequirements}
                            </Link>
                    }</td>
                    <td>
                        <TableRowDropdown items={
                            item.isRunningCalculations
                                ? []
                                : [
                                    {title: "Upravit", linkTo: '/auth/teacher/games/' + item.id},
                                    {title: "Smazat", onClick: () => {
                                            if(window.confirm("Opravdu SMAZAT CELOU HRU " + item.name + "? Tato akce nelze vrátit!")) {
                                                this.props.deleteTeacherGame(item.id);
                                            }
                                        }}
                                ]
                        }/>
                    </td>
                </tr>
            )
        });
    }

    getGameState(game) {
        if (game.isRunningCalculations) {
            return "probíhají výpočty";
        } else if (isRegistrationPeriod(game)) {
            return "registrace";
        } else if (game.marketsEnabled === false) {
            return "editace trhů";
        } else if (hasGameEnded(game)) {
            return "ukončená";
        } else {
            return "běží";
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        games: state.entities.teacherGames,
        didDownloadData: state.downloadedEntities.teacherGames
    }
};

export default withRouter(connect(mapStateToProps, {
    loadTeacherGames,
    deleteTeacherGame
})(Games))

import React from 'react'
import {withRouter,Link} from 'react-router-dom'
import {connect} from "react-redux";
import { CZ } from 'country-flag-icons/react/3x2'
import { US } from 'country-flag-icons/react/3x2'
import i18next from "i18next";

class LanguageOptions extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            lng: localStorage.getItem("language"),
            options: [
                {title: "Čeština", value: "cs", icon: CZ},
                {title: "English", value: "en", icon: US},
            ]
        };

    }

    render() {
        return (
            <div className="mr-2">
                <button
                 className="btn-lang"
                    onClick={() => this.onClick()}>
                    { this.state.lng == "cs" ? <US/> : <CZ/>}
                </button>
            </div>
        )
    }
        onChange = (index) => {
            let lng = this.state.options[index].value
            localStorage.setItem("language", lng)
            i18next.changeLanguage(lng)
            this.setState({lng})
        }

        onClick = () => {
            let targetLng = this.state.lng == "cs" ? "en" : "cs"
            localStorage.setItem("language", targetLng)
            i18next.changeLanguage(targetLng)
            this.setState({lng: targetLng})
            
        }

}

const mapStateToProps = (state, ownProps) => {
    return {


    }
};



export default  withRouter(connect(mapStateToProps)(LanguageOptions));

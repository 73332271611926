import React from 'react'
import {Redirect, withRouter} from 'react-router-dom'
import {connect} from "react-redux";
import TextField from "../../../../components/TextField";
import {createTeacherGame, loadTeacherGameDefaults, updateTeacherGame} from "../../../../actions/sync-modules";
import NumberField from "../../../../components/NumberField";
import DateField from "../../../../components/DateField";
import moment from "moment"
import update from 'immutability-helper'
import {generateEffectivity, EffectivityLevel} from "./GameMachineEffectivityGenerator";
import SelectField from "../../../../components/SelectField";
import Loader from "../../../../components/Loader";
import {Schemas} from "../../../../middleware/sync/schema";
import SelectTimeInWeek from "../../../../components/SelectTimeInWeek";
import {madeChangesWithoutSaving, savedAllChanges} from "../../../../actions/sync-changes-tracking";
import {firstUpper} from "../../../../services/utils";
import {resetErrorMessage, showErrorMessage} from "../../../../actions/errors";
import config from "../../../../config";
import { withTranslation } from 'react-i18next';
import { CZ } from 'country-flag-icons/react/3x2';
import { US } from 'country-flag-icons/react/3x2';

class Game extends React.Component {

    static propTypes = {

    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        const gameId = this.props.match.params.id;

        // default form values
        let game = {};
        let gameHasStarted = false;
        let currentQuarterNumber = null;

        // id autoincrements
        let lastGoodId = 0;
        let lastMaterialId = 0;

        if (gameId === "new") {
            this.initializeNewGame();
        } else {
            ({game, currentQuarterNumber, lastGoodId, lastMaterialId, gameHasStarted} = this.processExistingGame(gameId));
        }

        this.state = {
            machineTimeMenus: [],
            currentTab: 0,
            isCreatingNewGame: gameId === "new",
            isSaving: false,
            redirectToGames: false,
            newSeminarName: false,
            isLoading: gameId === "new",

            lastMaterialId: lastMaterialId,
            lastGoodId: lastGoodId,
            game: game,
            gameHasStarted: gameHasStarted,
            currentQuarterNumber: currentQuarterNumber
        };

    }

    initializeNewGame() {
        // download default values for the new game from the server
        this.props.loadTeacherGameDefaults(() => {
            let game = this.props.gameDefaults;
            game.productionEffectivity.forEach(e => {
                const hardness = e.effectivity === 'high' ? EffectivityLevel.high : EffectivityLevel.low;
                e.effectivity = generateEffectivity(hardness);
            });
            this.setState({
                game: game,
                currentQuarterNumber: null,
                lastGoodId: game.goods.length + 1,
                lastMaterialId: game.materials.length + 1,
                isLoading: false,
                gameHasStarted: game !== undefined && game.currentQuarterNumber > 0,
            });
        });
    }

    processExistingGame(gameId) {
        // extract ids from an existing game
        let lastGoodId = 0;
        let lastMaterialId = 0;
        let gameHasStarted = false;
        let currentQuarterNumber = null;

        let game = this.props.games[gameId];

        if (game !== undefined) {
            game.goods.forEach(good => {
                if (good.id > lastGoodId) lastGoodId = good.id;
            });
            game.materials.forEach(material => {
                if (material.id > lastMaterialId) lastMaterialId = material.id;
            });
            currentQuarterNumber = game.currentQuarterNumber;
            gameHasStarted = currentQuarterNumber > 0;
        }

        return { game, currentQuarterNumber, lastGoodId, lastMaterialId, gameHasStarted }
    }

    reloadGame() {
        this.setState(this.processExistingGame(this.state.game.id));
    }

    render() {
        if (this.state.game === undefined || this.state.redirectToGames) {
            return <Redirect to={'/auth/teacher/games'} />
        }

        if (this.state.isLoading) {
            return <Loader/>
        }

        return (
            <div className="box">
                <div className="headline-row clearfix">
                    {this.renderCloseButton()}
                    {this.renderSaveChangesButton()}
                    <h2>{this.state.isCreatingNewGame ? 'Vytvoření nové hry' : 'Úprava hry'}</h2>
                </div>
                <div className="content">
                    {this.renderContent()}
                </div>
            </div>
        )
    }

    renderCloseButton() {
        if (this.state.isCreatingNewGame || this.state.isSaving) {
            return null;
        } else {
            return <button className={'btn btn-light text-danger m-3 float-right'} onClick={() => this.cancelEditing()}>Zavřít</button>
        }
    }

    renderSaveChangesButton() {
        if (this.state.isCreatingNewGame || this.state.isSaving) {
            return null;
        } else if (this.props.noUnsavedChanges) {
            return <button className={'btn btn-success ml-3 mt-3 mb-3 float-right'} onClick={() => this.saveGame()}>Uloženo</button>
        } else {
            return <button className={'btn btn-primary ml-3 mt-3 mb-3 float-right'} onClick={() => this.saveGame()}>Uložit změny</button>
        }
    }

    cancelEditing() {
        const what = this.state.isCreatingNewGame ? 'vytváření' : 'úpravu';
        const isConfirmed = () => window.confirm("Opravdu zrušit " + what + " hry? Hra nebude uložena.");

        if (this.props.noUnsavedChanges || isConfirmed()) {
            this.props.savedAllChanges();
            this.props.resetErrorMessage();
            this.setState({redirectToGames: true});
        }
    }

    renderContent() {
        if (this.state.isSaving) {
            return <Loader />
        } else {
            return (
                <div>
                    <div className="tabs row">
                        {this.renderTabs()}
                    </div>
                    <hr/>
                    {this.renderForm(this.state.currentTab)}
                    {this.renderButtons()}
                </div>
            );
        }
    }

    renderButtons(){
        let a = [];
        if (this.state.isCreatingNewGame) {
            a.push(<button className={'btn btn-light text-danger mr-auto'} key={'a'} onClick={() => this.cancelEditing()}>Zrušit</button>);
        }
        if (this.state.currentTab > 0) {
            a.push(<button className={'btn btn-light mr-3'} key={'b'} onClick={() => this.setState({currentTab: this.state.currentTab - 1})}>Předchozí</button>)
        }
        if (this.state.currentTab < 5) {
            a.push(<button className={'btn btn-primary'} key={'c'} onClick={() => this.setState({currentTab: this.state.currentTab + 1})}>Další</button>)
        }
        if (this.state.currentTab === 5) {
            a.push(<button className={'btn btn-primary'} key={'d'} onClick={() => this.saveGame()}>{this.state.isCreatingNewGame ? 'Vytvořit hru' : 'Uložit změny'}</button>)
        }
        return <div>
            <hr/>
            <div className={'d-flex justify-content-end'}>
                {a}
            </div>
        </div>;
    }

    localizeFieldName(key) {
        const fullKey = 'originalErrors.gameFormFields.' + key;
        return firstUpper(this.props.t(fullKey), key)

    }

    validateObject(obj) {
        let error = false;
        const initialYear = this.state.game.initialYear;
        const gameYearCount = this.state.game.gameYearCount;

        Object.keys(obj).forEach(attr => {
            if (obj[attr] === null || obj[attr] === "") {
                this.props.showErrorMessage(this.props.t("originalErrors.Field Not Filled", {fieldName: this.localizeFieldName(attr)}))
                error = true;
            } else if (Array.isArray(obj[attr])) {
                if (obj[attr].length === 0) {
                    this.props.showErrorMessage(this.props.t("originalErrors.One Object Necessary", {fieldName: this.localizeFieldName(attr)}));
                    error = true;
                } else {
                    obj[attr].forEach(item => {
                        if (attr === "quarters" &&
                            (item.quarterNumber < initialYear * 4 || item.quarterNumber > gameYearCount * 4)
                        ) {
                            // no control for quarters before the last quarter of the last initial year or the last game quarter
                        } else if (this.validateObject(item) === false) {
                            error = true;
                        }
                    });
                }
            } else if (typeof obj[attr] === "object") {
                if(this.validateObject(obj[attr]) === false) {
                    error = true;
                }
            }
        });

        return error === false;
    }

    validateForm() {
        const optionalAttributes = ['currentQuarterNumber'];
        const game = this.state.game;

        // remove optional attributes to not validate them
        optionalAttributes.forEach(attr => {
            delete game[attr];
        });

        // validate all game attributes except optional ones
        if (this.validateObject(game)) {
            // validation ok
            this.props.resetErrorMessage();
            return true;
        } else {
            // problems detected, go to top of the page to see them
            window.scrollTo(0, 0);
            return false;
        }
    }

    saveGame() {
        if (this.validateForm()) {
            this.setState({isSaving: true});
            let game = Object.assign({}, this.state.game);

            if (game.id !== undefined) {
                this.props.updateTeacherGame(game.id, game, () => this.finishSaving());
            } else {
                this.props.createTeacherGame(game, () => this.finishSaving());
            }
        }
    }

    finishSaving() {
        this.setState({isSaving: false});

        if (this.props.error === undefined || this.props.error === null) { // stay on page in case of an error
            this.props.savedAllChanges();

            if (this.state.isCreatingNewGame) {
                // after creating a new game, redirect to list
                this.setState({redirectToGames: true});
            } else {
                // after updating a game, stay on page but load new data from server
                this.reloadGame();
            }
        }
    }

    renderTabs() {
        const tabs = ["1. Struktura hry", "2. Suroviny", "3. Výrobky", "4. Poptávka", "5. Strojový čas", "6. Ostatní"];
        return tabs.map((tab, index) => {
            return (
                <div key={index} className="col-md-4 col-lg-2 tab mb-3 ">
                    <div
                        id={'tab-' + index}
                        className={"px-3 py-3 pointer " + ((index === this.state.currentTab) ? "bg-primary text-white " : "bg-light")}
                        onClick={() => this.setState({currentTab: index})}
                    >
                        {tab}
                    </div>
                </div>
            )
        })
    }

    renderForm(id) {
        switch (id) {
            case 0: return this.renderFormStructureOfGame();
            case 1: return this.renderFormMaterials();
            case 2: return this.renderFormGoods();
            case 3: return this.renderFormDemand();
            case 4: return this.renderFormMachineTime();
            case 5: return this.renderFormOthers();
            default: return <div>Jsi blbec.</div>
        }
    }

    handleChange(key, value) {
        let copy = Object.assign({}, this.state.game);
        copy[key] = value;
        this.props.madeChangesWithoutSaving();
        this.setState({game: copy});
    }

    renderFormStructureOfGame() {
        const game = this.state.game;
        return (
            <div key={'structure-of-game'}>
                <h4>Název hry</h4>
                <div className={'row'}>
                    <div className={'col'}>
                        <TextField
                            defaultValue={game.name}
                            placeholder={"Název hry slouží pro vás jako identifikátor."}
                            onChange={(v) => this.handleChange('name', v)}
                            name={'name'}
                            maxLength={100}
                        />
                    </div>
                </div>

                <h4>Délka hry</h4>
                <div className={'row'}>
                    <div className={'col-md'}>
                        <NumberField
                            title={"Fiktivní rok založení firem"}
                            defaultValue={game.fictiveYearOfCompanyEstablishement}
                            onChange={(v) => this.handleChange('fictiveYearOfCompanyEstablishement', v)}
                            required={true}
                            minValue={0}
                            maxValue={9999}
                        />
                    </div>
                    <div className={'col-md'}>
                        <NumberField
                            minValue={1}
                            maxValue={100}
                            title={"Počet roků hry (vč. minulých)"}
                            defaultValue={game.gameYearCount}
                            onChange={(v) => this.handleYearChange('gameYearCount', v)}
                            disabled={this.state.isCreatingNewGame === false}
                        />
                    </div>
                    <div className={'col-md'}>
                        <NumberField
                            minValue={1}
                            maxValue={game.gameYearCount}
                            title={"Počet minulých roků"}
                            defaultValue={game.initialYear}
                            onChange={(v) => this.handleYearChange('initialYear', v)}
                            disabled={true}
                        />
                    </div>
                </div>

                <h4>Registrace</h4>
                <div className={'row'}>
                    <div className={'col-md'}>
                        <TextField
                            title={"Registrační fráze pro studenty"}
                            defaultValue={game.registrationPhrase}
                            placeholder={"Fráze pro přidání se do hry"}
                            onChange={(v) => this.handleChange('registrationPhrase', v)}
                            disabled={this.state.gameHasStarted}
                            name={'registration-phrase'}
                            maxLength={config.maxRegistrationPhraseLength}
                        />
                    </div>
                    <div className={'col-md'} id={'end-of-registrations'}>
                        <DateField
                            defaultValue={game.endOfRegistrations}
                            title={"Datum konce registrací firem studenty"}
                            placeholder={"Prosím, vyberte datum"}
                            returnEndOfDay={true}
                            onChange={(v) => this.handleChange('endOfRegistrations', v)}
                            disabled={this.state.gameHasStarted}
                        />
                    </div>
                    <div className={'col-md'}> </div>
                </div>

                <h4>Herní kola</h4>
                {this.renderSeasons()}

                <h4>Termíny cvičení</h4>
                {this.renderSeminars()}

                <div className={'alert alert-info small'}>
                    <h5>Legenda</h5>
                    <p>Nejprve je období registrací, kdy se studenti registrují do her, vytváří firmy a přidávají se do firem. Toto období začíná hned po vytvoření hry a končí datem "Datum konce registrací firem studenty".</p>
                    <p>Pak je další období, kdy studenti nedělají nic a učitelé vytváří trhy a přiřazují firmy do trhů.</p>
                    <p>Jakmile učitel ukončí editaci trhů, studenti vidí v administraci svoji firmu. Nachází se v posledním neherním kvartálu (při jednom neherním roku je to Q4 1. roku). Kromě tržních průzkůmů nemohou nic nastavovat.</p>
                    <p>Hra začíná koncem posledního kvartálu neherního roku (při jednom neherním roku je to pole "Konec Q4 1. roku"). Od této doby již studenti mohou dělat všechna rozhodnutí bez omezení.</p>
                    <p>Další herní kola začínají vždy dnem následujícím po konci předchozího kola a končí datem uvedeným v příslušném políčku, např. 3. kvartál 3. roku končí datem uvedeným v poli "Konec Q3 3. roku".</p>
                    <p>Všechna data jsou uvažována <i>včetně</i> daného dne. Tedy se uvažuje 23:59:59 vybraného dne.</p>
                </div>
            </div>
        )
    }

    renderSeminars() {
        const chips = this.state.game.seminars.map((seminar, index) => {
            return (
                <span key={index} className="badge badge-pill badge-primary badge-user mr-3 mb-2">
                    <span className="badge-text">{seminar.name}</span>
                    <span className="pointer close-in-badge" onClick={() => {
                        const index = this.state.game.seminars.indexOf(seminar);
                        this.props.madeChangesWithoutSaving();
                        this.setState({game: update(this.state.game, {seminars: {$splice: [[index, 1]]}})});
                    }}><i className="material-icons">close</i></span>
                </span>
            )
        });

        return <div>
            {chips}
            <div className={'mt-3 mb-4 row'}>
                <div className={'col-md-4'}>
                    <SelectTimeInWeek
                        onChange={value => this.setState({newSeminarName: value}) }
                    />
                </div>
                <div className={'col'}>
                    <button
                        className={'btn btn-primary'}
                        disabled={this.state.newSeminarName === false}
                        onClick={() => {
                            if (this.state.game.seminars.find(i => i.name === this.state.newSeminarName) === undefined) {
                                this.props.madeChangesWithoutSaving();
                                this.setState({game: update(this.state.game, {seminars: {$push: [{name: this.state.newSeminarName, nameEn: this.translateSeminarName(this.state.newSeminarName)}]}})})
                            }
                    }}>Přidat</button>
                </div>
            </div>
        </div>
    }

    translateSeminarName(originalName){
        let daysDictionary = {
            "Po" : "Mon",
            "Út" : "Tue",
            "St" : "Wed",
            "Čt" : "Thu",
            "Pá" : "Fri"
        }
        let lastPart = originalName.slice(2).replace("lichý", "odd").replace("sudý", "even").replace("týden", "week")
        return daysDictionary[originalName.slice(0, 2)] + lastPart
    }

    handleYearChange(k, v) {
        let game = Object.assign({}, this.state.game);
        game[k] = v;

        let quarters = game.quarters.slice();
        const newCount = game.gameYearCount * 4;

        for (let i = quarters.length; i < newCount; i ++) {
            quarters.push({quarterNumber: i + 1, end: null});
        }

        quarters = quarters.slice(0, newCount);

        game.quarters = quarters;

        this.props.madeChangesWithoutSaving();
        this.setState({game: game});
    }

    renderSeasons() {
        const game = this.state.game;
        let a = [];

        for(let i = 0; i < this.state.game.gameYearCount; i++) {
            const year = i + 1;
            const yearQuarter = i * 4 + 1;

            const fields = (new Array(4).fill(null)).map((v, j) => {
                const quarterNumber = yearQuarter + j;
                const quarter = this.state.game.quarters.find(quarter => (quarter.quarterNumber === quarterNumber));
                const end = quarter.end;

                return <div key={quarterNumber} className={'col-md'} id={'end-of-' + quarterNumber}>
                    <DateField
                        defaultValue={end}
                        onChange={(end) => this.handleQuarterEndChange(yearQuarter + j, end)}
                        title={"Konec Q" + (j+1) + " " + (i+1) + ". roku"}
                        returnEndOfDay={true}
                        placeholder={"Prosím, vyberte datum"}
                        disabled={
                            (year < game.initialYear) || // disable initial years
                            (year === game.initialYear && j < 3) || // except last quarter (4th)
                            (this.state.currentQuarterNumber > quarterNumber)
                        }
                    />
                </div>;
            });

            a.push(<div className={'row'} key={i}>{fields}</div>);
        }

        return a;
    }



    handleQuarterEndChange(quarterNumber, end) {
        let copy = Object.assign({}, this.state.game);
        let quarters = copy.quarters.slice();
        quarters.find(quarter => quarter.quarterNumber === quarterNumber).end = end;

        quarters.forEach(quarter => {
            if (quarter.quarterNumber > quarterNumber && quarter.end === null) {
                quarter.end = moment.unix(end).add(quarter.quarterNumber - quarterNumber, 'week').unix();
            }
        });

        copy.quarters = quarters;

        this.props.madeChangesWithoutSaving();
        this.setState({game: copy});
    }

    renderFormMaterials() {
        const game = this.state.game;
        return (
            <div key={'materials'}>
                <div className={'row'}>
                    <div className={'col-lg-8'}>
                        <div className={'row'}>
                            <div className={'col-md-7'}>
                                <h4>Surovniny používané pro výrobu</h4>
                            </div>
                            <div className={'col-md-5'}>
                                <button
                                    className={'btn btn-primary float-right'}
                                    onClick={() => this.addMaterial()}
                                    disabled={this.state.isCreatingNewGame === false}
                                >
                                    Přidat surovinu
                                </button>
                            </div>
                        </div>
                        {this.renderMaterialsTable()}
                        <div className="alert alert-warning small">
                            <strong>*</strong> Množství materiálu na skladě je údaj vztažený k začátku 1. čtvrtletí 1. roku hry (neherního).
                        </div>
                    </div>
                    <div className={'col-lg-4'}>
                        <h4>Sleva</h4>
                        <div>
                            <NumberField
                                title={'Min. množství pro získání slevy'}
                                defaultValue={game.materialDiscountThreshold}
                                minValue={0}
                                measure={'kg'}
                                onChange={(v) => this.handleChange('materialDiscountThreshold', v)}
                                disabled={this.state.game.marketsEnabled}
                            />
                        </div>
                        <div>
                            <NumberField
                                title={'Velikost slevy'}
                                defaultValue={game.materialDiscountRate * 100}
                                minValue={0}
                                maxValue={100}
                                onChange={(v) => this.handleChange('materialDiscountRate', v / 100)}
                                disabled={this.state.game.marketsEnabled}
                                measure={"%"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderMaterialsTable() {
        const game = this.state.game;

        if (game.materials.length === 0) {
            return <div>Žádná surovina.</div>
        }

        return (
            <table className={'table table-responsive mt-2'}>
                <thead>
                <tr>
                    <th>Název suroviny</th>
                    <th>Na skladě* (kg)</th>
                    <th>Cena (Kč/kg)</th>
                    <th>Volatilita ceny (%)</th>
                    <th> </th>
                </tr>
                </thead>
                <tbody>
                {game.materials.map((material, index) =>
                    <tr key={index}>
                        <td> 
                            <div className={'d-flex'}>                          
                            <span className={'game-form-center-flag'}><CZ/></span>
                            <TextField
                                defaultValue={material.name}
                                onChange={(v) => this.updateMaterialProperty(index, 'name', v)}
                                maxLength={20}
                                className={'form-item mb-1'}
                            />
                            </div> 
                            <div className="d-flex">
                            <span className={'game-form-center-flag'}><US/></span>
                            <TextField
                                defaultValue={material.nameEn}
                                onChange={(v) => this.updateMaterialProperty(index, 'nameEn', v)}
                                maxLength={20}
                                className={'form-item mb-0'}
                            />
                            </div>
                        </td>
                        <td>
                            <NumberField
                                short={true}
                                defaultValue={material.defaultStorage}
                                onChange={v => this.updateMaterialProperty(index, 'defaultStorage', v)}
                                disabled={this.state.game.marketsEnabled}
                                minValue={0}
                            />
                        </td>
                        <td>
                            <NumberField
                                short={true}
                                defaultValue={material.defaultPrice}
                                onChange={v => this.updateMaterialProperty(index, 'defaultPrice', v)}
                                disabled={this.state.game.marketsEnabled}
                                minValue={0}
                            />
                        </td>
                        <td>
                            <NumberField
                                short={true}
                                defaultValue={Math.round(material.priceVolatility * 100)}
                                onChange={v => this.updateMaterialProperty(index, 'priceVolatility', Math.round(v) / 100)}
                                disabled={this.state.game.marketsEnabled}
                                minValue={0}
                            />
                        </td>
                        <td>
                            {this.state.isCreatingNewGame === false ? null :
                                <span className="p-2 pointer" onClick={() => this.removeMaterial(index)}>
                                    <i className="material-icons">close</i>
                                </span>
                            }
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        );
    }

    addMaterial() {
        const id = this.state.lastMaterialId + 1;

        let goods = this.state.game.goods.slice();
        goods.forEach(good => {
            let demands = good.materialDemands.slice();
            demands.push({
                materialId: id,
                count: 0
            });
            good.materialDemands = demands;
        });

        this.props.madeChangesWithoutSaving();
        this.setState(update(this.state, {
            lastMaterialId: {$set: id},
            game: {
                materials: {$push: [{
                    "id": id,
                    "name": "",
                    "defaultPrice": 0,
                    "defaultStorage": 0,
                    "priceVolatility": 0.05
                }]},
                goods: {$set: goods}
            }
        }));
    }

    updateMaterialProperty(index, property, value) {
        this.props.madeChangesWithoutSaving();
        this.setState(update(this.state, {game: {materials: {[index]: { [property]: {$set: value}}}}}));
    }

    removeMaterial(index) {
        let goods = this.state.game.goods.slice();
        const id = this.state.game.materials[index].id;

        goods.forEach(good => {
            good.materialDemands = good.materialDemands.filter(demand => demand.materialId !== id);
        });

        this.props.madeChangesWithoutSaving();
        this.setState(update(this.state,
            {
                game: {
                    materials: {$splice: [[index, 1]]},
                    goods: {$set: goods}
                }
            }
        ));
    }

    renderFormGoods() {
        return (
            <div key={'goods'}>
                <div className={'row'}>
                    <div className={'col-lg-8'}>
                        <h4>Obchodované výrobky</h4>
                    </div>
                    <div className={'col-md-4'}>
                        <button
                            className={'btn btn-primary float-right'}
                            onClick={() => this.addGood()}
                            disabled={this.state.isCreatingNewGame === false}
                        >
                            Přidat výrobek
                        </button>
                    </div>
                </div>
                {this.renderGoodsTable()}
                <div className="alert alert-warning small">
                    <strong>*</strong> Množství produktu na skladě je údaj vztažený k začátku 1. čtvrtletí 1. roku hry (neherního).
                </div>
            </div>
        )
    }

    addGood() {
        const id = this.state.lastGoodId + 1;
        this.props.madeChangesWithoutSaving();
        this.setState(update(this.state, {
            lastGoodId: {$set: id},
            game: {
                goods: {$push: [{
                    id: id,
                    name: "",
                    minPrice: 0,
                    maxPrice: 0,
                    penalization: 0,
                    defaultStorage: 0,
                    storagePrice: 0,
                    materialDemands: this.state.game.materials.map(material => ({
                        materialId: material.id,
                        count: 0
                    })),
                    demand: {
                        "culminationQuarterNumber": 1,
                        "demandForMinPriceFirstQuarter": 15000,
                        "demandForMaxPriceFirstQuarter": 5000,
                        "quarterRiseCoefficient": -1000,
                        "yearRiseCoefficient": 2000
                    }
                }]},
                productionEffectivity: {$push: [{
                    goodId: id,
                    effectivity: generateEffectivity(1)
                }]}
            }}));
    }

    updateGoodProperty(index, property, value) {
        this.props.madeChangesWithoutSaving();
        this.setState(update(this.state, {game: {goods: {[index]: { [property]: {$set: value}}}}}));
    }

    removeGood(index) {
        const id = this.state.game.goods[index].id;
        const effectivityIndex = this.state.game.productionEffectivity.findIndex(item => item.goodId === id);

        this.props.madeChangesWithoutSaving();
        this.setState(update(this.state, {game: {
            goods: {$splice: [[index, 1]]},
            productionEffectivity: {$splice: [[effectivityIndex, 1]]}
        }}));
    }

    renderGoodsTable() {
        const game = this.state.game;

        if (game.goods.length === 0) {
            return <div>Žádný výrobek.</div>
        }

        const variableHeaders = game.materials.map((material) => <th key={material.id}>{material.name} spotřeba (kg)</th>);

        return (
            <table className={'table table-responsive table-centered-except-first-column table-striped-odd-columns mt-2'}>
                <thead>
                <tr>
                    <th>Název výrobku</th>
                    {variableHeaders}
                    <th>Na skladě* (ks)</th>
                    <th>Min. cena (Kč)</th>
                    <th>Max. cena (Kč)</th>
                    <th>Cena skladování (Kč)</th>
                    <th>Penalizace (Kč)</th>
                    <th className={'bg-white'}> </th>
                </tr>
                </thead>
                <tbody>
                {game.goods.map((good, index) => {

                        const variableColumns = game.materials.map(material => {
                            const demand = good.materialDemands.find(demand => demand.materialId === material.id);
                            const value = demand === undefined ? 0 : demand.count;
                            return (
                                <td key={material.id}>
                                    <NumberField
                                        short={true}
                                        defaultValue={value}
                                        onChange={v => this.updateGoodMaterialDemand(index, material.id, v)}
                                        disabled={this.state.game.marketsEnabled}
                                        minValue={0}
                                    />
                                </td>
                            )
                        });

                        return (
                        <tr key={index}>
                            <td>
                                <div className="d-flex">
                                    <span className="game-form-center-flag"><CZ/></span>
                                    <TextField
                                        defaultValue={good.name}
                                        onChange={(v) => this.updateGoodProperty(index, 'name', v)}
                                        maxLength={20}
                                        className={'form-item mb-1'}
                                    />
                                </div>
                                <div className="d-flex">
                                <span className="game-form-center-flag"><US/></span>
                                    <TextField
                                        defaultValue={good.nameEn}
                                        onChange={(v) => this.updateGoodProperty(index, 'nameEn', v)}
                                        maxLength={20}
                                        className={'form-item mb-0'}
                                    />
                                </div>
                            </td>
                            {variableColumns}
                            <td>
                                <NumberField
                                    short={true}
                                    defaultValue={good.defaultStorage}
                                    onChange={v => this.updateGoodProperty(index, 'defaultStorage', v)}
                                    disabled={this.state.game.marketsEnabled}
                                    minValue={0}
                                />
                            </td>
                            <td>
                                <NumberField
                                    short={true}
                                    defaultValue={good.minPrice}
                                    onChange={v => this.updateGoodProperty(index, 'minPrice', v)}
                                    disabled={this.state.game.marketsEnabled}
                                    minValue={0}
                                    maxValue={good.maxPrice}
                                />
                            </td>
                            <td>
                                <NumberField
                                    short={true}
                                    defaultValue={good.maxPrice}
                                    onChange={v => this.updateGoodProperty(index, 'maxPrice', v)}
                                    disabled={this.state.game.marketsEnabled}
                                    minValue={good.minPrice + 2}
                                />
                            </td>
                            <td>
                                <NumberField
                                    short={true}
                                    defaultValue={good.storagePrice}
                                    onChange={v => this.updateGoodProperty(index, 'storagePrice', v)}
                                    disabled={this.state.game.marketsEnabled}
                                    minValue={0}
                                />
                            </td>
                            <td>
                                <NumberField
                                    short={true}
                                    defaultValue={good.penalization}
                                    onChange={v => this.updateGoodProperty(index, 'penalization', v)}
                                    disabled={this.state.game.marketsEnabled}
                                    minValue={0}
                                />
                            </td>
                            <td className={'bg-white'}>
                                {this.state.isCreatingNewGame === false ? null :
                                    <span className="p-2 pointer"
                                      onClick={() => this.removeGood(index)}>
                                        <i className="material-icons">close</i>
                                    </span>
                                }
                            </td>
                        </tr>
                    )
                }
                )}
                </tbody>
            </table>
        );
    }

    updateGoodMaterialDemand(goodIndex, materialId, value) {
        const demands = this.state.game.goods[goodIndex].materialDemands;
        demands.find(demand => demand.materialId === materialId).count = value;
        this.props.madeChangesWithoutSaving();
        this.setState(update(this.state, {game: {goods: {[goodIndex]: {materialDemands: {$set: demands}}}}}));
    }

    /* DEMAND */
    renderFormDemand() {
        return (
            <div key={'demand'}>
                <h4>Generování poptávky po výrobcích</h4>
                {this.renderDemandTable()}
                <div className="alert alert-warning small">
                    <strong>*</strong> Změna přednastavených hodnot může mít závažné následky v průběhu hry. Poptávka může být příliš
                    vysoká/neuspokojitelná, nebo naopak příliš nízká. Doporučujeme nechat tyto hodnoty tak, jak jsou.
                    Bezpečná je pouze volba čtvrtletí, ve kterém má vrcholit poptávka.
                </div>
            </div>
        )
    }

    renderDemandTable() {
        const game = this.state.game;

        if (game.goods.length === 0) {
            return <div>Žádné produkty k zobrazení, nejprve přidejte výrobky.</div>
        }

        return (
            <table className={'table table-responsive table-centered-except-first-column table-striped-odd-columns'}>
                <thead>
                <tr>
                    <th>Název výrobku</th>
                    <th>Poptávka při min. ceně*</th>
                    <th>Poptávka při max. ceně*</th>
                    <th>Přírustek za čtvrtletí*</th>
                    <th>Vrchol poptávky ve čtvrtletí</th>
                    <th>Roční přírustek*</th>
                </tr>
                </thead>
                <tbody>
                {game.goods.map((good, goodIndex) => {
                    const demand = good.demand;
                    return (
                        <tr key={good.id} className={good.id}>
                            <td>{good.name}</td>
                            <td>
                                <NumberField
                                    defaultValue={demand.demandForMinPriceFirstQuarter}
                                    onChange={val => this.updateDemand(goodIndex, 'demandForMinPriceFirstQuarter', val)}
                                    disabled={this.state.game.marketsEnabled}
                                    minValue={0}
                                />
                            </td>
                            <td>
                                <NumberField
                                    defaultValue={demand.demandForMaxPriceFirstQuarter}
                                    onChange={val => this.updateDemand(goodIndex, 'demandForMaxPriceFirstQuarter', val)}
                                    disabled={this.state.game.marketsEnabled}
                                    minValue={0}
                                />
                            </td>
                            <td>
                                <NumberField
                                    defaultValue={demand.quarterRiseCoefficient}
                                    onChange={val => this.updateDemand(goodIndex, 'quarterRiseCoefficient', val)}
                                    disabled={this.state.game.marketsEnabled}
                                />
                            </td>
                            <td>
                                <SelectField
                                    defaultValue={demand.culminationQuarterNumber}
                                    options={[
                                        {title: "1", value: 1},
                                        {title: "2", value: 2},
                                        {title: "3", value: 3},
                                        {title: "4", value: 4}
                                    ]}
                                    onChange={val => this.updateDemand(goodIndex, 'culminationQuarterNumber', val)}
                                    disabled={this.state.game.marketsEnabled}
                                />
                            </td>
                            <td>
                                <NumberField
                                    defaultValue={demand.yearRiseCoefficient}
                                    onChange={val => this.updateDemand(goodIndex, 'yearRiseCoefficient', val)}
                                    disabled={this.state.game.marketsEnabled}
                                />
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }

    updateDemand(goodIndex, attr, value) {
        this.props.madeChangesWithoutSaving();
        this.setState(update(this.state, {game: {
            goods: {[goodIndex]: {demand: { [attr]: { $set: value}}}}}}));
    }


    /* MACHINE TIME */

    renderFormMachineTime() {
        return (
            <div key={'machine-time'}>
                <h4>Strojový čas nutný na výrobu</h4>
                {this.renderMachinesTimeTable()}
            </div>
        )
    }

    renderMachinesTimeTable() {
        const game = this.state.game;

        if (game.goods.length === 0) {
            return <div>Žádné produkty k zobrazení, nejprve přidejte výrobky.</div>
        }

        const times = [0, 250, 500, 750, 1000, 1250, 1500];

        return (
            <table className={'table table-responsive table-centered-except-first-column table-striped-columns'}>
                <thead>
                <tr>
                    <th rowSpan={2}>Délka výroby</th>
                    {times.map(time => <th key={time} colSpan={2}>{time} hodin</th>)}
                    <th className={'bg-white'}> </th>
                </tr>
                <tr>
                    {times.map(time => [
                        <th key={time + 'count'}>Kusů</th>,
                        <th key={time + 'people'}>Lidí</th>
                    ])}
                    <th className={'bg-white'}> </th>
                </tr>
                </thead>
                <tbody>
                {game.productionEffectivity.map((goodEffectivity, index) => {
                    const good = game.goods.find(good => good.id === goodEffectivity.goodId);
                    return (
                        <tr key={good.id} className={good.id}>
                            <td>{good.name}</td>
                            {goodEffectivity.effectivity.map((effectivity, index2) => ([
                                <td key={good.id + '-goods'}>
                                    <NumberField
                                        short={true}
                                        defaultValue={effectivity.goods}
                                        onChange={v => this.updateProductionEffectivity(index, index2, 'goods', v)}
                                        disabled={this.state.game.marketsEnabled}
                                        minValue={0}
                                    />
                                </td>,
                                <td key={good.id + '-people'}>
                                    <NumberField
                                        short={true}
                                        defaultValue={effectivity.people}
                                        onChange={v => this.updateProductionEffectivity(index, index2, 'people', v)}
                                        disabled={this.state.game.marketsEnabled}
                                        min={0}
                                    />
                                </td>
                            ]))}
                            <td className={'bg-white'}>
                                {this.state.game.marketsEnabled ? null :
                                    <div className="dropdown">
                                    <span className={'pointer mr-2'} onClick={() => {
                                        let copy = this.state.machineTimeMenus.slice();
                                        copy[good.id] = !copy[good.id];
                                        this.props.madeChangesWithoutSaving();
                                        this.setState({machineTimeMenus: copy});
                                    }}><i className="material-icons">more_vert</i></span>
                                        <div
                                            className={"dropdown-menu dropdown-menu-right " + (this.state.machineTimeMenus[good.id] ? 'd-block' : 'd-none')}
                                            aria-labelledby="dropdownMenuButton">
                                            <span className="dropdown-item pointer"
                                                  onClick={() => this.generateMachineTimeEffectivity(good.id, EffectivityLevel.high)}>Generovat vysokou náročnost</span>
                                            <span className="dropdown-item pointer"
                                                  onClick={() => this.generateMachineTimeEffectivity(good.id, EffectivityLevel.low)}>Generovat nízkou náročnost</span>
                                        </div>
                                    </div>
                                }
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }

    updateProductionEffectivity(goodIndex, timeIndex, attr, value) {
        this.props.madeChangesWithoutSaving();
        this.setState(update(this.state, {game: {
            productionEffectivity: {[goodIndex]: {effectivity: { [timeIndex]: { [attr]: {$set: value}}}}}}}));
    }

    generateMachineTimeEffectivity(goodId, hardness) {
        const index = this.state.game.productionEffectivity.findIndex(item => item.goodId === goodId);
        this.props.madeChangesWithoutSaving();
        this.setState(update(this.state, {
                machineTimeMenus: {
                    $toggle: [goodId]
                },
                game: {
                    productionEffectivity: {
                        [index]: {
                            effectivity: {
                                $set: generateEffectivity(hardness)
                            }
                        }
                    }
                }
            }
        ));
    }

    renderFormOthers() {
        const game = this.state.game;

        return (
            <div key={'others'}>

                <h4>Omezení</h4>
                <div className={'row'}>
                    <div className={'col-lg-3'}>
                        <NumberField
                            minValue={1}
                            title={"Max. počet zaměstnanců v jedné firmě"}
                            defaultValue={game.maxEmployees}
                            onChange={(v) => this.handleChange('maxEmployees', v)}/>
                    </div>
                    <div className={'col-lg-3'}>
                        <NumberField
                            minValue={1}
                            title={"Max. počet hráčů v jedné firmě"}
                            defaultValue={game.maxPlayersInCompany}
                            onChange={(v) => this.handleChange('maxPlayersInCompany', v)}/>
                    </div>
                    <div className={'col-lg-3'}>
                        <NumberField
                            title={'Max. počet strojů'}
                            defaultValue={game.maxMachineCount}
                            minValue={0}
                            required={true}
                            onChange={val => this.handleChange('maxMachineCount', val)}
                        />
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-lg-3'}>
                        <SelectField
                            title={'Automatický úvěr'}
                            defaultValue={game.automaticLoan}
                            options={[
                                {title: "ano",value: true},
                                {title: "ne",value: false},
                            ]}
                            onChange={val => this.handleChange('automaticLoan', val)}
                            disabled={this.state.game.marketsEnabled}
                        />
                    </div>
                    <div className={'col-lg-3'}>
                        <NumberField
                            minValue={0}
                            title={"Rezerva pro schválení úvěru***"}
                            defaultValue={game.hoursForLoanRequirement}
                            measure={'hod.'}
                            onChange={(v) => this.handleChange('hoursForLoanRequirement', v)}/>
                    </div>
                </div>

                <h4>Ceny průzkumů</h4>
                <div className={'row'}>
                    <div className={'col-lg-3'}>
                        <NumberField
                            title={'Tržní potenciál jednoho produktu za jedno čtvrtletí (Kč)'}
                            defaultValue={game.surveyPrices.marketPotential}
                            minValue={0}
                            required={true}
                            measure={'Kč'}
                            onChange={val => this.handleSurveyPriceChange('marketPotential', val)}
                            disabled={this.state.game.marketsEnabled}
                        />
                    </div>
                    <div className={'col-lg-3'}>
                        <NumberField
                            title={'Ceny konkurence za jedno čtvrtletí (Kč)'}
                            defaultValue={game.surveyPrices.concurrencyPrices}
                            minValue={0}
                            required={true}
                            measure={'Kč'}
                            onChange={val => this.handleSurveyPriceChange('concurrencyPrices', val)}
                            disabled={this.state.game.marketsEnabled}
                        />
                    </div>
                    <div className={'col-lg-3'}>
                        <NumberField
                            title={'Prodané ks výrobků konkurence za jedno čtvrtletí (Kč)'}
                            defaultValue={game.surveyPrices.concurrencySoldGoods}
                            minValue={0}
                            required={true}
                            measure={'Kč'}
                            onChange={val => this.handleSurveyPriceChange('concurrencySoldGoods', val)}
                            disabled={this.state.game.marketsEnabled}
                        />
                    </div>
                    <div className={'col-lg-3'}>
                        <NumberField
                            title={'Ceny surovin za jedno čtvrtletí (Kč)'}
                            defaultValue={game.surveyPrices.materialPrices}
                            minValue={0}
                            required={true}
                            measure={'Kč'}
                            onChange={val => this.handleSurveyPriceChange('materialPrices', val)}
                            disabled={this.state.game.marketsEnabled}
                        />
                    </div>
                    <div className={'col-lg-3'}>
                        <NumberField
                            title={'Množstevní sleva na průzkumy**'}
                            defaultValue={game.surveyPrices.discountRate * 100}
                            minValue={0}
                            maxValue={100}
                            required={true}
                            measure={'%'}
                            onChange={val => this.handleSurveyPriceChange('discountRate', val / 100)}
                            disabled={this.state.game.marketsEnabled}
                        />
                    </div>
                </div>

                <h4>Výchozí hodnoty na začátku hry*</h4>
                <div className={'row'}>
                    <div className={'col-lg-3'}>
                        <NumberField
                            title={'Provozní hotovost*'}
                            defaultValue={game.defaultCash}
                            minValue={0}
                            measure={'Kč'}
                            required={true}
                            onChange={val => this.handleChange('defaultCash', val)}
                            disabled={this.state.game.marketsEnabled}
                        />
                    </div>
                    <div className={'col-lg-3'}>
                        <NumberField
                            title={'Počet strojů*'}
                            defaultValue={game.defaultMachineCount}
                            minValue={0}
                            required={true}
                            onChange={val => this.handleChange('defaultMachineCount', val)}
                            disabled={this.state.gameHasStarted} // machines cannot be changed after initialization during switch to first game quarter
                        />
                    </div>
                    <div className={'col-lg-3'}>
                        <NumberField
                            title={'Cena akcie* (Kč)'}
                            defaultValue={game.defaultStockPrice}
                            minValue={0}
                            required={true}
                            measure={'Kč'}
                            onChange={val => this.handleChange('defaultStockPrice', val)}
                            disabled={this.state.game.marketsEnabled}
                        />
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-lg-3'}>
                        <NumberField
                            title={'Počet zaměstnanců*'}
                            defaultValue={game.defaultEmployeeCount}
                            minValue={0}
                            required={true}
                            onChange={val => this.handleChange('defaultEmployeeCount', val)}
                            disabled={this.state.game.marketsEnabled}
                        />
                    </div>
                    <div className={'col-lg-3'}>
                        <NumberField
                            title={'Úroveň mezd*'}
                            defaultValue={game.defaultSalary}
                            minValue={0}
                            required={true}
                            measure={'Kč'}
                            onChange={val => this.handleChange('defaultSalary', val)}
                            disabled={this.state.game.marketsEnabled}
                        />
                    </div>
                    <div className={'col-lg-3'}>
                        <SelectField
                            title={'Vzdělávání*'}
                            defaultValue={game.defaultEducation}
                            options={[
                                {title: "žádné",value: false},
                                {title: "ano",value: true},
                            ]}
                            onChange={val => this.handleChange('defaultEducation', val)}
                            disabled={this.state.game.marketsEnabled}
                        />
                    </div>
                </div>

                <h4>Hodnocení</h4>
                <div className={'row'}>
                    <div className={'col-lg-3'}>
                        <NumberField
                            minValue={1}
                            title={"Etalon pro hodnocení ziskovosti v rámci ceny akcie"}
                            defaultValue={game.profitabilityStandard}
                            onChange={(v) => this.handleChange('profitabilityStandard', v)}
                            measure={'Kč'}
                            disabled={this.state.game.marketsEnabled}
                        />
                    </div>
                </div>

                <div className="alert alert-warning small">
                    <strong>*</strong> Výchozími hodnotami jsou hodnoty na začátku 1. čtvrtletí 1. roku hry (neherního).
                </div>

                <div className="alert alert-info small">
                    <strong>**</strong> Množstevní sleva na průzkumy se aplikuje tak, že průzkum pro první kvartál je za plnou cenu, druhý s danou slevou,
                    třetí s dvojnásobkem dané slevy atd. Např. 25000+(25000*0.9)+(25000*0.8).
                    Tato sleva je aplikována na průzkumy tržního potenciálu a průzkumy cen surovin. Sleva je aplikována celkově za všechny produkty. Sleva je
                        aplikována zvlášt pro průzkumy různého typu.
                </div>

                <div className="alert alert-info small">
                    <strong>***</strong> Pokud je položka <i>Automatický úvěr</i> nastavena na "ne" nebo pokud měla firma v předchozím kole překlenovací
                    úvěr, pak je nutné schválit žádost o investiční úvěr učitelem. V takovém případě slouží <i>Rezerva pro schválení úvěru</i> jako
                    ochranná doba pro učitele, aby stihl žádost přezkoumat a vydat rozhodnutí. Jedná se o počet hodin před koncem kola, kdy již student nemůže žádost podávat.
                    Pokud se učitel nevyjádří, je žádost automaticky schválena na konci čtvrtletí (i pokud je vypnutá možnost "Automatický úvěr").
                    <br/><br/>
                    V ostatních případech může student podávat žádost až do poslední chvíle a žádost je schválena automaticky na konci kola.
                </div>

            </div>
        )
    }

    handleSurveyPriceChange(key, val) {
        this.props.madeChangesWithoutSaving();
        this.setState(update(this.state, {
            game: {
                surveyPrices: {
                    [key]: {$set: val}
                }
            }
        }));
    }

}

const mapStateToProps = (state, ownProps) => {
    const gameError = state.errors.find(error => error.id === 'POST-' + Schemas.TEACHER_GAME.key || error.id === 'PUT-' + Schemas.TEACHER_GAME.key);

    return {
        games: state.entities.teacherGames,
        gameDefaults: state.entities.teacherGameDefaults['first'],
        error: gameError,
        noUnsavedChanges: state.hasUnsavedChanges === false
    }
};
const GameTranslated = withTranslation('translation')(Game)
export default withRouter(connect(mapStateToProps, {
    createTeacherGame,
    updateTeacherGame,
    showErrorMessage,
    resetErrorMessage,
    madeChangesWithoutSaving,
    savedAllChanges,
    loadTeacherGameDefaults
})(GameTranslated))

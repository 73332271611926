/* eslint-disable no-undef */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Link, Redirect, withRouter} from 'react-router-dom'
import {
    loadLoanRequirements,
    updateLoanRequirement
} from '../../../../actions/sync-modules'
import Loader from '../../../../components/Loader'
import {currentQuarter, quarterNameFor} from "../../../../services/quarter";
import {dateFromUnixTimestamp, formatDateTime, formatNumber, sequenceMap, toArray} from "../../../../services/utils";
import RemainingTimeProgressBar from "../../../../components/RemainingTimeProgressBar";
import config from "../../../../config";
import moment from "moment";

class LoanRequirements extends Component {

    constructor(props) {
        super(props);

        const gameId = this.props.match.params.gameId;
        const game = props.games[gameId];

        this.state = {
            game: game,
            currentQuarter: currentQuarter(game)
        };

        this.props.loadLoanRequirements(gameId);
    }

    static getDerivedStateFromProps(props, state) {
        let arr = toArray(props.loanRequirements);
        const requirementsGroupedByQuarter = {};

        arr.forEach(i => {
            const quarter = state.game.quarters.find(q => q.id === i.quarterId);
            if (requirementsGroupedByQuarter[quarter.quarterNumber] === undefined) {
                requirementsGroupedByQuarter[quarter.quarterNumber] = [];
            }
            requirementsGroupedByQuarter[quarter.quarterNumber].push(i);
        });

        return {
            isInitialized: true,
            loanRequirements: requirementsGroupedByQuarter
        }
    }

    render() {
        if (this.state.game === undefined) {
            return <Redirect to={'/auth/teacher/games'} />
        }

        if (this.props.didDownloadedData !== true) {
            return <Loader />
        } else {
            const lastQuarter = currentQuarter(this.state.game).quarterNumber;
            const firstQuarter = this.state.game.initialYear * 4 + 1;

            if (lastQuarter < firstQuarter) {
                return <div className="box">
                    <div className="content pt-3">Žádné žádosti. Hra ještě nezačala.</div>
                </div>;
            }

            return (
                <div className={'row'}>
                    {sequenceMap(lastQuarter, firstQuarter, quarterNumber => {
                        return this.renderQuarter(quarterNumber);
                    })}
                </div>
            )
        }
    }

    renderQuarter(quarterNumber) {
        const quarterRequirements = this.state.loanRequirements[quarterNumber];

        return (
            <div key={quarterNumber} className={'col-xl-9'}>
                <div className={'box'}>
                    <h3>Žádosti o úvěry pro {quarterNameFor(this.state.game, quarterNumber)}</h3>
                    <div className={'content'}>
                        {this.renderRemainingHours(quarterNumber)}
                        {this.renderLoansForQuarter(quarterNumber, quarterRequirements)}
                    </div>
                </div>
            </div>
        )
    }

    renderLoansForQuarter(quarterNumber, quarterRequirements) {
        if (quarterRequirements === undefined || quarterRequirements.length === 0) {
            return "Žádné žádosti v tomto čtvrtletí."
        }

        const sortedRequirements = quarterRequirements
            .sort((a, b) => a.acceptedByTeacher !== null && b.acceptedByTeacher === null ? -1 : 1);

        return <div>
            {sortedRequirements.map(requirement => {
                const timestamp = requirement.lastChange === null ? "??" : formatDateTime(dateFromUnixTimestamp(requirement.lastChange.datetime));

                return (
                    <div key={requirement.id}>
                        <hr/>
                        <div className={'row'}>
                            <div className={'col-md'}>
                                <h5>
                                    {requirement.companyName}
                                    <Link to={'/auth/teacher/administration/' + this.state.game.id + '/summaries/' + requirement.companyId} className={'ml-3 small'}>Přehled firmy</Link>
                                </h5>
                                Podáno: {timestamp}<br/>
                                <span className={'mr-3'}>Částka: {formatNumber(requirement.amount)} Kč</span><br/>
                                {this.renderDocument(requirement)}
                            </div>
                            <div className={'col-md'}>
                                {this.renderButtons(quarterNumber, requirement)}
                            </div>
                        </div>
                    </div>
                );
            })}
            </div>
    }

    renderDocument(requirement) {
        if (requirement.document === null) {
            return 'Žádná příloha.';
        } else {
            return <a
                    href={config.serverUrl + 'investment-loan-documents/' + requirement.id + '/' + requirement.document}
                    target={'_blank'}
                    className={'btn btn-light btn-sm text-primary mt-1 mb-4'}
                    rel="noopener noreferrer"
                >Stáhnout přílohu</a>
        }
    }

    renderRemainingHours(quarterNumber) {
        if (quarterNumber !== this.state.currentQuarter.quarterNumber) {
            return null;
        } else {
            return (
                <div className={'row mb-4'}>
                    <div className={'col-lg-6'}>
                        <RemainingTimeProgressBar
                            game={this.state.game}
                            title={'Automatické schválení za:'}
                        />
                    </div>
                </div>
            )
        }
    }

    renderButtons(quarterNumber, requirement) {
        let result = [];

        if (requirement.accepted === true && requirement.acceptedByTeacher === null) {
            result.push(<div key={'accepted'} className={'text-success'}>Uděleno automaticky.</div>)

        } else if (requirement.acceptedByTeacher === true) {
            result.push(<div key={'accepted'} className={'text-success'}>Uděleno ({this.renderLastChange(requirement)})</div>)

        } else if (requirement.acceptedByTeacher === false) {
            result.push(<div key={'rejected'} className={'text-danger'}>Zamítnuto ({this.renderLastChange(requirement)})</div>)

        } else if (quarterNumber === this.state.currentQuarter.quarterNumber) {
            result.push(<button
                className={'btn btn-primary btn-sm mr-3 mb-2'}
                key={'accept'}
                onClick={() => this.handleChange(requirement, true)}>
                Udělit</button>);
            result.push(<button
                className={'btn btn-danger btn-sm mb-2'}
                key={'reject'}
                onClick={() => this.handleChange(requirement, false)}>
                Zamítnout
            </button>);

        } else {
            result.push(<div key={'unknown'} className={'text-danger'}>Vypršelo období pro schválení žádosti. Bude automaticky schválena.</div>)
        }

        return result;
    }

    renderLastChange(requirement) {
        if (requirement.lastChange != null && requirement.accepted !== null) {
            return <span>{requirement.lastChange.user} {formatDateTime(new Date(requirement.lastChange.datetime * 1000))}</span>
        } else {
            return null;
        }
    }

    handleChange(requirement, acceptedByTeacher) {
        const changeName = acceptedByTeacher ? "PŘIJMOUT" : "ODMÍTNOUT";
        const confirmed = window.confirm("Opravdu " + changeName + " žádost firmy " + requirement.companyName + " o úvěr ve výši " + requirement.amount + "? Rozhodnutí není možné později změnit.")

        if (confirmed) {
            const data = {
                'acceptedByTeacher': acceptedByTeacher,
                'lastChange': {
                    'datetime': moment().unix(),
                    'user': this.props.user
                }
            };
            this.props.updateLoanRequirement(this.state.game.id, requirement.id, data);
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const user = state.entities.user['first'];

    return {
        user: user !== undefined ? user.name : "",
        games: state.entities.teacherGames,
        loanRequirements: state.entities.loanRequirements,
        didDownloadedData: state.downloadedEntities.loanRequirements
    }
};

const mapDispatchToProps = {
    loadLoanRequirements,
    updateLoanRequirement
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoanRequirements))

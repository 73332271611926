import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from "react-i18next";

class FileUpload extends React.Component {

    static propTypes = {
        title: PropTypes.string,
        buttonTitle: PropTypes.string,
        description: PropTypes.string,
        onChange: PropTypes.func,
        accept: PropTypes.string,
        disabled: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            selectedFileName: ""
        }
    }

    wrapDescription(description) {
        if (description === undefined) {
            return "";
        } else {
            return description.split('\n').map((item, key) => {
                return <span key={key}>{item}<br/></span>
            })
        }
    }

    render() {
        const { title, description } = this.props;

        return (
            <div className="form-item mb-4">
                <div className="form-label mb-1">{title}</div>
                    <label htmlFor="file-upload" className="file-upload-btn btn btn-light mr-3">
                        {this.props.buttonTitle || this.props.t("fileUpload.selectFile")}
                    </label>
                    <input
                        id="file-upload"
                        type="file"
                        className="d-none"
                        onChange={event => this.handleChange(event.target.files[0])}
                        accept={this.props.accept}
                        disabled={this.props.disabled}
                    />
                    {this.state.selectedFileName}
                <div className="description">{this.wrapDescription(description)}</div>
            </div>
        );
    }

    handleChange(file) {
        if (file === undefined) {
            this.setState({selectedFileName: ""});
            this.props.onChange(null);
        } else {
            this.setState({selectedFileName: file.name});
            this.props.onChange(file);
        }
    }
}

export default withTranslation('translation')(FileUpload)
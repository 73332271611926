/* eslint-disable no-undef */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Link, Redirect, withRouter} from 'react-router-dom'
import { withTranslation } from "react-i18next";
import config from "../../../config";


class Homepage extends Component {

    static propTypes = {

    };

    render() {
        const { t } = this.props;

        if (this.props.isAuthenticated) {
            return <Redirect to={'/login'}/>
        }

        return (
            <div className="">
                <div className="p-5">
                    <h1>{t("homepage.basicInfo")}</h1>
                    <Link to={'/login'}>{t("homepage.enterTheGame")} &raquo;</Link>
                    <div className="mt-5 text-secondary small">
                        {t('homepage.version')}: { config.version }
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        isAuthenticated: state.isAuthenticated
    };
};

const HomepageTranslated = withTranslation('translation')(Homepage)
export default withRouter(connect(mapStateToProps, {

})(HomepageTranslated))

import {LOGIN_RESTORED} from "../../actions/sync-modules";

export const getAuthToken = () => {
    const token = localStorage.getItem('authToken');
    return (token === null) ? null : token
};

export const persistAuthToken = (response) => {
    const user = response && response.entities.user['first'];
    if (user !== undefined) {
        localStorage.setItem('authToken', user.apiToken);
        localStorage.setItem('authUserName', user.name);
    }
};

export const restoreAuthToken = (store) => {
    const token = localStorage.getItem('authToken');

    if (token !== null) {
        store.dispatch({
            type: LOGIN_RESTORED
        });
    }
};

export const clearAuthToken = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('authUserName');
};

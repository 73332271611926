import React from 'react'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from "react-redux";
import UserButton from './UserButton'
import LoadingIndicator from '../components/SavingIndicator'
import LanguageOptions from './LanguageOptions';
import { withTranslation } from "react-i18next";

class Navbar extends React.Component {

    static propTypes = {
        toggleMobileMenu: PropTypes.func.isRequired
    };

    render() {
        return (
            <nav className="navbar fixed-top navbar-light">
                {this.renderMobileMenuToggler()}
                <a className="navbar-brand" href="/">
                    <img className="d-none d-md-inline-block align-middle logo ml-2" src={process.env.PUBLIC_URL + "/img/logo.svg"} alt="Logo" />
                    <div className="d-none d-md-block d-lg-none mr-2">{this.props.t("navbar.name")}</div>
                    <div className="d-none d-lg-block mr-2">{this.props.t("navbar.nameExtended")}</div>
                </a>
                <div className="mr-auto">
                    <LoadingIndicator isLoading={this.props.isSyncing || this.props.isWaitingForSync} lastSave={this.props.lastSave} t={this.props.t}/>
                </div>
                {!this.props.isTeacher ? <LanguageOptions/> : null}
                <UserButton/>
            </nav>
        )
    }

    renderMobileMenuToggler() {
        if (this.props.location.pathname.startsWith('/auth/student/company/') || this.props.location.pathname.startsWith('/auth/teacher/administration/')) {
            return <button className="navbar-toggler d-md-none" type="button"
                           onClick={this.props.toggleMobileMenu}>
                <span className="navbar-toggler-icon"> </span>
            </button>
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const user = state.entities.user['first'];

    return {
        isSyncing: state.isSyncing,
        isWaitingForSync: state.isWaitingForSync,
        lastSave: state.lastSave,
        isTeacher: user !== undefined && user.role === "teacher"
    }
};

const NavbarTranslated = withTranslation('translation')(Navbar)
export default withRouter(connect(mapStateToProps)(NavbarTranslated))

import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import Root from './routes/Root'
import configureStore from './store/configureStore'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { restoreAuthToken } from "./middleware/sync/authorization";


const store = configureStore();

render(
  <Router basename={process.env.PUBLIC_URL}>
    <Root store={store} />
  </Router>,
  document.getElementById('root')
);

restoreAuthToken(store);

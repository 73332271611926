import React from 'react'
import PropTypes from 'prop-types'
import config from "../config";
import { withTranslation } from "react-i18next";

class NumberField extends React.Component {

    static propTypes = {
        title: PropTypes.string,
        defaultValue: PropTypes.number,
        minValue: PropTypes.number,
        maxValue: PropTypes.number,
        description: PropTypes.string,
        onChange: PropTypes.func,
        measure: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        short: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        console.log('config', config);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            value: props.defaultValue || 0,
            rangeError: false
        };
    }

    wrapDescription(description) {
        if (description === undefined) {
            return "";
        } else {
            return description.split('\n').map((item, key) => {
                return <span key={key}>{item}<br/></span>
            })
        }
    }

    render() {
        const { title, minValue, maxValue, description } = this.props;
        const min = minValue === undefined ? "-1 mld." : minValue;
        const max = maxValue === undefined ? "1 mld." : maxValue;
        const className =
            (this.props.measure !== undefined ? " input-with-measure " : "") +
            (this.props.short ? " number-input " : "");

        return (
            <div className="form-item mb-4">
                <div className="form-label mb-1">{title}</div>
                <input
                    type="number"
                    pattern="[0-9]*"
                    className={className}
                    value={this.state.value}
                    onChange={event => this.handleChange(event.target.value)}
                    required={this.props.required}
                    disabled={this.props.disabled}
                />
                <span className={'ml-1'}>{this.props.measure}</span>
                {
                    (this.state.rangeError) ?
                    <div className="form-error text-danger">{this.props.t("numberField.outOfRange", {min: min, max: max})}</div>
                    : null
                }
                <div className="description">{this.wrapDescription(description)}</div>
            </div>
        );
    }

    handleChange(newValue) {
        let intValue = parseInt(newValue, 10);
        if (isNaN(intValue)) intValue = "";
        this.setState({value: intValue});
        const minValue = this.props.minValue === undefined ? config.minNumber : this.props.minValue;
        const maxValue = this.props.maxValue === undefined ? config.maxNumber : this.props.maxValue;

        if (
            intValue !== "" &&
            intValue >= minValue &&
            intValue <= maxValue
        )
        {
            this.setState({rangeError: false});
            this.props.onChange(intValue);
        } else {
            this.setState({rangeError: true});
        }
    }
}

export default withTranslation('translation')(NumberField)

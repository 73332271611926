/* eslint-disable no-undef */

import React, { Component } from 'react'
import MarketSurvey from "./MarketSurvey";
import MarketSurveyRequirements from "./MarketSurveyRequirements";
import ConcurrencySurvey from "./ConcurrencySurvey";
import MaterialSurvey from "./MaterialSurvey";
import MaterialSurveyRequirement from "./MaterialSurveyRequirement";
import ConcurrencySurveyRequirement from "./ConcurrencySurveyRequirement";
import { withTranslation } from "react-i18next";

class Surveys extends Component {
    render() {
        const { t } = this.props;
        return <div>
            <h2 className={'title-out-of-box'}>{t("surveys.sells")}</h2>
            <MarketSurveyRequirements/>
            <MarketSurvey/>

            <h2 className={'mt-4 title-out-of-box'}>{t("surveys.material")}</h2>
            <MaterialSurveyRequirement/>
            <MaterialSurvey/>

            <h2 className={'mt-4 title-out-of-box'}>{t("surveys.competitor")}</h2>
            <ConcurrencySurveyRequirement/>
            <ConcurrencySurvey/>
        </div>
    }
}

export default withTranslation('translation')(Surveys);

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateMaterialPurchaseRequirement } from '../../../../actions/sync-modules'
import LastChange from '../../../../components/LastChange'
import PropTypes from 'prop-types'
import NumberField from "../../../../components/NumberField";
import {formatNumber} from "../../../../services/utils";
import { withTranslation } from "react-i18next";

class MaterialPurchaseRequirement extends Component {

    static propTypes = {
        data: PropTypes.object.isRequired,
        prices: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            isInitialized: false,
            value: props.data.amount
        }
    }

    render() {
        const { t } = this.props;
        const material = this.props.materials.find(material => material.id === this.props.data.materialId);
        return (
            <div className={'box box-important'}>
                <h3>{t("materialPurchaseRequirement.purchase", {materialName: localStorage.getItem("language") == "cs"? material.name : material.nameEn})}</h3>
                <div className={'content'}>
                    <NumberField
                        title={t("materialPurchaseRequirement.quantity")}
                        defaultValue={this.state.value}
                        minValue={0}
                        description={this.getDescription(material)}
                        onChange={this.handleChange}
                    />
                </div>
                <LastChange object={this.props.data} t={t} />
            </div>
        );
    }

    handleChange(newValue) {
        newValue = parseInt(newValue, 10);
        this.setState({value: newValue}, () => {
            this.props.updateMaterialPurchaseRequirement(this.props.data.materialId, {
                amount: newValue
            });
        });
    }

    getDescription(material) {
        const { t } = this.props;
        const storageAmount = this.props.materialStorage.find(item => item.materialId === this.props.data.materialId).count;
        const currentQuarterPrice = this.props.prices !== undefined && this.props.prices["currentPrice"];
        const discountThreshold = this.props.game.materialDiscountThreshold;
        const discountRate = this.props.game.materialDiscountRate;

        const discount = this.state.value >= discountThreshold ? discountRate : 0;
        const totalPrice = currentQuarterPrice * this.state.value;

        return (
            t("materialPurchaseRequirement.storageState", {storageAmount: formatNumber(storageAmount)}) +
            t("materialPurchaseRequirement.priceForKg", {currentQuarterPrice: formatNumber(currentQuarterPrice)}) +
            t("materialPurchaseRequirement.discount", {total: formatNumber((discount * totalPrice))}) +
            t("materialPurchaseRequirement.purchasePrice", {total: formatNumber(((1 - discount) * totalPrice))})
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const game = state.entities.game['first'];
    const company = state.entities.company['first'];

    return {
        game: game,
        materials: game.materials,
        materialStorage: company.previousState.materialStorage,
    }
};

const mapDispatchToProps = {
    updateMaterialPurchaseRequirement
};

const MaterialPurchaseRequirementTranslated = withTranslation('translation')(MaterialPurchaseRequirement)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MaterialPurchaseRequirementTranslated))

import React from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";
import {containsFatal} from "../services/errors";
import Errors from "./Errors";

class Main extends React.Component {
    render() {
        return (
            <div className="main">
                <Errors/>
                {containsFatal(this.props.errors) ?
                    null :
                    this.props.children
                }
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        errors: state.errors
    }
};

export default withRouter(connect(mapStateToProps, {})(Main))
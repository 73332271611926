import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {formatNumber} from "../services/utils";
import { withTranslation } from "react-i18next";

class SliderWithField extends React.Component {

    static propTypes = {
        title: PropTypes.string,
        defaultValue: PropTypes.number.isRequired,
        minValue: PropTypes.number.isRequired,
        maxValue: PropTypes.number.isRequired,
        step: PropTypes.number.isRequired,
        description: PropTypes.string,
        onChange: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            previousValue: props.defaultValue,
            value: props.defaultValue,
            rangeError: false
        };
    }

    wrapDescription(description) {
        if (description === undefined) {
            return "";
        } else {
            return description.split('\n').map((item, key) => {
                return <span key={key}>{item}<br/></span>
            })
        }
    }

    render() {
        const { title, defaultValue, minValue, maxValue, step, description } = this.props;

        return (
            <div className="form-item mb-4">
                <div className="form-label mb-1">{title}</div>
                <div className="d-flex align-items-center">
                    <div className="w-40 mr-3">
                        <input
                            type="number"
                            pattern="[0-9]*"
                            className="w-100"
                            value={this.state.value}
                            onChange={event => this.handleChange(event.target.value, false)}
                        />
                    </div>
                    <div className="w-10 mr-3 text-right d-none d-md-block">
                        {formatNumber(minValue)}
                    </div>
                    <div className="w-40 mr-3 d-none d-md-block">
                        <Slider
                            defaultValue={defaultValue}
                            min={minValue}
                            max={maxValue}
                            step={step}
                            value={(this.state.value !== "" && this.state.value >= minValue && this.state.value <= maxValue) ? this.state.value : 0}
                            onChange={newValue => this.handleChange(newValue, false)}
                            onAfterChange={value => this.handleChange(value, true)}
                        />
                    </div>
                    <div className="w-10 d-none d-md-block">
                        {formatNumber(maxValue)}
                    </div>
                    <div className="w-60 mr-2 text-right text-secondary text-right d-block d-md-none">
                        <small>{formatNumber(minValue)} {this.props.t("sliderWithField.to")} {formatNumber(maxValue)}</small>
                    </div>
                </div>
                {
                    (this.state.rangeError) ?
                    <div className="form-error text-danger">{this.props.t("sliderWithField.outOfRange", {min: minValue, max: maxValue})}</div>
                    : null
                }
                <div className="description">{this.wrapDescription(description)}</div>
            </div>
        );
    }

    handleChange(newValue, isFinal = false) {
        let intValue = parseInt(newValue, 10);
        intValue = isNaN(intValue) ? "" : intValue;
        this.setState({value: intValue});

        if (intValue === "" || intValue < this.props.minValue || intValue > this.props.maxValue) {
            this.setState({rangeError: true});
            return;
        }

        this.setState({rangeError: false});
        const hasAcceptedChange = this.props.onChange(intValue, isFinal);

        if (isFinal) {
            if (hasAcceptedChange !== false) {
                this.setState({previousValue: newValue});
            } else {
                this.rollbackChange();
            }
        }
    }

    rollbackChange() {
        this.setState({value: this.state.previousValue});
        this.props.onChange(this.state.previousValue, false);
    }
}

export default withTranslation('translation')(SliderWithField)

import React from 'react'
import {Redirect, withRouter} from 'react-router-dom'
import { connect } from "react-redux";

const StudentSection = ({ isTeacher, children }) => {

    if (isTeacher) { // redirect teacher away from the student section
        console.log(4);
        return <Redirect to={'/auth/teacher/games'}/>
    }

    return children;

};

export default withRouter(connect(state => {
    const user = state.entities.user['first'];

    return {
        isTeacher: user !== undefined && user.role === "teacher",
    }
}, {
})(StudentSection));




import { getAuthToken } from './authorization'
import sendServerRequest from './serverRequest'

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'Call API';

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
    if (action[CALL_API] === undefined) {
        return next(action)
    }

    let callAPI = action[CALL_API];
    let { endpoint } = callAPI;
    const { schema, types, method, data } = callAPI;
    const [ requestType, successType, failureType ] = types;

    if (typeof endpoint === 'function') {
        endpoint = endpoint(store.getState())
    }
    if (typeof endpoint !== 'string') {
        throw new Error('Specify a string endpoint URL.')
    }
    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected an array of three action types.')
    }
    if (!types.every(type => typeof type === 'string')) {
        throw new Error('Expected action types to be strings.')
    }

    const actionWith = data => {
        const finalAction = Object.assign({}, action, data);
        delete finalAction[CALL_API];
        return finalAction
    };

    next(actionWith({ type: requestType }));

    return sendServerRequest(endpoint, schema, method, data, getAuthToken()).then(
        response => next(actionWith({
            response,
            type: successType
        })),
        error => next(actionWith({
            error: error || { error: 'error', errorMessage: 'Nastala chyba. Zkuste prosím obnovit stránku.'},
            type: failureType
        }))
    )
}

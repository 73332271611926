/* eslint-disable no-undef */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Link, Redirect, withRouter} from 'react-router-dom'
import Loader from '../../../../components/Loader'
import {loadTeacherCompanies} from "../../../../actions/sync-modules";
import {sortSanitized, toArray} from "../../../../services/utils";

class Summaries extends Component {

    constructor(props) {
        super(props);

        const gameId = this.props.match.params.gameId;
        const game = props.games[gameId];

        this.state = {
            game: game
        };

        this.props.loadTeacherCompanies(gameId);
    }

    render() {
        if (this.state.game === undefined) {
            return <Redirect to={'/auth/teacher/games'} />
        } else if (this.props.didDownloadData !== true) {
            return <Loader />
        } else {
            return this.renderContent();
        }
    }

    renderContent() {
        const sortedCompanies = sortSanitized(toArray(this.props.teacherCompanies), 'name');

        return <div className={'col-xl-9'}>
            <div className={'box'}>
                <h3>Interní přehledy firem</h3>
                <div className={'content'}>
                    <table className={'table table-responsive'}>
                        <tbody>
                        {sortedCompanies.map(company => {
                            return <tr key={company.id}>
                                <td>
                                    <Link to={'/auth/teacher/administration/' + this.state.game.id + '/summaries/' + company.id}>{company.name}</Link>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>;
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        games: state.entities.teacherGames,
        teacherCompanies: state.entities.teacherCompanies,
        didDownloadData: state.downloadedEntities.teacherCompanies
    }
};

const mapDispatchToProps = {
    loadTeacherCompanies
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Summaries))

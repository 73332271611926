import React from 'react'
import Loader from "../../../../components/Loader";
import PropTypes from 'prop-types'
import {toArray} from "../../../../services/utils";
import Modal from 'react-modal';
import MarketDemand from "./MarketDemand";
import {
    addNewMarket,
    deleteTeacherMarket, loadMarketsRandomness,
    updateTeacherCompany, updateTeacherGame, updateTeacherMarket
} from "../../../../actions/sync-modules";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import Tooltip from 'react-tooltip-lite';
import {isRegistrationPeriod} from "../../../../services/quarter";
import config from "../../../../config";

class Markets extends React.Component {

    static propTypes = {
        game: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.defaultNewMarketName = "Market ";

        this.state = {
            addNewMarket: false,
            newMarketName: this.defaultNewMarketName,
            selectCompany: false,
            selectCompanyTargetMarket: null,
            isChangingEditingMode: false,
            showNoAutomaticCompanyMoreInfo: false,

    };

        this.props.loadMarketsRandomness(this.props.game.id);
    }

    render() {
        return (
            <div>
                <div className="box">
                    {this.state.isChangingEditingMode ?
                        <div className={'p-5'}><Loader/></div>
                        :
                        this.renderContent()
                    }
                </div>
                {this.renderModalWindow()}
            </div>
        )
    }

    renderContent() {
        return <div>
            <div className="row headline-row">
                <div className={'col-12'}>
                    {this.renderEditModeWarning()}
                </div>
                <div className="col mr-auto">
                    <h3>Přehled trhů</h3>
                </div>
                <div className="col mr-auto">
                    {this.renderAddMarketButton()}
                </div>
            </div>
            <div className="content">
                {this.renderNoAutomaticCompanyWarning()}
                <table className="table table-responsive-md">
                    <thead>
                    <tr>
                        <th>Název trhu</th>
                        <th colSpan={2}>Firmy na trhu</th>
                        <th>Poptávka</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderNewMarket()}
                    {this.renderTableRows()}
                    </tbody>
                </table>
            </div>
        </div>
    }

    renderAddMarketButton() {
        if (this.props.game.marketsEnabled === false) {
            return (
                <button
                    className="float-right btn btn-primary m-3"
                    onClick={() => this.setState({addNewMarket: true})}
                    disabled={this.state.addNewMarket === true || this.state.isAddingNewMarket === true}
                >Nový trh</button>
            );
        } else {
            return null;
        }
    }

    disableEditing() {
        if (isRegistrationPeriod(this.props.game)) {
            return alert("Nelze ukončit editaci trhů, dokud probíhají registrace.");
        }

        if (this.existsCompanyWithoutMarket()) {
            return alert("Nelze ukončit editaci trhů, protože existuje firma nepřiřazená na trh.");
        }

        if (this.existsMarketWithoutCompany()) {
            return alert("Nelze ukončit editaci trhů, protože existuje trh bez firmy.");
        }

        const confirmed = window.confirm("POZOR!!! Ukončení editace trhů je nevratná akce. Poté již NENÍ možné vytvářet trhy, mazat trhy, přesouvat firmy mezi trhy, odebírat firmy z trhů ani přidávat nové firmy na trhy!\n\nPřed potvrzením si prosím zkontrolujte, že jsou trhy správně vytvořeny a že na všech trzích je správný počet a složení firem.");

        if (confirmed) {
            this.setState({isChangingEditingMode: true, addNewMarket: false});
            this.props.updateTeacherGame(this.props.game.id, {marketsEnabled: true}, () => {
                this.setState({isChangingEditingMode: false});
            });
        }
    }

    renderEditModeWarning() {
        if (this.props.game.marketsEnabled === false) {
            return <div className={'alert alert-warning mb-0'}>
                <i className="material-icons mr-2">info</i>
                Hra začne, jakmile ukončíte editaci trhů. Do té doby není nic pro studenty přístupné.
                <button className={'btn btn-success ml-3 my-2'}
                      onClick={() => this.disableEditing()}>Ukončit editaci</button>
            </div>
        } else {
            return null;
        }
    }

    renderTableRows() {
        return toArray(this.props.teacherMarkets).map(market => {
            const companies = this.getCompaniesOnMarket(market);
            return (
                <tr key={market.id}>
                    <td>
                        <input
                            defaultValue={market.name}
                            onChange={e => this.props.updateTeacherMarket(this.state.gameId, market.id, {name: e.target.value})}
                            maxLength={config.maxCompanyNameLength}
                        />
                    </td>
                    <td colSpan={2}>
                        {this.renderCompanyChips(market, companies)}
                    </td>
                    <MarketDemand
                        game={this.props.game}
                        market={market}
                        companyCount={companies.length}
                    />
                    <td className={'d-flex'}>
                        {this.renderDeleteMarket(market)}
                    </td>
                </tr>
            );
        });
    }

    getCompaniesOnMarket(market) {
        return toArray(this.props.teacherCompanies).filter(company => company.marketId === market.id);
    }

    renderDeleteMarket(market) {
        if (this.props.game.marketsEnabled === false) {
            return <div className={'ml-auto'}>
                <Tooltip content="Smazat trh">
                    <span className="p-2 pl-3 pointer" data-tip="Smazat" data-for={'market-delete-' + market.id} onClick={() => {
                        if (window.confirm("Opravdu SMAZAT trh " + market.name + "? Tato akce nelze vrátit!")) {
                            this.props.deleteTeacherMarket(this.props.game.id, market.id);
                        }
                    }}><i className="material-icons">delete_outline</i></span>
                </Tooltip>
            </div>;
        } else {
            return null;
        }
    }

    renderCompanyChips(market, companies) {
        let results = companies.map(company => {
            return (
                <span
                    key={company.id}
                    className="badge badge-pill badge-primary badge-user mr-3 mb-2 text-left"
                >
                    <span className="badge-text">
                        {company.name}
                        <br/>
                        <small><i>{this.findSeminarName(company)}</i></small>
                    </span>
                    {this.props.game.marketsEnabled ? null :
                        <span className="pointer close-in-badge" onClick={
                            () => this.removeCompanyFromMarket(company)
                        }><i className="material-icons">close</i></span>}
                </span>
            )
        });

        if (this.props.game.marketsEnabled === false) {
            results.push(<span
                key={'add-company'}
                className="p-2 plus-button"
                onClick={() => this.setState({selectCompany: true, selectCompanyTargetMarket: market})}
            > </span>);
        }

        if (companies.length !== 5) {
            results.push(<div key="not-5-companies" className={'text-warning'}>Na trhu není 5 firem.</div>);
        }

        return results;
    }

    renderNewMarket() {
        if (this.state.addNewMarket) {
            return <tr key={'add-new-market'} className={'bg-light'}>
                <td colSpan={5}>
                    <input
                        defaultValue={this.state.newMarketName}
                        placeholder={'Zadejte název'}
                        onKeyPress={e => {
                            if (e.key === 'Enter') this.addNewMarket();
                        }}
                        onChange={(e) => this.setState({newMarketName: e.target.value})}
                        autoFocus={true}
                        maxLength={config.maxCompanyNameLength}
                    />
                    <button
                        className="btn btn-primary btn-sm ml-3"
                        onClick={() => this.addNewMarket()}
                    >Vytvořit trh
                    </button>
                    <button
                        className="btn btn-primary btn-sm ml-3"
                        onClick={() => this.setState({addNewMarket: false})}
                    >Zrušit
                    </button>
                </td>
            </tr>

        } else if (this.state.isAddingNewMarket) {
            return <tr>
                <td colSpan={2}><Loader/></td>
            </tr>
        }
    }

    addNewMarket() {
        const name = this.state.newMarketName;
        this.setState({
            addNewMarket: false,
            isAddingNewMarket: true,
            newMarketName: this.defaultNewMarketName
        }, () => {
            this.props.addNewMarket(this.props.game.id, name, result => {
                this.setState({isAddingNewMarket: false});
                this.props.loadMarketsRandomness(this.props.game.id);
            });
        });
    }

    renderModalWindow() {
        const marketName = this.state.selectCompanyTargetMarket !== null ? this.state.selectCompanyTargetMarket.name : '';

        Modal.setAppElement('#root');

        return (
            <Modal
                id="test"
                contentLabel="modalA"
                closeTimeoutMS={150}
                isOpen={this.state.selectCompany}
                style={{content: {padding: 0}}}
            >
                <div className={'modal-headline-block'}>
                    <button className="btn btn-primary float-right ml-4"
                            onClick={() => this.setState({selectCompany: false})}>Zrušit
                    </button>
                    <h3>Zvolte firmu pro přidání na {marketName}</h3>
                    <hr/>
                </div>
                <div className={'modal-content-block'}>
                    <ul>
                        {toArray(this.props.teacherCompanies)
                            .filter(company => company.marketId === null)
                            .sort((a, b) => a.seminarId - b.seminarId)
                            .map(company => (
                                <li
                                    className={'text-primary mb-4 pointer'}
                                    key={company.id}
                                    onClick={() => this.updateCompanyMarket(company)}
                                >
                                    <u>{company.name} ({this.findSeminarName(company)})</u>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </Modal>
        )
    }

    findSeminarName(company) {
        const seminar = this.props.game.seminars.find(i => i.id === company.seminarId);
        return (seminar === undefined) ? '' : seminar.name;
    }

    updateCompanyMarket(company) {
        const market = this.state.selectCompanyTargetMarket;
        this.setState({selectCompany: false});
        this.props.updateTeacherCompany(this.props.game.id, company.id, {
            "marketId": market.id
        });
    }

    removeCompanyFromMarket(company) {
        this.props.updateTeacherCompany(this.props.game.id, company.id, {
            "marketId": null
        });
    }

    renderNoAutomaticCompanyWarning() {
        let existsAutomaticCompany = false;

        toArray(this.props.teacherMarkets).forEach(market => {
            this.getCompaniesOnMarket(market).forEach(company => {
                if (company.mode === "automatic") {
                    existsAutomaticCompany = true;
                }
            })
        });

        if (existsAutomaticCompany || this.props.game.marketsEnabled) {
            return null;
        } else {
            return (
                <div className={'alert alert-warning'}>
                    <i className="material-icons mr-2">info</i>
                    Ve hře není žádný trh s automatickou firmou!&nbsp;
                    {
                        this.state.showNoAutomaticCompanyMoreInfo ?
                            <span>
                                To je problém, protože po ukončení editace trhů již není možné přesouvat firmy mezi trhy.
                                Takové přesuny firem by změnily hodnoty pro předchozí kvartály a to nechceme.
                                Je však možné kdykoliv přesouvat studenty mezi firmami. Je proto vhodné mít na trhu rezervní automatické firmy, aby bylo možné i během hry provádět
                                přesuny studentů. Např. studenta flákače je možné vyčlenit z fungující firmy a přesunout ho do rezervní automatické firmy,
                                která je poté přepnuta na manuální. Takové rezervní firmy tedy umožní provádět určité změny i během hry.
                            </span>
                            :
                            <a href="/" onClick={(e) => {
                                this.setState({showNoAutomaticCompanyMoreInfo: true});
                                e.preventDefault();
                            }}>více info &raquo;</a>
                    }
                </div>
            );
        }
    }

    existsCompanyWithoutMarket() {
        let existsCompanyWithoutMarket = false;

        toArray(this.props.teacherCompanies).forEach(company => {
            if (company.marketId === null) {
                existsCompanyWithoutMarket = true;
            }
        });

        return existsCompanyWithoutMarket;
    }

    existsMarketWithoutCompany() {
        let existsMarketWithoutCompany = false;

        toArray(this.props.teacherMarkets).forEach(market => {
            if (this.getCompaniesOnMarket(market).length === 0) {
                existsMarketWithoutCompany = true;
            }
        });

        return existsMarketWithoutCompany;
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        games: state.entities.teacherGames,
        teacherCompanies: state.entities.teacherCompanies,
        teacherMarkets: state.entities.teacherMarkets
    }
};

const mapDispatchToProps = {
    addNewMarket,
    updateTeacherCompany,
    deleteTeacherMarket,
    loadMarketsRandomness,
    updateTeacherGame,
    updateTeacherMarket
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Markets))

import React from 'react'
import PropTypes from 'prop-types'

const Loader = ({isLarge = true}) => {
    const className = isLarge ? "large-loading" : "small-loading";
    return (
        <div className={className}>
            <div className="loader"> </div>
        </div>
    );
};

Loader.propTypes = {
    isLarge: PropTypes.bool
};

export default Loader

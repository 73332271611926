import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateStaffPersonalPolicy } from '../../../../actions/sync-modules'
import LastChange from '../../../../components/LastChange'
import SliderWithField from "../../../../components/SliderWithField";
import SelectField from "../../../../components/SelectField";
import PropTypes from 'prop-types'
import {formatNumber, nl2br} from "../../../../services/utils";
import { withTranslation } from "react-i18next";

class StaffPersonalPolicy extends Component {

    static propTypes = {
        onSalaryIndexChange: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            isInitialized: false,
            salaryIndex: 0,
            education: 0
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state.isInitialized === false && props.staffPersonalPolicy !== undefined) {
            props.onSalaryIndexChange(props.staffPersonalPolicy.salaryIndex);
            return {
                isInitialized: true,
                salaryIndex: props.staffPersonalPolicy.salaryIndex,
                education: props.staffPersonalPolicy.education
            }
        }
        return null
    }

    render() {
        const { t } = this.props;
        if (this.props.staffPersonalPolicy === undefined) return null;

        const salary = this.props.salaryBase * (this.state.salaryIndex/100);

        return [
            <div className="content" key="content">
                <SliderWithField
                    defaultValue={this.state.salaryIndex}
                    minValue={this.props.minSalaryIndex}
                    maxValue={this.props.maxSalaryIndex}
                    step={1}
                    description={t("staffPersonalPolicy.salaryAmount", {amount: formatNumber(Math.round(salary))})}
                    title={t("staffPersonalPolicy.salaryIndex")}
                    onChange={val => this.setState({salaryIndex: val}, this.handleChange)}
                />
                <SelectField
                    title={t("staffPersonalPolicy.educateEmploees")}
                    onChange={(val) => this.setState({education: val}, this.handleChange)}
                    options={[{title: t("staffPersonalPolicy.no"), value: false}, {title: t("staffPersonalPolicy.yes"), value: true}]}
                    defaultValue={this.state.education}
                />
                <div className="form-item mb-3">
                    <div className="form-label mb-1">{t("staffPersonalPolicy.laborMarket")}</div>
                    <div className="description">{nl2br(this.getMoreInfoText())}</div>
                </div>
            </div>,
            <LastChange key="last-change" object={this.props.staffPersonalPolicy} t={t} />
        ]
    }

    handleChange() {
        this.props.updateStaffPersonalPolicy({
            salaryIndex: this.state.salaryIndex,
            education: this.state.education
        });
        this.props.onSalaryIndexChange(this.state.salaryIndex);
    }

    getMoreInfoText() {
        const { t } = this.props;
        const piUnderAverage = this.props.staffPersonalPolicy.wasPersonalIndexUnderMarketAverageInPreviousQuarter;
        const hiredEmployeeCount = this.props.staffRequirement.hire;

        let out = piUnderAverage ? t("staffPersonalPolicy.indexUnderAvg") : t("staffPersonalPolicy.indexAboveAvg")

        if (piUnderAverage && hiredEmployeeCount > 0) {
            const hiringCosts = hiredEmployeeCount * this.props.hiringFee;
            out += t("staffPersonalPolicy.hiringCosts", {amount: Math.round(hiringCosts / 1000) })
        }

        return out;
    }

}

const mapStateToProps = (state, ownProps) => {
    const game = state.entities.game['first'];

    return {
        goods: game.goods,
        maxSalaryIndex: game.maxSalaryIndex,
        minSalaryIndex: game.minSalaryIndex,
        staffRequirement: state.entities.staffRequirement['first'],
        staffPersonalPolicy: state.entities.staffPersonalPolicy['first'],
        salaryBase: game.defaultSalary,
        hiringFee: game.hiringFee
    }
};

const mapDispatchToProps = {
    updateStaffPersonalPolicy
};

const StaffPersonalPolicyTranslated = withTranslation('translation')(StaffPersonalPolicy)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StaffPersonalPolicyTranslated))

import React from 'react'
import PropTypes from 'prop-types'
import {nl2br} from "../services/utils";

class SelectField extends React.Component {

    static propTypes = {
        title: PropTypes.string,
        defaultValue: PropTypes.any,
        options: PropTypes.array.isRequired,
        description: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        name: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            value: props.options.findIndex(option => option.value === props.defaultValue)
        };
    }

    render() {
        const { title, options, description } = this.props;

        return (
            <div className={"form-item mb-3"}>
                {title !== undefined ? <div className="form-label mb-1">{title}</div> : null}
                <select
                    name={this.props.name}
                    className="custom-select"
                    value={this.state.value}
                    onChange={(e) => this.setState({value: e.target.value}, this.handleChange)}
                    disabled={this.props.disabled}
                >
                    {options.map((option, index) => <option key={index} value={index}>{option.title}</option>)}
                </select>
                <div className="description">{nl2br(description)}</div> 
            </div>
        );
    }

    handleChange() {
        const originalValue = this.props.options[this.state.value].value;
        this.props.onChange(originalValue);
    }
}

export default SelectField

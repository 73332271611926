import React from 'react'
import PropTypes from 'prop-types'
import {toArray} from "../services/utils";
import {quarterNameFor} from "../services/quarter";
import config from "../config";
import { withTranslation } from "react-i18next";

class SummariesTable extends React.Component {

    static propTypes = {
        game: PropTypes.object.isRequired,
        summaries: PropTypes.object
    };

    render() {
        const summaries = toArray(this.props.summaries);

        if (summaries.length === 0) {
            return <p>{this.props.t("summariesTable.noDocYet")}</p>;
        } else {
            return this.renderTable(summaries);
        }
    }

    renderTable(summaries) {
        return (
            <table className="table">
                <thead>
                <tr>
                    <th>{this.props.t("summariesTable.yearSummary")}</th>
                    <th>{this.props.t("summariesTable.quarterSummary")}</th>
                </tr>
                </thead>
                <tbody>
                {summaries.map((summary, index) => {
                    const firstQuarterOfYear = summary.quarterNumber - ((summary.quarterNumber - 1) % 4);
                    const yearSummaryName =
                        quarterNameFor(this.props.game, firstQuarterOfYear) +
                        ' - ' +
                        quarterNameFor(this.props.game, summary.quarterNumber);
                    const quarterSummaryName = quarterNameFor(this.props.game, summary.quarterNumber);

                    return (
                        <tr key={index}>
                            <td>
                                <a href={config.serverUrl + 'summaries/' + localStorage.getItem('language') + '/' + summary.yearSummary} target="_blank" rel="noopener noreferrer">
                                    <i className="material-icons">cloud_download</i>
                                    <span className="summary-name">{yearSummaryName}</span>
                                </a>
                            </td>
                            <td>
                                <a href={config.serverUrl + 'summaries/' + localStorage.getItem('language') + '/' + summary.quarterSummary} target="_blank" rel="noopener noreferrer">
                                    <i className="material-icons">cloud_download</i>
                                    <span className="summary-name">{quarterSummaryName}</span>
                                </a>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }
}

export default withTranslation('translation')(SummariesTable)

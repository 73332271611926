import React from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";
import {loadUserCompanies, updateUserCompany, addNewCompany, updateCompany, deleteCompany } from "../../../../actions/sync-modules";
import Loader from "../../../../components/Loader";
import {dateFromUnixTimestamp, formatDateTime, toArray} from "../../../../services/utils";
import SelectSeminar from "../../../../components/SelectSeminar";
import {removeErrorMessageById} from "../../../../actions/errors";
import {Schemas} from "../../../../middleware/sync/schema";
import {existsServerError, serverErrorId} from "../../../../services/errors";
import Tooltip from 'react-tooltip-lite';
import config from "../../../../config";
import {withTranslation, Trans} from "react-i18next";

class CompanyRegistration extends React.Component {

    static propTypes = {
    };

    constructor(props) {
        super(props);

        this.addNewCompany = this.addNewCompany.bind(this);

        this.state = {
            isUserInCompany: false,
            userCompany: undefined,
            addNewCompany: false,
            userHasCreatedCompany: false,
            userOwnCompany: undefined,
            isSaving: false,
        };

        this.props.loadUserCompanies();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.userCompanies !== undefined) {
            const userCompany = toArray(props.userCompanies).filter(company => company.users.find(user => user.id === props.user.id) !== undefined);
            const userOwnCompany = toArray(props.userCompanies).filter(company => company.ownerId === props.user.id);
            return {
                isUserInCompany: userCompany.length > 0,
                userCompany: userCompany[0],
                userHasCreatedCompany: userOwnCompany.length > 0,
                userOwnCompany: userOwnCompany[0]
            }
        }
        return {
            userHasCreatedCompany: false
        };
    }

    render() {
        const { t } = this.props;
        return (
            <div className="container">
                <div className="box">
                    <div className="row headline-row">
                        <div className="col mr-auto">
                            <h3>{t("companyRegistration.overview")}</h3>
                        </div>
                        <div className="col mr-auto">
                            <button
                                className="float-right btn btn-primary m-3"
                                onClick={() => this.setState({addNewCompany: true})}
                                disabled={this.state.isAddingNewCompany || this.state.addNewCompany || this.state.userHasCreatedCompany || !this.props.didDownloadData || this.state.isSaving}
                            >{t("companyRegistration.newCompany")}</button>
                        </div>
                    </div>
                    <div className="content">
                        {this.renderContent()}
                    </div>
                </div>
            </div>
        )
    }

    renderContent() {
        if (!this.props.didDownloadData || this.state.isSaving) {
            return <Loader/>
        }

        return (
            <div>
                {this.renderNewCompany()}
                {this.renderUserInCompanyInfo()}
                {this.renderMyCompanyInfo()}
                <div className="companies-list">
                    {this.renderCompanies()}
                </div>
            </div>
        )
    }

    renderUserInCompanyInfo() {
        const registrationEndDate = formatDateTime(dateFromUnixTimestamp(this.props.game.endOfRegistrations));

        if (this.state.isUserInCompany) {
            return <div className='alert alert-success mb-3'>
                <Trans values={{ companyName: this.state.userCompany.name, registrationEndDate: registrationEndDate }}>
                    companyRegistration.addSuccess
                </Trans>
                
            </div>
        } else {
            return <div className='alert alert-info mb-3'>
                <i className="material-icons">error</i>
                <Trans values={{ registrationEndDate: registrationEndDate }}>
                    companyRegistration.notYetRegisteredInfo
                </Trans>
            </div>
        }
    }

    renderMyCompanyInfo() {
        const { t } = this.props;
        if (this.state.userHasCreatedCompany) {
            return <div className='alert alert-success mb-3'>
                {t("companyRegistration.founderInfo", {companyName: this.state.userOwnCompany.name})}
            </div>
        } else {
            return null;
        }
    }

    renderCompanies() {
        const { t } = this.props;
        const arr = toArray(this.props.userCompanies).sort((a, b) => {
            if (a.ownerId === this.props.user.id) return -1;
            else if (a.name > b.name) return 1;
            else if (a.name < b.name) return -1;
            else return 0;
        });

        if (arr.length === 0) {
            return <div key={'none'}>{t("companyRegistration.nothingFounded")}</div>
        }

        return arr.map(item => {
            if (item.ownerId === this.props.user.id) {
                return this.renderMyCompanyRow(item);
            } else {
                return this.renderForeignCompanyRow(item);
            }
        });
    }

    renderForeignCompanyRow(item) {
        return (
            <div key={item.id} className={'mb-3'}>
                <h5>{item.name}</h5>
                <div className={'mb-2'}>{this.findSeminarName(item.seminarId)}</div>
                <div className={'mb-5'}>{this.renderUserChips(item, item.users)}</div>
            </div>
        )
    }

    findSeminarName(seminarId) {
        const seminars = this.props.game.seminars;
        const seminar = seminars.find(i => i.id === seminarId);

        return seminar === undefined ? '' : seminar.name;
    }

    renderMyCompanyRow(item) {
        const { t } = this.props;
        return (
            <div key={item.id} className={'mb-3 bg-light p-3 clearfix'}>
                <h5>{t("companyRegistration.yourCompany")}</h5>
                <div className={'mb-3'}>
                    {t("companyRegistration.companyName")}<br/>
                    <input defaultValue={item.name} maxLength={config.maxCompanyNameLength} onChange={
                        e => this.updateCompany(item.id, e.target.value, item.seminar)
                    } />
                </div>
                <div>
                    {t("companyRegistration.seminar")}
                    <SelectSeminar
                        defaultValue={item.seminarId}
                        onChange={value => { if (value !== false) this.updateCompany(item.id, item.name, value); }}
                        game={this.props.game}
                    />
                </div>
                <div className={'mb-3'}>
                    {this.renderUserChips(item, item.users)}
                </div>
                <div className={'float-right'}>
                    <Tooltip content={t("companyRegistration.deleteCompany")}>
                        <span className="btn btn-light btn-sm text-danger pointer" data-tip={t("companyRegistration.deleteCompany")} data-for={'delete-company-' + item.id} onClick={() => {
                            if (window.confirm(t("companyRegistration.confirmDelete", {companyName: item.name}))) {
                                this.props.deleteCompany(item.id);
                            }
                        }
                        }><i className="material-icons">delete_outline</i> {t("companyRegistration.delete")}</span>
                    </Tooltip>
                </div>
            </div>
        )
    }

    updateCompany(id, name, seminarId) {
        this.props.removeErrorMessageById(serverErrorId('put', Schemas.USER_COMPANY));
        this.props.updateCompany(id, name, seminarId);
    }

    renderUserChips(company, students) {
        const { t } = this.props;
        let isMeInRow = false;

        let results = students.map(student => {
            const isItMe = student.id === this.props.user.id;
            isMeInRow = isItMe ? true : isMeInRow;
            const cn = isItMe ? 'badge-violet' : 'badge-primary';
            return (
                <span
                    key={student.name}
                    className={"badge badge-pill " + cn + " badge-user mr-3 mb-2"}
                >
                    <span className="badge-text">{student.name}</span>
                    { (isItMe && this.state.userHasCreatedCompany === false) ?
                        <span className="close-in-badge" onClick={
                            () => this.updateUserCompany(null)
                        }><i className="material-icons">close</i></span>
                        : null }
                </span>
            )
        });

        if (students.length === 0) {
            results.push(<div key={'no-students'} className={'mb-2'}>{t("companyRegistration.noStudents")}</div>);
        }

        if (isMeInRow === false && this.state.userHasCreatedCompany === false) {
            results.push(<button key={'add-to-company'} className={'btn btn-sm btn-light'} onClick={() =>
                this.updateUserCompany(company.id)
            }><i className="material-icons">add</i> {t("companyRegistration.joinCompany")}</button>);
        }

        return results;
    }

    updateUserCompany(companyId) {
        this.setState({isSaving: true});
        this.props.removeErrorMessageById(serverErrorId('put', Schemas.USER));
        this.props.updateUserCompany(this.props.user, companyId, () => {
            if (this.props.existsUserCompanyUpdateError === false) {
                // success
                this.props.loadUserCompanies();
                this.setState({isSaving: false});
            } else {
                // failure
                this.setState({isSaving: false});
            }
        });
    }

    renderNewCompany() {
        const { t } = this.props;
        const addNewCompanyHandler = this.addNewCompany;

        if (this.state.addNewCompany) {
            return <div key={'add-new-company'} className={'new-company bg-light p-4 mb-4'}>
                <h4>{t("companyRegistration.companyCreation")}</h4>
                <div className={'mb-2'}>
                {t("companyRegistration.companyName")} <br/>
                    <input
                        placeholder={t("companyRegistration.enterName")}
                        defaultValue={this.state.newCompanyName}
                        onKeyPress={e => { if(e.key === 'Enter') addNewCompanyHandler(); }}
                        onChange={e => this.setState({ newCompanyName: e.target.value})}
                        maxLength={config.maxCompanyNameLength}
                    />
                </div>
                <div className={'mb-2'}>
                {t("companyRegistration.seminar")}<br/>
                    <SelectSeminar
                        defaultValue={this.state.newCompanySeminarId}
                        onChange={value => this.setState({ newCompanySeminarId: value})}
                        game={this.props.game}
                    />
                </div>
                <div>
                    <button
                        className="btn btn-primary btn-sm mr-3"
                        onClick={() => addNewCompanyHandler()}
                        disabled={!this.state.newCompanyName || !this.state.newCompanySeminarId}
                    >{t("companyRegistration.createCompany")}</button>
                    <button
                        className="btn btn-primary btn-sm"
                        onClick={() => this.setState({addNewCompany: false})}
                    >{t("companyRegistration.cancel")}</button>
                </div>
            </div>

        } else if (this.state.isAddingNewCompany) {
            return <Loader/>
        }
    }

    addNewCompany() {
        if (!this.state.newCompanyName || !this.state.newCompanySeminarId) {
            return;
        }

        this.props.removeErrorMessageById(serverErrorId('post', Schemas.USER_COMPANY));

        this.setState({addNewCompany: false, isAddingNewCompany: true}, () => {
            this.props.addNewCompany(this.state.newCompanyName, this.state.newCompanySeminarId, () => {
                if (this.props.existsCompanyCreateError === false) {
                    // success:
                    // hide add company form
                    this.setState({isAddingNewCompany: false, newCompanyName: null, newCompanySeminarId: null});
                    // assign user to the new company
                    const newCompanyId = toArray(this.props.userCompanies).find(i => i.ownerId === this.props.user.id).id;
                    this.updateUserCompany(newCompanyId);
                } else {
                    // failure:
                    // show add company form again
                    this.setState({isAddingNewCompany: false, addNewCompany: true});
                }
            });
        });
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.entities.user['first'],
        game: state.entities.game['first'],
        userCompanies: state.entities.userCompanies,
        didDownloadData: state.downloadedEntities.userCompanies,
        existsCompanyCreateError: existsServerError(state.errors, 'post', Schemas.USER_COMPANY),
        existsUserCompanyUpdateError: existsServerError(state.errors, 'put', Schemas.USER)

    }
};

const CompanyRegistrationTranslated = withTranslation('translation')(CompanyRegistration)

export default withRouter(connect(mapStateToProps, {
    loadUserCompanies,
    updateUserCompany,
    addNewCompany,
    updateCompany,
    deleteCompany,
    removeErrorMessageById
})(CompanyRegistrationTranslated))

export default {
    version: '1.0.2',
    serverUrl: process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000/', // slash / must be at the end
    maxNumber: 1000000000,
    minNumber: -1000000000,
    maxString: 255,
    maxCompanyNameLength: 30,
    maxRegistrationPhraseLength: 50,
    defaultLanguage: 'en', // 'cs' or 'en', used for the first visit of the page, when the user changes the language manually, this takes no effect
}

import React from 'react'
import PropTypes from 'prop-types'
import SelectField from "./SelectField";
import { withTranslation } from "react-i18next";

class SelectSeminar extends React.Component {

    static propTypes = {
        game: PropTypes.object.isRequired,
        defaultValue: PropTypes.number,
        onChange: PropTypes.func.isRequired
    };

    render() {
        let options = this.props.game.seminars.map(seminar => 
            ({title: (localStorage.getItem("language") == "cs" ? seminar.name : seminar.nameEn), value: seminar.id}));
        options.unshift({title: this.props.t("selectSeminar.select"), value: false});

        return (
            <SelectField
                defaultValue={this.props.defaultValue}
                options={options}
                onChange={value => this.props.onChange(value)}
            />
        )
    }

}

export default withTranslation('translation')(SelectSeminar)
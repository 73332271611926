/* eslint-disable no-undef */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {formatNumber} from "../../../../services/utils";
import {withTranslation} from 'react-i18next';

class CashFlow extends Component {
    static propTypes = {
        cashFlow: PropTypes.object.isRequired,
        isBankrupted: PropTypes.bool.isRequired
    };

    render() {
        const { cashFlow, t } = this.props;

        return (
            <div className="box">
                <h3>{t("cashFlow.cashFlow")}</h3>
                <div className="content">
                    <table className="table table-sm">
                        <thead>
                        <tr className={'bg-light'}>
                            <th>{t("cashFlow.originalState")}</th>
                            <th className="text-right">{formatNumber(Math.round(cashFlow.defaultCash))}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className={'bg-light'}>
                            <td>{t("cashFlow.totalIncome")}</td>
                            <td className="text-right">{formatNumber(Math.round(cashFlow.income.total))}</td>
                        </tr>
                        <tr>
                            <td className="pl-4">{t("cashFlow.sales")}</td>
                            <td className="text-right">{formatNumber(Math.round(cashFlow.income.revenues))}</td>
                        </tr>
                        <tr>
                            <td className="pl-4">{t("cashFlow.loan1")}</td>
                            <td className="text-right">{formatNumber(Math.round(cashFlow.income.investLoan))}</td>
                        </tr>
                        <tr>
                            <td className="pl-4">{t("cashFlow.loan2")}</td>
                            <td className="text-right">{formatNumber(Math.round(cashFlow.income.bridgingLoan))}</td>
                        </tr>
                        <tr>
                            <td className="pl-4">{t("cashFlow.extraIncome")}</td>
                            <td className="text-right">{formatNumber(Math.round(cashFlow.income.extraordinaryIncome))}</td>
                        </tr>
                        <tr className={'bg-light'}>
                            <td>{t("cashFlow.totalExpense")}</td>
                            <td className="text-right">{formatNumber(Math.round(cashFlow.outcome.total))}</td>
                        </tr>
                        <tr>
                            <td className="pl-4">{t("cashFlow.investments")}</td>
                            <td className="text-right">{formatNumber(Math.round(cashFlow.outcome.investments))}</td>
                        </tr>
                        <tr>
                            <td className="pl-4">{t("cashFlow.expenses")}</td>
                            <td className="text-right">{formatNumber(Math.round(cashFlow.outcome.outcome))}</td>
                        </tr>
                        <tr>
                            <td className="pl-4">{t("cashFlow.taxes")}</td>
                            <td className="text-right">{formatNumber(Math.round(cashFlow.outcome.taxes))}</td>
                        </tr>
                        <tr>
                            <td className="pl-4">{t("cashFlow.extraExpense")}</td>
                            <td className="text-right">{formatNumber(Math.round(cashFlow.outcome.extraordinaryOutcome))}</td>
                        </tr>
                        <tr className={'bg-light'}>
                            <th>{t("cashFlow.finalMoney")}</th>
                            <th className="text-right">{formatNumber(Math.round(cashFlow.finalCash))}</th>
                        </tr>
                        </tbody>
                    </table>
                </div>
                {this.renderBankruptcyWarning()}
            </div>
        )
    }

    renderBankruptcyWarning() {
        const { t } = this.props;
        if (this.props.isBankrupted === false) {
            return null;
        } else {
            return <div className="box-footer bg-danger text-white">
                <h5>{t("cashFlow.bankrupt")}</h5>
                {t("cashFlow.loanGranted")}
            </div>
        }
    }
}

export default withTranslation('translation')(CashFlow);

/* eslint-disable no-undef */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { loadStaff } from '../../../../actions/sync-modules'
import Loader from '../../../../components/Loader'
import StaffTable from "./StaffTable";
import StaffRequirement from "./StaffRequirement";
import StaffPersonalPolicy from "./StaffPersonalPolicy";
import { withTranslation } from "react-i18next";

class Staff extends Component {

    constructor(props) {
        super(props);
        this.props.loadStaff();

        this.state = {
            salaryIndex: null
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="row">
                    <div className="col">
                        <div className="box">
                            <h3>{t("staff.humanResources")}</h3>
                            <div className="content">
                                {this.renderStaffTable()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="box box-important">
                            <h3>{t("staff.hireAndFire")}</h3>
                            {this.renderStaffRequirement()}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="box box-important">
                            <h3>{t("staff.personalPolicy")}</h3>
                            {this.renderStaffPolicy()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderStaffTable() {
        const { t } = this.props;
        if (this.props.staff === undefined) {
            return (
                <Loader/>
            );
        } else if (this.props.staff.length === 0) {
            return (
                <p>{t("staff.noData")}</p>
            );
        } else {
            return <StaffTable data={this.props.staff}  game={this.props.game} t={t}/>
        }
    }

    renderStaffRequirement() {
        if (this.props.staffRequirement === undefined || this.props.staffPersonalPolicy === undefined) {
            return <Loader/>
        } else {
            return <StaffRequirement salaryIndex={this.state.salaryIndex} />
        }
    }

    renderStaffPolicy() {
        if (this.props.staffRequirement === undefined || this.props.staffPersonalPolicy === undefined) {
            return <Loader/>
        } else {
            return <StaffPersonalPolicy onSalaryIndexChange={newSalaryIndex => this.setState({salaryIndex: newSalaryIndex})}/>
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const game = state.entities.game['first'];

    return {
        game: game,
        goods: game.goods,
        staff: state.entities.staff['first'],
        staffRequirement: state.entities.staffRequirement['first'],
        staffPersonalPolicy: state.entities.staffPersonalPolicy['first']
    }
};

const mapDispatchToProps = {
    loadStaff
};

const StaffTranslated = withTranslation('translation')(Staff)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StaffTranslated))

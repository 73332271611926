import React from 'react'
import {Redirect, withRouter} from 'react-router-dom'
import { login } from '../../../actions/sync-modules'
import PropTypes from 'prop-types'
import {connect} from "react-redux";
import Loader from "../../../components/Loader";
import { withTranslation } from "react-i18next";
import i18next from 'i18next';

class Login extends React.Component {

    static propTypes = {
        isAuthenticated: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);

        this.login = this.login.bind(this);
        this.usernameInputRef = React.createRef();
        this.passwordInputRef = React.createRef();

        this.state = {
            isProcessing: false
        };
    }

    render() {
        const { t } = this.props;


        if (this.state.isProcessing) {
            return <Loader/>
        }

        if (this.props.isAuthenticated) {
            let defaultPath = this.props.isTeacher ? '/auth/teacher/games' : '/auth/student/company/dashboard';
            const {from} = this.props.location.state || {from: {pathname: defaultPath}};
            if (this.props.isTeacher) {
                i18next.changeLanguage('cs')
                localStorage.setItem("language", "cs")
            }
            return <Redirect to={from}/>
        }

        return (
                <div className="pt-5 col-md-7 col-lg-3 mx-auto">
                    <form action="#" onSubmit={
                        (e) => {
                            this.login();
                            e.preventDefault();
                        }
                    }>
                        <div className="box p-4 d-flex flex-column justify-content-center align-items-center">
                            <h3 className="mb-3">{t("login.logingin")}</h3>
                            <p>
                                <input type="text" placeholder={t("login.uniLogin")} name="email" required ref={this.usernameInputRef} autoComplete="username" />
                            </p>
                            <p>
                                <input type="password" placeholder={t("login.password")} name="password" required ref={this.passwordInputRef} autoComplete="current-password" />
                            </p>
                            <p className="mt-3">
                                <button className="btn btn-primary">{t("login.logIn")}</button>
                            </p>
                        </div>
                    </form>
                </div>
        )
    }

    login() {
        // We must use refs instead of onChange handlers because of a bug in Chrome on iOS. This bug causes that autofilled values do not trigger onChange events.
        // https://github.com/facebook/react/issues/1159
        const username = this.usernameInputRef.current.value;
        const password = this.passwordInputRef.current.value;

        this.setState({isProcessing: true});
        this.props.login(username, password, () => {
            this.setState({isProcessing: false});
        });
    }
}

const mapStateToProps = (state, ownProps) => {
    const user = state.entities.user['first'];

    return {
        isRegisteredToGame: user && user.gameId !== null,
        isAuthenticated: state.isAuthenticated,
        isBeingAuthenticated: state.isBeingAuthenticated,
        isTeacher: user !== undefined && user.role === "teacher"
    }
};

const LoginTranslated = withTranslation('translation')(Login)

export default withRouter(connect(mapStateToProps, {
    login
})(LoginTranslated))

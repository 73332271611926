import React from 'react'
import {withRouter,Link} from 'react-router-dom'
import {logout} from '../actions/sync-modules'
import PropTypes from 'prop-types'
import {connect} from "react-redux";
import onClickOutside from "react-onclickoutside";
import {resetErrorMessage} from "../actions/errors";
import {formatDateTimeShort} from "../services/utils";
import { withTranslation } from "react-i18next";

class UserButton extends React.Component {

    static propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
        userName: PropTypes.string,
        logout: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            isUserMenuVisible: false
        };
    }

    render() {
        if (this.props.isAuthenticated){

            let backgroundImage = "url('" + process.env.PUBLIC_URL + "/img/avatar.svg')";
            let className = "user";

            if (this.props.profilePicture !== null) {
                backgroundImage = "url(" + this.props.profilePicture + ")";
                className += " bordered";
            }

            return (
                <div className="nav-item dropdown">
                    <div
                        className={className}
                        style={{backgroundImage: backgroundImage}}
                        onClick={() => this.setState({isUserMenuVisible: !this.state.isUserMenuVisible})}
                    > </div>
                    <div className={
                        "dropdown-menu dropdown-menu-right" +
                        ((this.state.isUserMenuVisible) ? ' d-block' : '')
                    } aria-labelledby="navbarDropdown">
                        <div className="dropdown-item-text">
                            {this.props.userName}
                            {this.renderLastLogin()}
                        </div>
                        <div className="dropdown-divider"> </div>
                        <span
                            className={"dropdown-item pointer " + ((this.props.isSyncing || this.props.isWaitingForSync) && 'disabled')}
                            onClick={(e) => {
                                this.setState({isUserMenuVisible: false});
                                this.props.resetErrorMessage();
                                this.props.logout();
                                e.preventDefault();
                            }}
                        >{this.props.t("userButton.logout")}</span>
                    </div>
                </div>
            )
        } else if (this.props.location.pathname !== '/login'){
            return (
                <Link to="/login" className="btn btn-default">{this.props.t("userButton.login")}</Link>
            )
        } else {
            return null
        }
    }

    renderLastLogin() {
        if (this.props.lastLogin === null) {
            return null;
        } else {
            const date = new Date(this.props.lastLogin * 1000);
            return (
                <div className={'last-login text-secondary mt-2'} >
                    {this.props.t("userButton.lastLogin")}<br/>
                    {formatDateTimeShort(date)}
                </div>
            );
        }
    }

    handleClickOutside(evt) {
        this.setState({isUserMenuVisible: false});
    }
}

const mapStateToProps = (state, ownProps) => {
    const user = state.entities.user['first'];

    return {
        userName: user === undefined ? localStorage.getItem('authUserName') : user.name,
        lastLogin: user !== undefined ? user.lastLogin : null,
        isAuthenticated: state.isAuthenticated,
        isSyncing: state.isSyncing,
        isWaitingForSync: state.isWaitingForSync,
        profilePicture: state.profilePicture
    }
};

export default withRouter(connect(mapStateToProps, {
    logout,
    resetErrorMessage
})(withTranslation('translation')(onClickOutside(UserButton))))

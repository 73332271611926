import React from 'react'
import {Redirect, withRouter} from 'react-router-dom'
import { connect } from "react-redux";

const TeacherSection = ({ path, location, children, isStudent }) => {

    if (isStudent) { // redirect student away from the teacher section
        console.log(12);
        return <Redirect to={'/auth/student/company/dashboard'}/>
    }

    return children

};

export default withRouter(connect(state => {
    const user = state.entities.user['first'];

    return {
        isStudent: user !== undefined && user.role === "student"
    }
}, {

})(TeacherSection));




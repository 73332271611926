import React from 'react'
import {Redirect, withRouter} from 'react-router-dom'
import { registerToGame } from '../../../../actions/sync-modules'
import {connect} from "react-redux";
import Error from "../../../../components/Error";
import Loader from "../../../../components/Loader";
import config from "../../../../config";
import { withTranslation } from "react-i18next";


class RegistrationToGame extends React.Component {

    static propTypes = {
    };

    constructor(props) {
        super(props);

        this.state = {
            registrationPhrase: '',
            isBeingRegistered: false
        }
    }

    render() {
        const { t } = this.props;
        if (this.props.isRegisteredToGame) {
            return <Redirect to={'/auth/student/company/dashboard'} />
        }

        if (this.state.isBeingRegistered) {
            return <Loader/>
        }

        return (
                <div className="pt-5 col-md-7 col-lg-4 col-xl-3 mx-auto">
                    <form action="#" onSubmit={e => {
                        this.registerToGame();
                        e.preventDefault();
                    }}>
                        <div className="box p-4 d-flex flex-column justify-content-center align-items-center">

                            <h3 className="mb-3">{t("registrationToGame.registrationToTheGame")}</h3>
                            {this.renderError()}
                            <p>
                                <input
                                    type="text"
                                    placeholder={t("registrationToGame.registrationPhrase")}
                                    name="uname"
                                    autoComplete="false"
                                    required
                                    maxLength={config.maxRegistrationPhraseLength}
                                    onChange={
                                        e => this.setState({registrationPhrase: e.target.value})
                                    }
                                />
                            </p>
                            <p className="mt-3">
                                <button className="btn btn-primary">{t("registrationToGame.register")}</button>
                            </p>
                        </div>
                    </form>
                </div>
        )
    }

    registerToGame() {
        this.setState({isBeingRegistered: true}, () => {
            this.props.registerToGame(this.state.registrationPhrase, () => {
                this.setState({isBeingRegistered: false});
            });
        });
    }

    renderError() {
        if (this.props.falseRegistrationPhrase) {
            return <Error>{this.props.t("registrationToGame.badPhrase")}</Error>;
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const user = state.entities.user['first'];
    const userGame = state.entities.userGame['first'];

    return {
        isRegisteredToGame: (user.gameId !== undefined && user.gameId !== null) || (userGame !== undefined && userGame !== null),
        falseRegistrationPhrase: userGame === null
    }
};

const RegistrationToGameTranslated = withTranslation('translation')(RegistrationToGame)

export default withRouter(connect(mapStateToProps, {
    registerToGame
})(RegistrationToGameTranslated))

import {toArray} from "./utils";
import {entityFromSchema} from "../middleware/sync/schema";

export const uniqueErrors = (errors) => {
    let results = {}; // an object is used instead of array to show only unique error messages

    errors.forEach((error, index) => {
        const key = error.error;
        const uniqueKey = key + '-' + error.code + '-' + JSON.stringify(error.vars);

        if (results[uniqueKey] === undefined) {
            const copy = Object.assign({}, error);
            copy.indexes = [index];
            results[uniqueKey] = copy;
        } else {
            results[uniqueKey].indexes.push(index);
        }
    });

    return toArray(results);
};

export const localizedError = (error, t) => {
    const key = error.error;
    if (key === undefined) {
        return t("originalErrors.Fatal error");
    } else {
        return t("originalErrors." + key, key , error.vars)
    }
};

export const containsFatal = (errors) => {
    if (errors.length === 0) {
        return false;
    } else {
        const fatalError = errors.find(error => isFatal(error));
        return fatalError !== undefined;
    }
};

export const isFatal = (error) => {
    return (
        error.error === "Failed to fetch" ||
        error.error === "Fatal error" ||
        (error.code > 401 && error.code !== 422)
    );
};

export const existsErrorById = (errors, id) => {
    const error = errors.find(e => e.id === id);
    return error !== undefined;
};

export const existsServerError = (errors, method, schema) => {
    const id = serverErrorId(method, schema);
    return existsErrorById(errors, id);
};

export const serverErrorId = (method, schema) => {
    if (['get', 'put', 'post', 'delete'].includes(method.toLowerCase()) === false) {
        throw new Error("Method not supported!");
    }
    return method.toUpperCase() + '-' + entityFromSchema(schema);
};
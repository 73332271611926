import { schema } from 'normalizr'

// ENTITY STRUCTURE

export const entities = {
    user: '',
    userGame: '',
    game: '',
    company: '',
    sells: '',
    production: '',
    machines: '',
    investmentLoan: '',
    investmentLoanExtraRepayment: '',
    marketSurveys: '',
    marketSurveyRequirements: '',
    concurrencySurvey: '',
    concurrencySurveyRequirement: '',
    summaries: '',
    materialPurchase: '',
    materialPurchasePrices: '',
    materialPurchaseRequirement: '',
    materialSurvey: '',
    materialSurveyRequirement: '',
    staff: '',
    staffRequirement: '',
    staffPersonalPolicy: '',
    userCompanies: '',
    teacherGames: '',
    teacherGameDefaults: '',
    stockPrices: '',
    loanRequirements: '',
    teacherCompanies: '',
    teacherMarkets: '',
    teacherRandomness: '',
    teacherUsers: '',
    teacherSearchUsers: '',
    scores: '',
};

// HELPER FUNCTIONS

Object.keys(entities).forEach(entityKey => {
    if (entities[entityKey] === '') {
        entities[entityKey] = entityKey;
    }
});

export const entitiesStructure = (() => {
    let obj = {};
    Object.keys(entities).forEach(name => obj[name] = {});
    return obj;
})();

export const entityFromSchema = (schema) => {
    if (schema === null) {
        return null;
    } else if (Array.isArray(schema)) {
        return schema[0].key;
    } else {
        return schema.key;
    }
};

// SCHEMA DEFINITIONS

const singleObjectSchema = (entity) => {
    return new schema.Entity(entity, {}, {
        idAttribute: item => "first"
    });
};

const byIdSchema = (entity) => {
    return new schema.Entity(entity, {}, {
        idAttribute: item => item.id
    });
};

const byGoodIdSchema = (entity) => {
    return new schema.Entity(entity, {}, {
        idAttribute: item => item.goodId
    });
};

const byMaterialIdSchema = (entity) => {
    return new schema.Entity(entity, {}, {
        idAttribute: item => item.materialId
    });
};

const byMarketIdSchema = (entity) => {
    return new schema.Entity(entity, {}, {
        idAttribute: item => item.marketId
    });
};

const byQuarterNumberSchema = (entity) => {
    return new schema.Entity(entity, {}, {
        idAttribute: item => item.quarterNumber
    });
};

// general schemas
const userSchema = singleObjectSchema(entities.user);
const summarySchema = byQuarterNumberSchema(entities.summaries);
const scoreSchema = byIdSchema(entities.scores);

// student schemas
const sellSchema = byGoodIdSchema(entities.sells);
const marketSurveySchema = byGoodIdSchema(entities.marketSurveys);
const marketSurveyRequirementSchema = byGoodIdSchema(entities.marketSurveyRequirements);
const concurrencySurveySchema = byQuarterNumberSchema(entities.concurrencySurvey);
const materialPurchaseSchema = byMaterialIdSchema(entities.materialPurchase);
const materialPurchasePricesSchema = byMaterialIdSchema(entities.materialPurchasePrices);
const materialPurchaseRequirementSchema = byMaterialIdSchema(entities.materialPurchaseRequirement);
const materialSurveySchema = byMaterialIdSchema(entities.materialSurvey);
const userCompanySchema = byIdSchema(entities.userCompanies);

// teacher schemas
const teacherGameSchema = byIdSchema(entities.teacherGames);
const teacherGameDefaultsSchema = singleObjectSchema(entities.teacherGameDefaults);
const stockPricesSchema = byIdSchema(entities.stockPrices);
const loanRequirementsSchema = byIdSchema(entities.loanRequirements);
const teacherCompanySchema = byIdSchema(entities.teacherCompanies);
const teacherMarketSchema = byIdSchema(entities.teacherMarkets);
const teacherRandomnessSchema = byMarketIdSchema(entities.teacherRandomness);
const teacherUserSchema = byIdSchema(entities.teacherUsers);
const teacherSearchUserSchema = byIdSchema(entities.teacherSearchUsers);

// wrap all schemas to one object
export const Schemas = {
    // general
    USER: userSchema,
    USER_GAME: singleObjectSchema(entities.userGame),
    SUMMARY: summarySchema,
    SUMMARY_ARRAY: [summarySchema],
    SCORE: scoreSchema,
    SCORE_ARRAY: [scoreSchema],

    // student
    GAME: singleObjectSchema(entities.game),
    COMPANY: singleObjectSchema(entities.company),
    SELL: sellSchema,
    SELL_ARRAY: [sellSchema],
    PRODUCTION: singleObjectSchema(entities.production),
    MACHINES: singleObjectSchema(entities.machines),
    INVESTMENT_LOAN: singleObjectSchema(entities.investmentLoan),
    INVESTMENT_LOAN_EXTRA_REPAYMENT: singleObjectSchema(entities.investmentLoanExtraRepayment),
    MARKET_SURVEY: marketSurveySchema,
    MARKET_SURVEY_ARRAY: [marketSurveySchema],
    MARKET_SURVEY_REQUIREMENT: marketSurveyRequirementSchema,
    MARKET_SURVEY_REQUIREMENT_ARRAY: [marketSurveyRequirementSchema],
    CONCURRENCY_SURVEY: concurrencySurveySchema,
    CONCURRENCY_SURVEY_ARRAY: [concurrencySurveySchema],
    CONCURRENCY_SURVEY_REQUIREMENT: singleObjectSchema(entities.concurrencySurveyRequirement),
    MATERIAL_PURCHASE: materialPurchaseSchema,
    MATERIAL_PURCHASE_ARRAY: [materialPurchaseSchema],
    MATERIAL_PURCHASE_PRICES: materialPurchasePricesSchema,
    MATERIAL_PURCHASE_PRICES_ARRAY: [materialPurchasePricesSchema],
    MATERIAL_PURCHASE_REQUIREMENT: materialPurchaseRequirementSchema,
    MATERIAL_PURCHASE_REQUIREMENT_ARRAY: [materialPurchaseRequirementSchema],
    MATERIAL_SURVEY: materialSurveySchema,
    MATERIAL_SURVEY_ARRAY: [materialSurveySchema],
    MATERIAL_SURVEY_REQUIREMENT: singleObjectSchema(entities.materialSurveyRequirement),
    STAFF: singleObjectSchema(entities.staff),
    STAFF_REQUIREMENT: singleObjectSchema(entities.staffRequirement),
    STAFF_PERSONAL_POLICY: singleObjectSchema(entities.staffPersonalPolicy),
    USER_COMPANY: userCompanySchema,
    USER_COMPANY_ARRAY: [userCompanySchema],

    // teacher:
    TEACHER_GAME: teacherGameSchema,
    TEACHER_GAME_ARRAY: [teacherGameSchema],
    TEACHER_GAME_DEFAULTS: teacherGameDefaultsSchema,
    TEACHER_STOCK_PRICES: stockPricesSchema,
    TEACHER_STOCK_PRICES_ARRAY: [stockPricesSchema],
    TEACHER_LOAN_REQUIREMENTS: loanRequirementsSchema,
    TEACHER_LOAN_REQUIREMENTS_ARRAY: [loanRequirementsSchema],
    TEACHER_COMPANY: teacherCompanySchema,
    TEACHER_COMPANY_ARRAY: [teacherCompanySchema],
    TEACHER_MARKET: teacherMarketSchema,
    TEACHER_MARKET_ARRAY: [teacherMarketSchema],
    TEACHER_RANDOMNESS: teacherRandomnessSchema,
    TEACHER_RANDOMNESS_ARRAY: [teacherRandomnessSchema],
    TEACHER_USER: teacherUserSchema,
    TEACHER_USER_ARRAY: [teacherUserSchema],
    TEACHER_SEARCH_USER: teacherSearchUserSchema,
    TEACHER_SEARCH_USER_ARRAY: [teacherSearchUserSchema],
};

import React from 'react'

export const toArray = function (object) {
    return Object.keys(object).map(key => object[key]);
};

export const sequenceMap = function (start, end, callback) {
    let results = [];
    const s = (start < end) ? 1 : -1;
    for (let i = start; i !== end + s; i += s) {
        results.push(callback(i));
    }
    return results;
};

export const nl2br = function (description) {
    if (description === undefined) {
        return "";
    } else {
        return description.split('\n').map((item, key) => {
            return <span key={key}>{item}<br/></span>
        })
    }
};

export const dateFromUnixTimestamp = function(unixTimestamp) {
    if (unixTimestamp === null || unixTimestamp === undefined) {
        return null;
    } else {
        return new Date(unixTimestamp * 1000);
    }
};

export function getCurrentLanguage() {
    return localStorage.getItem("language");
}

export function getCurrentLocale() {
    return getCurrentLanguage() === "en" ? "en-US" : "cs-CZ";
}

export const formatNumber = (number) => {
    return number.toLocaleString(getCurrentLocale(), {maximumFractionDigits: 2});
};

export const formatDate = (date) => {
    if (date === null || date === undefined) {
        return "-";
    } else {
        return date.toLocaleDateString(getCurrentLocale());
    }
};

export const formatDateTime = (date) => {
    if (date === null || date === undefined) {
        return "-";
    } else {
        return date.toLocaleString(getCurrentLocale());
    }
};

export const formatDateTimeShort = (date) => {
    if (date === null || date === undefined) {
        return "-";
    } else {
        const minutes = date.getMinutes();
        const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

        if (getCurrentLanguage() === "en") {
            return (date.getMonth() + 1) + "/" + date.getDate() + " " + date.getHours() + ":" + formattedMinutes;
        } else {
            return date.getDate() + ". " + (date.getMonth() + 1) + ". " + date.getHours() + ":" + formattedMinutes;
        }
    }
};

export const firstUpper = (s) => {
    if (typeof s !== 'string') {
        return '';
    } else {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }
};

export const sanitize = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
};

export const sortSanitized = (arr, key) => {
    return arr.sort((a, b) => sanitize(a[key]) < sanitize(b[key]) ? -1 : 1);
};

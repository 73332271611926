import React from 'react'
import PropTypes from 'prop-types'
import { Chart } from 'react-google-charts';
import { quarterNameFor } from "../services/quarter";
import {formatNumber} from "../services/utils";
import i18next from "i18next";

const StockChart = ({ game, stockPrices, t }) => {
    const data = [[t("stockChart.time"), t("stockChart.price"), {role: 'annotation'}]].concat(stockPrices.map(item => {
        const quarter = quarterNameFor(game, item.quarterNumber);
        const stockPrice = item.value;
        return [quarter, stockPrice, t("stockChart.czk", {value: formatNumber(item.value)})];
    }));

    return (
        <div className={'my-pretty-chart-container'}>
            <Chart
                chartType="AreaChart"
                data={data}
                options={
                    {
                        vAxis: { format: '#' + t("stockChart.czk")},
                        annotations: {
                            boxStyle: {
                                stroke: '#ffffff',
                                strokeWidth: 5,
                                rx: 0,
                                ry: 0,
                                fill: '#fffffd'
                            },
                            textStyle: {
                                bold: true
                            }
                        },
                        animation: {
                            startup: true,
                            duration: 1500,
                            easing: 'out',
                        },
                    }
                }
                graph_id="AreaChart"
                width="100%"
                height="400px"
            />
        </div>
    )
};

StockChart.propTypes = {
    game: PropTypes.object.isRequired,
    stockPrices: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired
};

export default StockChart

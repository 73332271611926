import {DELETE_ALL_ENTITIES_DATA, DELETE_ENTITY_DATA} from "../actions/entities";
import update from "immutability-helper";
import {START_DOWNLOADING_ENTITY, STOP_DOWNLOADING_ENTITY} from "../actions/sync-requests";
import {
    ADD_SYNC_REQUEST,
    NEW_CHANGES_WITHOUT_SAVING, REMOVE_FIRST_SYNC_REQUEST,
    SAVED_ALL_CHANGES,
    START_SYNC,
    STOP_SYNC,
    WAIT_FOR_SYNC
} from "../actions/sync-queue";
import {START_LOADING_BASIC_DATA} from "../actions/sync-modules";

export const isLoadingBasicData = (state = {isLoading: false, count: 0}, action) => {
    let copy;
    switch (action.type) {
        case START_LOADING_BASIC_DATA:
            copy = Object.assign({}, state);
            copy.isLoading = true;
            return copy;

        case START_DOWNLOADING_ENTITY:
            if (state.isLoading) {
                copy = Object.assign({}, state);
                copy.count++;
                return copy;
            }
            return state;

        case STOP_DOWNLOADING_ENTITY:
            if (state.isLoading) {
                copy = Object.assign({}, state);
                copy.count--;
                if (copy.count === 0) {
                    copy.isLoading = false;
                }
                return copy;
            }
            return state;

        default:
            return state;
    }
};

export const hasUnsavedChanges = (state = false, action) => {
    switch (action.type) {
        case NEW_CHANGES_WITHOUT_SAVING:
            return true;
        case SAVED_ALL_CHANGES:
            return false;
        default:
            return state;
    }
};

export const isWaitingForSync = (state = false, action) => {
    switch (action.type) {
        case WAIT_FOR_SYNC:
            return true;
        case STOP_SYNC:
            return false;
        default:
            return state;
    }
};

export const isSyncing = (state = false, action) => {
    switch (action.type) {
        case START_SYNC:
            return true;
        case STOP_SYNC:
            return false;
        default:
            return state;
    }
};

export const lastSave = (state = null, action) => {
    switch (action.type) {
        case STOP_SYNC:
            return (new Date()).toLocaleTimeString();
        default:
            return state;
    }
};

export const syncQueue = (state = [], action) => {
    switch (action.type) {
        case ADD_SYNC_REQUEST:
            const copy = state.slice();

            const existingRequestIndex = copy.findIndex(item => item.id === action.id);
            if (existingRequestIndex !== -1) {
                copy.splice(existingRequestIndex, 1);
            }

            copy.push({
                id: action.id,
                request: action.request
            });

            return copy;

        case REMOVE_FIRST_SYNC_REQUEST:
            const a = state.slice();
            a.shift();
            return a;

        default:
            return state;
    }
};

export const downloadedEntities = (state = {}, action) => {
    if (action.type === DELETE_ENTITY_DATA) {
        const copy = Object.assign({}, state);
        delete copy[action.entity];
        return copy;

    } else if (action.type === DELETE_ALL_ENTITIES_DATA) {
        return {};

    } else if (action.entity !== null && action.entity !== undefined) {
        switch (action.type) {
            case START_DOWNLOADING_ENTITY:
                return update(state, {[action.entity]: {$set: false}});
            case STOP_DOWNLOADING_ENTITY:
                return update(state, {[action.entity]: {$set: true}});
            default:
                return state;
        }

    } else {
        return state;
    }
};

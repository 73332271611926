import * as ActionTypes from "../actions/sync-modules";

export const isAuthenticated = (state = false, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN_SUCCESS:
        case ActionTypes.LOGIN_RESTORED:
            return true;
        case ActionTypes.LOGIN_FAILURE:
        case ActionTypes.LOGOUT:
            return false;
        default:
            return state;
    }
};

export const isBeingAuthenticated = (state = false, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN_REQUEST:
            return true;
        case ActionTypes.LOGIN_SUCCESS:
        case ActionTypes.LOGIN_FAILURE:
        case ActionTypes.LOGOUT:
            return false;
        default:
            return state;
    }
};

export const profilePicture = (state = null, action) => {
    switch (action.type) {
        case ActionTypes.SET_PROFILE_PICTURE:
            return action.image;
        case ActionTypes.DELETE_PROFILE_PICTURE:
        case ActionTypes.LOGOUT:
            return null;
        default:
            return state;
    }
}

import {CALL_API} from "../middleware/sync";
import {entityFromSchema} from "../middleware/sync/schema";
import {deleteEntityData, deleteEntityDataById, updateEntityDataById} from "./entities";
import {addSyncRequest, startSync, startSyncWithDelay} from "./sync-queue";


// --- GET REQUEST ---

export const DOWNLOAD_REQUEST = 'DOWNLOAD_REQUEST';
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS';
export const DOWNLOAD_FAILURE = 'DOWNLOAD_FAILURE';
const DOWNLOAD_TYPES = [ DOWNLOAD_REQUEST, DOWNLOAD_SUCCESS, DOWNLOAD_FAILURE ];

export const START_DOWNLOADING_ENTITY = 'START_DOWNLOADING_ENTITY';
export const STOP_DOWNLOADING_ENTITY = 'STOP_DOWNLOADING_ENTITY';

export const getRequest = (endpoint, schema) => {
    return {
        [CALL_API]: {
            types: DOWNLOAD_TYPES,
            endpoint: endpoint,
            schema: schema
        }
    }
};

export const downloadData = (targets, complete) => (dispatch, getState) => {
    // normalize inputs parameter
    if (Array.isArray(targets) === false) {
        targets = [targets];
    }

    // delete old data
    targets.forEach(target => {
        const entity = entityFromSchema(target.schema);
        dispatch({type: START_DOWNLOADING_ENTITY, entity: entity});
        deleteEntityData(entity)(dispatch, getState);
    });

    // prepare download requests as promises
    let promises = [];
    targets.forEach(target => {
        promises.push(new Promise((resolve, reject) => {
            dispatch(getRequest(target.endpoint, target.schema)).then(() => {
                // download complete
                dispatch({type: STOP_DOWNLOADING_ENTITY, entity: entityFromSchema(target.schema)});
                resolve();
            });
        }));
    });

    // finish sync and then download all entities
    startSync(() => {
        Promise.all(promises).then(complete);
    })(dispatch, getState);
};


// --- POST REQUEST ---

export const CREATE_REQUEST = 'CREATE_REQUEST';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_FAILURE = 'CREATE_FAILURE';
const CREATE_TYPES = [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE];

export const postRequest = (endpoint, schema, data) => {
    return {
        [CALL_API]: {
            types: CREATE_TYPES,
            method: 'POST',
            endpoint: endpoint,
            schema: schema,
            data: data
        }
    }
};

export const createData = (endpoint, schema, data, complete) => (dispatch, getState) => {
    // We cannot create the object locally immediately because its ID is generated on the server.
    // Therefore, we create the object on the server first and then we save it after receiving the server response.

    // create on server via sync queue and store the result received from server
    const request = postRequest(endpoint, schema, data);
    addSyncRequest(endpoint + '/new', request)(dispatch, getState);
    startSync(complete)(dispatch, getState);
};


// --- PUT REQUEST ---

export const UPDATE_REQUEST = 'UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'UPDATE_FAILURE';
const UPDATE_TYPES = [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE];

export const putRequest = (endpoint, schema, data) => {
    return {
        [CALL_API]: {
            types: UPDATE_TYPES,
            method: 'PUT',
            endpoint: endpoint,
            schema: schema,
            data: data
        }
    }
};

export const updateData = (endpoint, schema, id, data, complete) => (dispatch, getState) => {
    // local immediate update
    if (id !== null) {
        updateEntityDataById(schema, id, data)(dispatch, getState);
    }

    // server update via sync queue
    const request = putRequest(endpoint, schema, data);
    addSyncRequest(endpoint, request)(dispatch, getState);
    startSyncWithDelay(complete)(dispatch, getState);
};


// --- DELETE REQUEST ---

export const DELETE_REQUEST = 'DELETE_REQUEST';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_FAILURE = 'DELETE_FAILURE';
const DELETE_TYPES = [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE];

export const deleteRequest = (endpoint, schema) => {
    return {
        [CALL_API]: {
            types: DELETE_TYPES,
            method: 'DELETE',
            endpoint: endpoint,
            schema: schema
        }
    }
};

export const deleteData = (endpoint, schema, id, complete) => (dispatch, getState) => {
    // local immediate delete
    if (id !== null) {
        deleteEntityDataById(schema, id)(dispatch, getState);
    }

    // delete on server via sync queue
    const request = deleteRequest(endpoint, schema);
    addSyncRequest(endpoint, request)(dispatch, getState);
    startSync(complete)(dispatch, getState);
};


// --- FILE UPLOAD REQUEST ---

export const UPLOAD_REQUEST = 'UPLOAD_REQUEST';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_FAILURE = 'UPLOAD_FAILURE';
const UPLOAD_TYPES = [UPLOAD_REQUEST, UPLOAD_SUCCESS, UPLOAD_FAILURE];

export const uploadRequest = (endpoint, schema, files) => {
    const data = new FormData();
    Object.keys(files).forEach(key => {
        data.append(key, files[key]);
    });
    return {
        [CALL_API]: {
            types: UPLOAD_TYPES,
            method: 'POST',
            endpoint: endpoint,
            schema: schema,
            data: data
        }
    }
};

export const uploadFile = (endpoint, schema, files, complete) => (dispatch, getState) => {
    // upload files to server via sync queue
    const request = uploadRequest(endpoint, schema, files);
    addSyncRequest(endpoint, request)(dispatch, getState);
    startSync(complete)(dispatch, getState);
};

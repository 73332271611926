import React from 'react'
import PropTypes from 'prop-types'
import { quarterNameFor } from "../../../../services/quarter";
import {formatNumber} from "../../../../services/utils";
import { withTranslation } from "react-i18next";

const MaterialPurchaseTable = ({ game, data, t }) => {
    return (
        <table className="table table-responsive table-centered-except-first-column">
            <thead>
            <tr>
                <th> </th>
                {data.map(item => <th key={item.quarterNumber}>{quarterNameFor(game, item.quarterNumber)}</th>)}
            </tr>
            </thead>
            <tbody>
            <tr>
                <th>{t("materialPurchaseTable.purchased")}</th>
                {data.map(item => <td key={item.quarterNumber} className={'text-right'}>{formatNumber(item.amount)}</td>)}
            </tr>
            <tr>
                <th>{t("materialPurchaseTable.price")}</th>
                {data.map(item => <td key={item.quarterNumber} className={'text-right'}>{formatNumber(item.price)}</td>)}
            </tr>
            <tr>
                <th>{t("materialPurchaseTable.discount")}</th>
                {data.map(item => <td key={item.quarterNumber} className={'text-right'}>{formatNumber(item.discountRate * 100)} %</td>)}
            </tr>
            <tr>
                <th>{t("materialPurchaseTable.priceTotal")}</th>
                {data.map(item => <td key={item.quarterNumber} className={'text-right'}>{formatNumber(Math.round(item.totalPrice))}</td>)}
            </tr>
            <tr className={'border-top'}>
                <th>{t("materialPurchaseTable.finalStorageState")}</th>
                {data.map(item => <td key={item.quarterNumber} className={'text-right'}>{formatNumber(item.storageAmount)}</td>)}
            </tr>
            <tr>
                <th>{t("materialPurchaseTable.storageCosts")}</th>
                {data.map(item => <td key={item.quarterNumber} className={'text-right'}>{formatNumber(Math.round(item.storagePrice))}</td>)}
            </tr>
            </tbody>
        </table>
    )
};

MaterialPurchaseTable.propTypes = {
    game: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation('translation')(MaterialPurchaseTable)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateConcurrencySurveyRequirement } from '../../../../actions/sync-modules'
import LastChange from '../../../../components/LastChange'
import {formatNumber} from "../../../../services/utils";
import {loadConcurrencySurveyRequirement} from "../../../../actions/sync-modules";
import {currentQuarter, quarterNameFor} from "../../../../services/quarter";
import Loader from "../../../../components/Loader";
import { withTranslation } from "react-i18next";

class ConcurrencySurveyRequirement extends Component {

    static propTypes = {

    };

    constructor(props) {
        super(props);

        this.state = {
            isInitialized: false,
            price: false,
            soldGoods: false
        };

        this.props.loadConcurrencySurveyRequirement();
    }

    static getDerivedStateFromProps(props, state) {
        if (state.isInitialized === false && props.concurrencySurveyRequirement !== undefined) {
            return {
                isInitialized: true,
                prices: props.concurrencySurveyRequirement.prices,
                soldGoods: props.concurrencySurveyRequirement.soldGoods
            }
        }
        return null
    }

    render() {
        const { t } = this.props;
        return (
            <div className="box box-important">
                <h3>{t("concurrencySurveyRequirement.order", {quarterName: quarterNameFor(this.props.game, this.props.currentQuarter)})}</h3>
                <div className="content" key="content">
                    {this.renderContent()}
                </div>
                <LastChange key={'last-change'} object={this.props.concurrencySurveyRequirement} t={t} />
            </div>
        );
    }

    renderContent() {
        if (this.props.didDownloadData !== true) {
            return <Loader/>
        } else {
            return <div className="form-item">
                {this.renderCheckboxes()}
                {this.renderSurveyPrice()}
            </div>
        }
    }

    renderCheckboxes() {
        const { t , concurrencySurveyRequirement } = this.props;

        return [
            <div className="form-check form-check-inline" key="price">
                <input
                    className="form-check-input"
                    type="checkbox"
                    id={'concurency-survey-requirement-price'}
                    defaultChecked={concurrencySurveyRequirement !== undefined && concurrencySurveyRequirement.prices}
                    onChange={() => this.setState({prices: !this.state.prices}, this.handleChange)}
                />
                <label className="form-check-label" htmlFor={'concurency-survey-requirement-price'}>
                    {t("concurrencySurveyRequirement.competitorsPrices")}
                </label>
            </div>,
            <div className="form-check form-check-inline" key="soldGoods">
                <input
                    className="form-check-input"
                    type="checkbox"
                    id={'concurency-survey-requirement-soldGoods'}
                    defaultChecked={concurrencySurveyRequirement !== undefined && concurrencySurveyRequirement.soldGoods}
                    onChange={() => this.setState({soldGoods: !this.state.soldGoods}, this.handleChange)}
                />
                <label className="form-check-label" htmlFor={'concurency-survey-requirement-soldGoods'}>
                    {t("concurrencySurveyRequirement.competitorsQuantity")}
                </label>
            </div>
        ];
    }

    handleChange() {
        this.props.updateConcurrencySurveyRequirement({
            prices: this.state.prices,
            soldGoods: this.state.soldGoods
        });
    }

    renderSurveyPrice() {
        const { t } = this.props;
        let price = 0;

        if (this.state.prices) {
            price += this.props.priceSurveyPrice;
        }

        if (this.state.soldGoods) {
            price += this.props.soldGoodsSurveyPrice;
        }

        return (
            <div key="desc" className="description">
                {t("concurrencySurveyRequirement.totalPrice", {amount: formatNumber(price)})}
            </div>
        )
    }

}

const mapStateToProps = (state, ownProps) => {
    const game = state.entities.game['first'];

    return {
        game: game,
        goods: game.goods,
        concurrencySurveyRequirement: state.entities.concurrencySurveyRequirement['first'],
        priceSurveyPrice: game.surveyPrices.concurrencyPrices,
        soldGoodsSurveyPrice: game.surveyPrices.concurrencySoldGoods,
        currentQuarter: currentQuarter(game).quarterNumber,
        didDownloadData: state.downloadedEntities.concurrencySurveyRequirement
    }
};

const mapDispatchToProps = {
    loadConcurrencySurveyRequirement,
    updateConcurrencySurveyRequirement
};

const ConcurrencySurveyRequirementTranslated = withTranslation('translation')(ConcurrencySurveyRequirement)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConcurrencySurveyRequirementTranslated))

import React from 'react'
import {Redirect, withRouter} from 'react-router-dom'
import { connect } from "react-redux";
import {loadCompany} from "../actions/sync-modules";
import {isGameRunning, isRegistrationPeriod} from "../services/quarter";

const CompanySection = ({ didLoadCompany, isRegistrationPeriod, loadCompany, isLoadingBasicData, isGameRunning, children }) => {

    if (isRegistrationPeriod) { // user is not in any company yet
        console.log(8);
        return <Redirect to={'/auth/student/company-registration'}/>
    }

    if (didLoadCompany === false) {
        console.log(9);
        if (isLoadingBasicData === false) {
            loadCompany();
        }
        return null;
    }

    return (
        <div className={isGameRunning ? '' : ' game-not-running '}>
            {children}
        </div>
    );
};

export default withRouter(connect(state => {
    const game = state.entities.game['first'];
    const downloaded = state.downloadedEntities;

    return {
        didLoadCompany: downloaded.company === true,
        isLoadingBasicData: state.isLoadingBasicData.isLoading,
        isRegistrationPeriod: isRegistrationPeriod(game),
        isGameRunning: isGameRunning(game)
    }
}, {
    loadCompany
})(CompanySection));




import React from 'react'
import {Redirect, withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from "react-redux";
import MenuItem from '../components/MenuItem'
import {
    currentQuarter, hasGameEnded, isGameRunning
} from "../services/quarter";
import MenuFooter from "./MenuFooter";
import Tooltip from 'react-tooltip-lite';
import { withTranslation } from 'react-i18next';

class Menu extends React.Component {

    static propTypes = {
        currentQuarter: PropTypes.object,
        mobileMenuVisible: PropTypes.bool.isRequired,
        hideMenu: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            game: undefined,
            currentQuarter: undefined
        };
    }

    static getDerivedStateFromProps(props, state) {
        let game = props.game;

        if (props.isTeacher) {
            const matches = props.location.pathname.match(/\/auth\/teacher\/administration\/([0-9]+)\/.*/);
            const gameId = matches !== null && matches[1];
            game = props.teacherGames[gameId];
        }

        return {
            game: game,
            currentQuarter: currentQuarter(game)
        };
    }

    render() {
        if (this.props.isTeacher && this.state.game === undefined) {
            return <Redirect to={'/auth/teacher/games'} />
        }

        const { pathname } = this.props.location;

        let menuClassName = (this.props.mobileMenuVisible) ?
            "pl-0 pr-0 d-block col-12 mobile-menu" :
            "pl-0 pr-1 d-none d-md-block col-md-4 col-lg-3 col-xl-2 desktop-menu";

        return (
            <div className={menuClassName}>
                <div className={"menu " + ((!this.props.mobileMenuVisible) ? "d-flex flex-column":"")}>
                    <div onClick={this.props.hideMenu}>
                        {pathname.startsWith('/auth/teacher/') ? this.renderTeacherMenu() : this.renderStudentMenu()}
                    </div>
                    <MenuFooter game={this.state.game} currentQuarter={this.state.currentQuarter} />
                </div>
            </div>
        );
    }

    renderStudentMenu() {
        const { pathname } = this.props.location;
        const { t } = this.props;
        const basePath = '/auth/student/company';
        return <div>
            <MenuItem currentPath={pathname} targetPath={basePath + "/dashboard"} icon='dashboard' title={t("menu.dashboard")}/>
            <MenuItem currentPath={pathname} targetPath={basePath + "/material-purchase"} icon='material-purchase' title={t("menu.materialPurchase")}/>
            <MenuItem currentPath={pathname} targetPath={basePath + "/production"} icon='production' title={t("menu.production")}/>
            <MenuItem currentPath={pathname} targetPath={basePath + "/sells"} icon='sells' title={t("menu.sells")}/>
            <MenuItem currentPath={pathname} targetPath={basePath + "/staff"} icon='staff' title={t("menu.staff")}/>
            <MenuItem currentPath={pathname} targetPath={basePath + "/surveys"} icon='material-research' title={<span>{t("menu.surveys")} {this.renderMarketSurveyWarning()}</span>}/>
            <MenuItem currentPath={pathname} targetPath={basePath + "/summaries"} icon='documents' title={t("menu.summaries")}/>
            {this.props.gameHasEnded ?
                <MenuItem currentPath={pathname} targetPath={basePath + "/scores"} icon='results' bold={true} title={t("menu.results")}/>
                : null
            }
        </div>
    }

    renderMarketSurveyWarning() {
        if (isGameRunning(this.props.game) === false && hasGameEnded(this.props.game) === false) {
            return (
                <span>
                    <Tooltip
                        styles={{display: "inline-block", marginLeft: "6px"}}
                        content={this.props.t("menu.changesAllowed")}>
                        <span className={'badge badge-pill badge-danger'}>!</span>
                    </Tooltip>
                </span>
            );
        } else {
            return null;
        }
    }

    renderTeacherMenu() {
        const { pathname } = this.props.location;
        const gameId = this.state.game !== undefined && this.state.game.id;
        const basePath = '/auth/teacher/administration/' + gameId;
        const gameName = this.state.game === undefined ? 'Hra bez názvu' : this.state.game.name;

        return <div>
            <div className={'mb-4 font-weight-bold'}>
                <MenuItem currentPath={null} targetPath={"/auth/teacher/games"} icon='back' title={gameName} />
            </div>
            <MenuItem currentPath={pathname} targetPath={basePath + "/markets-and-companies"} icon='dashboard' title='Trhy a podniky'/>
            <MenuItem currentPath={pathname} targetPath={basePath + "/students"} icon='staff' title='Studenti'/>
            <MenuItem currentPath={pathname} targetPath={basePath + "/loan-requirements"} icon='loan-requirements' title={<span>Žádosti o úvěry {this.renderOpenedLoanRequirements()}</span>}/>
            <MenuItem currentPath={pathname} targetPath={basePath + "/stock-prices"} icon='stock-prices' title='Stav akcií'/>
            <MenuItem currentPath={pathname} targetPath={basePath + "/experimental"} icon='experimental' title='Experimentální'/>
            <MenuItem currentPath={pathname} targetPath={basePath + "/summaries"} icon='documents' title='Přehledy'/>
            <MenuItem currentPath={pathname} targetPath={basePath + "/scores"} icon='results' title='Výsledky'/>
        </div>
    }

    renderOpenedLoanRequirements() {
        const openedLoanRequirements = this.state.game !== undefined && this.state.game.openedLoanRequirements;
        let className = "ml-3 badge badge-pill badge-";

        if (openedLoanRequirements > 0) {
            className += 'danger';
        } else {
            className += 'light';
        }

        return <span className={className}>{openedLoanRequirements}</span>
    }
}

const mapStateToProps = (state, ownProps) => {
    let user = state.entities.user['first'];
    let game = state.entities.game['first'];

    return {
        game: game,
        teacherGames: state.entities.teacherGames,
        isTeacher: user !== undefined &&  user.role === "teacher",
        gameHasEnded: game !== undefined && hasGameEnded(game),
    }
};

const MenuTranslated = withTranslation('translation')(Menu)

export default withRouter(connect(mapStateToProps)(MenuTranslated))
